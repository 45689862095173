import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import { DarkModeContext } from "../context/DarkModeContext";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { FaCog, FaSearch, FaTimes } from "react-icons/fa";

function AppMessages() {
  const { isDarkMode } = useContext(DarkModeContext);
  const [user, setUser] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  // Move contacts definition here and use it as initial state
  const [contacts, setContacts] = useState([
    { id: 1, name: "John Doe" },
    { id: 2, name: "Jane Smith" },
    { id: 3, name: "Alice Johnson" },
  ]);

  const filteredContacts = contacts.filter((contact) =>
    contact.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  if (!user) {
    return null; // or a loading spinner
  }

  return (
    <div
      className={`flex min-h-screen w-full ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      <div className="flex flex-1">
        {/* Redesigned Contacts sidebar */}
        <div className="w-80 border-r border-gray-200 dark:border-gray-700 bg-white dark:bg-gray-800 overflow-hidden flex flex-col">
          <div className="p-4 flex justify-between items-center border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-xl font-semibold">Contacts</h2>
            <button
              className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors"
              onClick={() => setIsSettingsOpen(true)}
            >
              <FaCog size={20} />
            </button>
          </div>
          <div className="p-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Search contacts..."
                className="w-full pl-10 pr-4 py-2 rounded-full border border-gray-300 dark:border-gray-600 bg-gray-100 dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>
          <ul className="flex-1 overflow-y-auto">
            {filteredContacts.map((contact) => (
              <li
                key={contact.id}
                className={`p-4 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer transition-colors ${
                  selectedContact?.id === contact.id
                    ? "bg-blue-50 dark:bg-blue-900"
                    : ""
                }`}
                onClick={() => setSelectedContact(contact)}
              >
                <div className="flex items-center">
                  <div className="w-10 h-10 rounded-full bg-gray-300 dark:bg-gray-600 flex items-center justify-center text-xl font-semibold mr-3">
                    {contact.name.charAt(0)}
                  </div>
                  <span className="font-medium">{contact.name}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>

        {/* Messages area */}
        <main className="flex-1 p-8">
          {selectedContact ? (
            <>
              <h1 className="text-2xl font-bold mb-4">
                Chat with {selectedContact.name}
              </h1>
              {/* Add your messages content here */}
              <p>Messages will be displayed here.</p>
            </>
          ) : (
            <p className="text-center text-gray-500 dark:text-gray-400">
              Select a contact to start chatting
            </p>
          )}
        </main>

        {/* Settings Overlay */}
        {isSettingsOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 w-3/4 h-3/4 rounded-lg shadow-lg overflow-hidden flex flex-col">
              <div className="p-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
                <h2 className="text-2xl font-semibold">Settings</h2>
                <button
                  className="text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200 transition-colors"
                  onClick={() => setIsSettingsOpen(false)}
                >
                  <FaTimes size={24} />
                </button>
              </div>
              <div className="flex flex-1 overflow-hidden">
                <nav className="w-1/4 bg-gray-50 dark:bg-gray-800">
                  <ul className="space-y-1 p-4">
                    <li>
                      <button className="w-full text-left px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">
                        Chat Integrations
                      </button>
                    </li>
                    {/* Add more tabs here in the future */}
                  </ul>
                </nav>
                <div className="flex-1 p-6 overflow-y-auto">
                  <h3 className="text-xl font-semibold mb-4">
                    Chat Integrations
                  </h3>
                  <p className="mb-4">Configure your chat integrations here.</p>
                  {/* Add chat integration settings here */}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AppMessages;
