import React, { useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { db } from "../firebase/config";
import { collection, addDoc } from "firebase/firestore";
import { createTrelloList } from "../utils/trelloSync";
import { PlusIcon, XIcon } from "@heroicons/react/solid";
import { httpsCallable } from "firebase/functions";
import { getFunctions } from "firebase/functions";

function AddList({ boardId, isTrelloBoard, isDarkMode, onListAdded, isFirstList }) {
  const [isAdding, setIsAdding] = useState(false);
  const [listName, setListName] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useContext(UserContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!listName.trim()) return;

    setError(null);
    setIsLoading(true);
    try {
      let newList;
      if (isTrelloBoard) {
        const createTrelloList = httpsCallable(getFunctions(), 'createTrelloList');
        const result = await createTrelloList({ boardId, name: listName });
        newList = result.data;
      } else {
        const listsRef = collection(
          db,
          `users/${userData.uid}/boxiaBoards/${boardId}/lists`
        );
        const docRef = await addDoc(listsRef, {
          name: listName,
          cards: [],
          pos: isFirstList ? 16384 : Date.now(), // Use 16384 for the first list, similar to Trello
        });
        newList = { id: docRef.id, name: listName, cards: [] };
      }
      onListAdded(newList, isFirstList);
      setListName("");
      setIsAdding(false);
    } catch (error) {
      console.error("Error adding list:", error);
      setError(`Failed to add list: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAdding) {
    return (
      <button
        onClick={() => setIsAdding(true)}
        className={`m-2 p-2 h-10 rounded-md text-left flex items-center justify-center transition-all duration-300 ${
          isDarkMode
            ? "bg-gray-700 text-gray-200 hover:bg-gray-600"
            : "bg-gray-100 text-gray-700 hover:bg-gray-200"
        } shadow-sm hover:shadow-md`}
      >
        <PlusIcon className="h-5 w-5 mr-2" />
        <span>Add List</span>
      </button>
    );
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={`m-2 p-4 w-72 rounded-lg shadow-md transition-all duration-300 ease-in-out ${
        isDarkMode ? "bg-gray-700" : "bg-gray-100"
      }`}
    >
      <div className="relative">
        <input
          type="text"
          value={listName}
          onChange={(e) => setListName(e.target.value)}
          placeholder="Enter list name"
          className={`w-full p-2 pr-8 rounded-md border ${
            isDarkMode
              ? "bg-gray-600 text-gray-200 border-gray-500 focus:border-blue-400"
              : "bg-white text-gray-700 border-gray-300 focus:border-blue-500"
          } focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50`}
          autoFocus
        />
        <button
          type="button"
          onClick={() => setIsAdding(false)}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
        >
          <XIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="flex justify-between mt-4">
        <button
          type="submit"
          className={`px-4 py-2 rounded-md transition-all duration-300 ${
            isDarkMode
              ? "bg-blue-600 text-white hover:bg-blue-700"
              : "bg-blue-500 text-white hover:bg-blue-600"
          } shadow-md hover:shadow-lg transform hover:-translate-y-0.5`}
          disabled={isLoading}
        >
          {isLoading ? "Adding..." : "Add List"}
        </button>
        <button
          type="button"
          onClick={() => setIsAdding(false)}
          className={`px-4 py-2 rounded-md transition-all duration-300 ${
            isDarkMode
              ? "bg-gray-600 text-gray-200 hover:bg-gray-500"
              : "bg-gray-200 text-gray-700 hover:bg-gray-300"
          } shadow-md hover:shadow-lg transform hover:-translate-y-0.5`}
        >
          Cancel
        </button>
      </div>
      {error && <div className="mt-2 text-red-500 text-sm">{error}</div>}
    </form>
  );
}

export default AddList;