import React, { createContext, useState, useEffect } from "react";
import { auth, db } from "../firebase/config";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { onAuthStateChanged, signOut } from "firebase/auth";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const unsubscribeSnapshot = onSnapshot(userDocRef, (doc) => {
          if (doc.exists()) {
            setUserData({ ...doc.data(), uid: user.uid });
          } else {
            setUserData(null);
          }
          setIsLoading(false);
        }, (error) => {
          setUserData(null);
          setIsLoading(false);
        });

        return () => {
          unsubscribeSnapshot();
        };
      } else {
        setUserData(null);
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const logout = async () => {
    try {
      await signOut(auth);
      setUserData(null);
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <UserContext.Provider value={{ userData, isLoading, logout }}>
      {children}
    </UserContext.Provider>
  );
};