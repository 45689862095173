import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { db, auth } from "../firebase/config";
import { DarkModeContext } from "../context/DarkModeContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc } from "firebase/firestore";

// Constants
const stripePromise = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
  : null;

const plans = [
  {
    name: "Boxia Personal",
    monthlyPrice: "$9.99",
    annualPrice: "$7.99",
    period: "/month",
    monthlyBilling: "billed monthly",
    annualBilling: "billed annually",
    features: [
      "All core Boxia features",
      "10GB storage",
      "Basic AI assistance",
      "Up to 5 integrations",
      "Mobile app access",
    ],
    id: "personal",
  },
  {
    name: "Boxia Pro",
    monthlyPrice: "$17.99",
    annualPrice: "$14.99",
    period: "/month",
    monthlyBilling: "billed monthly",
    annualBilling: "billed annually",
    features: [
      "All Personal features",
      "Unlimited storage",
      "Advanced AI features",
      "Unlimited integrations",
      "Priority support",
      "Advanced customization",
      "Workflow automation",
    ],
    id: "pro",
    highlight: true,
  },
  // {
  //   name: "Boxia Team",
  //   monthlyPrice: "$15.99",
  //   annualPrice: "$12.99",
  //   period: "/user/month",
  //   monthlyBilling: "billed monthly",
  //   annualBilling: "billed annually",
  //   note: "Minimum 3 users",
  //   features: [
  //     "All Pro features",
  //     "Team collaboration tools",
  //     "Shared workspaces",
  //     "Admin controls",
  //     "Team analytics",
  //     "Enhanced security features",
  //   ],
  //   id: "team",
  // },
];

// Components
const PlanCard = ({
  plan,
  darkMode,
  annualBilling,
  selectedPlan,
  onSelect,
}) => (
  <div
    className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl ${
      darkMode ? "bg-gray-800" : "bg-white"
    } ${
      plan.highlight || selectedPlan === plan.id ? "ring-2 ring-indigo-500" : ""
    } flex flex-col h-full`}
  >
    <div className="flex-grow">
      <h3
        className={`text-2xl font-bold mb-4 ${
          darkMode ? "text-indigo-300" : "text-indigo-700"
        }`}
      >
        {plan.name}
      </h3>
      <div className="mb-2">
        <span
          className={`text-4xl font-bold ${
            darkMode ? "text-gray-100" : "text-gray-900"
          }`}
        >
          {annualBilling ? plan.annualPrice : plan.monthlyPrice}
        </span>
        <span
          className={`text-lg ${darkMode ? "text-gray-400" : "text-gray-600"}`}
        >
          {plan.period}
        </span>
      </div>
      {(annualBilling ? plan.annualBilling : plan.monthlyBilling) && (
        <p
          className={`mb-2 text-sm ${
            darkMode ? "text-gray-400" : "text-gray-600"
          }`}
        >
          {annualBilling ? plan.annualBilling : plan.monthlyBilling}
        </p>
      )}
      {plan.note && (
        <p
          className={`mb-4 text-sm font-medium ${
            darkMode ? "text-gray-300" : "text-gray-700"
          }`}
        >
          {plan.note}
        </p>
      )}
      <ul className="mb-8 space-y-2">
        {plan.features.map((feature, featureIndex) => (
          <li
            key={featureIndex}
            className={`flex items-center ${
              darkMode ? "text-gray-300" : "text-gray-700"
            }`}
          >
            <svg
              className="w-5 h-5 mr-2 text-green-500 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className="mt-auto">
      <button
        onClick={() => onSelect(plan.id)}
        className={`w-full py-2 px-4 rounded-full font-medium ${
          selectedPlan === plan.id
            ? "bg-indigo-600 text-white hover:bg-indigo-700"
            : darkMode
            ? "bg-gray-700 text-white hover:bg-gray-600"
            : "bg-gray-200 text-gray-800 hover:bg-gray-300"
        } transition-colors duration-200`}
      >
        {selectedPlan === plan.id ? "Selected" : "Select Plan"}
      </button>
    </div>
  </div>
);

// Main component
function PlanSelection() {
  const [stripeLoaded, setStripeLoaded] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [annualBilling, setAnnualBilling] = useState(true);
  const [billingInterval, setBillingInterval] = useState("yearly");

  const navigate = useNavigate();
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    const initializeStripe = async () => {
      if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
        setError(
          "Payment processing is currently unavailable. Please contact support."
        );
      } else {
        try {
          const stripeInstance = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
          );
          if (stripeInstance) {
            setStripeLoaded(true);
          } else {
            setError("Failed to initialize Stripe. Please try again later.");
          }
        } catch {
          setError(
            "Failed to initialize payment system. Please try again later."
          );
        }
      }
    };

    initializeStripe();
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            if (
              userData.subscriptionStatus &&
              userData.subscriptionStatus !== "canceled"
            ) {
              navigate("/app");
            }
          }
        } catch (error) {
          console.error("Error checking subscription status:", error);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handlePlanSelect = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    if (!selectedPlan) {
      setError("Please select a plan");
      setLoading(false);
      return;
    }

    if (!stripeLoaded) {
      setError(
        "Payment processing is not available. Please try again later or contact support."
      );
      setLoading(false);
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("No authenticated user found");
      }

      const functions = getFunctions();
      const createCheckoutSessionCallable = httpsCallable(
        functions,
        "createCheckoutSessionCallable"
      );
      const { data } = await createCheckoutSessionCallable({
        planId: selectedPlan,
        billingInterval: billingInterval,
        userId: user.uid,
        userEmail: user.email,
      });

      if (!data || !data.sessionId) {
        throw new Error("Invalid response from server");
      }

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error("Stripe failed to initialize");
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        throw error;
      }
    } catch (error) {
      setError(`An error occurred: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      className={`min-h-screen flex flex-col transition-colors duration-300 ${
        isDarkMode
          ? "bg-gradient-to-b from-gray-900 via-gray-850 to-gray-800 text-white"
          : "bg-gradient-to-b from-white via-indigo-50 to-indigo-100 text-gray-800"
      }`}
    >
      <main className="flex-grow flex flex-col">
        <section className="flex-shrink-0 py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto text-center">
            <h1
              className={`text-3xl sm:text-4xl md:text-5xl font-extrabold tracking-tight mb-4 leading-tight ${
                isDarkMode
                  ? "bg-gradient-to-r from-indigo-200 to-indigo-400"
                  : "bg-gradient-to-r from-indigo-900 to-indigo-600"
              } bg-clip-text text-transparent`}
            >
              Choose Your{" "}
              <span className="bg-gradient-to-r from-indigo-600 to-indigo-400 bg-clip-text text-transparent">
                Boxia Plan
              </span>
            </h1>
            <p
              className={`text-lg sm:text-xl mb-6 max-w-2xl mx-auto ${
                isDarkMode ? "text-indigo-300" : "text-indigo-700"
              }`}
            >
              Select the plan that best fits your needs and start boosting your
              productivity today.
            </p>
          </div>
        </section>

        <section className="flex-grow py-8 px-4 sm:px-6 lg:px-8">
          <div className="max-w-7xl mx-auto">
            <div className="flex justify-center mb-8">
              <div
                className={`p-1 rounded-full ${
                  isDarkMode ? "bg-gray-700" : "bg-gray-200"
                }`}
              >
                <button
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    annualBilling
                      ? isDarkMode
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-500 text-white"
                      : isDarkMode
                      ? "text-gray-300"
                      : "text-gray-700"
                  }`}
                  onClick={() => {
                    setAnnualBilling(true);
                    setBillingInterval("yearly");
                  }}
                >
                  Annual Billing
                </button>
                <button
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    !annualBilling
                      ? isDarkMode
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-500 text-white"
                      : isDarkMode
                      ? "text-gray-300"
                      : "text-gray-700"
                  }`}
                  onClick={() => {
                    setAnnualBilling(false);
                    setBillingInterval("monthly");
                  }}
                >
                  Monthly Billing
                </button>
              </div>
            </div>

            {error && <p className="text-red-500 text-center mb-4">{error}</p>}
            <form onSubmit={handlePlanSelect} className="flex flex-col h-full">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
                {plans.map((plan) => (
                  <PlanCard
                    key={plan.id}
                    plan={plan}
                    darkMode={isDarkMode}
                    annualBilling={annualBilling}
                    selectedPlan={selectedPlan}
                    onSelect={setSelectedPlan}
                  />
                ))}
              </div>
              <div className="mt-auto text-center"></div>
            </form>
          </div>
        </section>
      </main>
    </div>
  );
}

export default PlanSelection;
