import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../firebase/config";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";

const functions = getFunctions();

export async function syncGitHubProjects(userId) {
  try {
    const syncGitHubDataFunction = httpsCallable(functions, "syncGitHubData");
    const result = await syncGitHubDataFunction({ userId });
    const { userInfo, organizations, repositories } = result.data;

    // Update user's GitHub data in Firestore
    await setDoc(
      doc(db, `users/${userId}/integrations/github`),
      {
        userInfo,
        organizations,
        lastSynced: new Date().toISOString(),
      },
      { merge: true }
    );

    // Fetch existing GitHub boards
    const existingBoardsSnapshot = await getDocs(
      collection(db, `users/${userId}/githubBoards`)
    );
    const existingBoards = new Set(
      existingBoardsSnapshot.docs.map((doc) => doc.id)
    );

    // Update repositories and their projects
    for (const repo of repositories) {
      const projects = await fetchGitHubProjects(
        userId,
        repo.owner.login,
        repo.name
      );
      for (const project of projects) {
        const boardId = `${repo.id}_${project.id}`;
        await setDoc(doc(db, `users/${userId}/githubBoards/${boardId}`), {
          id: boardId,
          name: project.name,
          repoName: repo.name,
          repoOwner: repo.owner.login,
          projectId: project.id,
          columns: project.columns,
          createdAt: project.created_at,
          updatedAt: project.updated_at,
        });
        existingBoards.delete(boardId);
      }
    }

    // Remove boards that no longer exist
    for (const boardId of existingBoards) {
      await deleteDoc(doc(db, `users/${userId}/githubBoards/${boardId}`));
    }

    console.log("GitHub projects synced successfully");
    return { userInfo, organizations, repositories };
  } catch (error) {
    console.error("Error syncing GitHub projects:", error);
    throw new Error(`Failed to sync GitHub projects: ${error.message}`);
  }
}

async function fetchGitHubProjects(userId, owner, repo) {
  try {
    const fetchProjectsFunction = httpsCallable(
      functions,
      "fetchGitHubProjects"
    );
    const result = await fetchProjectsFunction({ userId, owner, repo });
    return result.data;
  } catch (error) {
    console.error("Error fetching GitHub projects:", error);
    throw new Error(`Failed to fetch GitHub projects: ${error.message}`);
  }
}

export async function createGitHubProject(userId, name, background) {
  try {
    const createProjectFunction = httpsCallable(
      functions,
      "createGitHubProject"
    );
    const result = await createProjectFunction({ userId, name });
    const project = result.data;

    const boardId = `${project.repository_id}_${project.id}`;
    await setDoc(doc(db, `users/${userId}/githubBoards/${boardId}`), {
      id: boardId,
      name: project.name,
      repoName: project.repository.name,
      repoOwner: project.repository.owner.login,
      projectId: project.id,
      columns: project.columns,
      createdAt: project.created_at,
      updatedAt: project.updated_at,
      background: background,
    });

    return {
      id: boardId,
      name: project.name,
      background: background,
      isGitHub: true,
    };
  } catch (error) {
    console.error("Error creating GitHub project:", error);
    throw new Error(`Failed to create GitHub project: ${error.message}`);
  }
}
