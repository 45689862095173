import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DarkModeContext } from "../context/DarkModeContext";
import Sidebar from "../components/Sidebar";
import {
  FiFolder,
  FiFile,
  FiEye,
  FiTrash2,
  FiUpload,
  FiPlus,
  FiGrid,
  FiList,
  FiSearch,
  FiMoreVertical,
  FiImage,
  FiFilm,
  FiMusic,
  FiFileText,
  FiFolderPlus,
  FiX,
  FiDownload,
  FiArrowLeft,
  FiChevronRight,
  FiCloud,
} from "react-icons/fi";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  listAll,
  deleteObject,
  getMetadata,
} from "firebase/storage";
import { auth, functions } from "../firebase/config";
import { httpsCallable } from "firebase/functions";
import { motion, AnimatePresence } from "framer-motion";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { FiChevronDown } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import { useCallback } from "react";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { addRecentItem, getRecentItems } from "../utils/recentItemsUtils";

const formatStorageSize = (bytes) => {
  const mb = bytes / (1024 * 1024);
  if (mb >= 1024) {
    return `${(mb / 1024).toFixed(2)} GB`;
  }
  return `${mb.toFixed(2)} MB`;
};

const Tab = ({ id, title, isActive, onClick, onClose, isDarkMode }) => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    className={`flex items-center px-4 py-2 rounded-lg cursor-pointer transition-all duration-200 ${
      isActive
        ? isDarkMode
          ? "bg-indigo-600 text-white"
          : "bg-white text-indigo-600"
        : isDarkMode
        ? "bg-gray-800 text-gray-300 hover:bg-gray-700"
        : "bg-white text-gray-600 hover:bg-gray-200"
    }`}
    onClick={onClick}
  >
    <span className="font-medium mr-2">{title}</span>
    {id !== "home" && (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onClose(id);
        }}
        className={`ml-2 transition-colors duration-200 rounded-full ${
          isDarkMode
            ? "text-gray-400 hover:text-white"
            : "text-gray-500 hover:text-indigo-600"
        }`}
      >
        <FiTrash2 className="w-4 h-4" />
      </button>
    )}
  </motion.div>
);

const TabContainer = ({ children, isDarkMode }) => (
  <div
    className={`flex items-end space-x-1 ${
      isDarkMode ? "bg-gray-900" : "bg-white"
    } p-2 rounded-xl shadow-md`}
  >
    <AnimatePresence>{children}</AnimatePresence>
  </div>
);

function AppFiles() {
  const navigate = useNavigate();
  const { isDarkMode } = useContext(DarkModeContext);
  const [selectedFolder, setSelectedFolder] = useState("My Files");
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [storageUsed, setStorageUsed] = useState(0);
  const [storageLimit, setStorageLimit] = useState(0);
  const [tabs, setTabs] = useState([{ id: "home", title: "Home" }]);
  const [activeTab, setActiveTab] = useState("home");
  const [viewMode, setViewMode] = useState("grid");
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropUpOpen, setIsDropUpOpen] = useState(false);
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [currentPath, setCurrentPath] = useState("My Files");
  const [viewedImage, setViewedImage] = useState(null);
  const [cloudProviders, setCloudProviders] = useState([]);
  const [currentProvider, setCurrentProvider] = useState("local"); // 'local'
  const [error, setError] = useState(null);
  const [recentFiles, setRecentFiles] = useState([]);
  const [oneDrivePersonalFiles, setOneDrivePersonalFiles] = useState([]);
  const [oneDriveWorkFiles, setOneDriveWorkFiles] = useState([]);

  useEffect(() => {
    if (auth.currentUser) {
      fetchFiles();
      fetchStorageInfo();
      fetchFolders();
      fetchRecentFiles();
    }
  }, [currentPath, auth.currentUser]);

  useEffect(() => {
    if (auth.currentUser) {
      fetchOneDriveFiles("personal");
      fetchOneDriveFiles("work");
    }
  }, [currentPath, auth.currentUser]);

  const fetchOneDriveFiles = async (accountType, folderId = "root") => {
    try {
      const fetchOneDriveFiles = httpsCallable(functions, "fetchOneDriveFiles");
      const result = await fetchOneDriveFiles({
        folderId,
        accountType,
      });
      if (accountType === "personal") {
        setOneDrivePersonalFiles(result.data.files);
      } else {
        setOneDriveWorkFiles(result.data.files);
      }
    } catch (error) {
      console.error(`Error fetching OneDrive ${accountType} files:`, error);
    }
  };

  const handleProviderChange = (provider) => {
    setCurrentProvider(provider);
    setCurrentPath("My Files");
    setSelectedFolder("My Files");
  };

  const fetchRecentFiles = async () => {
    try {
      const recentItems = await getRecentItems(auth.currentUser.uid, "file");
      setRecentFiles(recentItems);
    } catch (error) {
      console.error("Error fetching recent files:", error);
    }
  };

  useEffect(() => {
    if (auth.currentUser) {
      fetchFiles();
      fetchStorageInfo();
      fetchFolders();
    }
  }, [currentPath, auth.currentUser]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        fetchFiles();
        fetchStorageInfo();
        fetchFolders();
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchStorageInfo = async () => {
    try {
      const getUserStorageInfo = httpsCallable(functions, "getUserStorageInfo");
      const result = await getUserStorageInfo();
      setStorageUsed(result.data.storageUsed);
      setStorageLimit(result.data.storageLimit);
      const plan = result.data.plan;
      const subscriptionStatus = result.data.subscriptionStatus;
      console.log(`Current plan: ${plan}, Status: ${subscriptionStatus}`);
    } catch (error) {
      console.error("Error fetching storage info:", error);
      setStorageUsed(0);
      setStorageLimit(5 * 1024 * 1024 * 1024);
    }
  };

  const fetchFolders = async () => {
    if (!auth.currentUser) {
      console.log("User not authenticated");
      return;
    }

    const storage = getStorage();
    const userId = auth.currentUser.uid;
    const listRef = ref(storage, `users/${userId}/My Files`);

    try {
      const res = await listAll(listRef);
      const fetchedFolders = res.prefixes.map((folderRef) => folderRef.name);

      console.log("Fetched folders:", fetchedFolders);
    } catch (error) {
      console.error("Error fetching folders:", error);
    }
  };

  const fetchFiles = async () => {
    if (!auth.currentUser) {
      console.log("User not authenticated");
      return;
    }

    console.log(`Fetching files for path: ${currentPath}`);

    const storage = getStorage();
    const userId = auth.currentUser.uid;
    const listRef = ref(storage, `users/${userId}/${currentPath}`);

    try {
      const res = await listAll(listRef);
      console.log(
        `Found ${res.items.length} items and ${res.prefixes.length} folders in ${currentPath}`
      );

      const foldersData = res.prefixes.map((folderRef) => ({
        name: folderRef.name,
        isFolder: true,
        fullPath: folderRef.fullPath,
      }));

      const filesData = await Promise.all(
        res.items
          .filter((item) => !item.name.endsWith(".folder"))
          .map(async (itemRef) => {
            try {
              const url = await getDownloadURL(itemRef);
              const metadata = await getMetadata(itemRef);
              return {
                name: itemRef.name,
                url,
                size: metadata.size,
                type: metadata.contentType,
                lastModified: metadata.updated,
                isFolder: false,
                fullPath: itemRef.fullPath,
              };
            } catch (error) {
              console.error(`Error fetching file ${itemRef.name}:`, error);
              return null;
            }
          })
      );
      const validFiles = filesData.filter((file) => file !== null);
      console.log(
        `Setting ${validFiles.length} files and ${foldersData.length} folders in state`
      );
      setFiles([...foldersData, ...validFiles]);
    } catch (error) {
      console.error("Error fetching files:", error);
      if (error.code === "storage/unauthorized") {
        console.error(
          "Permission denied. Check Storage Rules and authentication."
        );
      }
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (storageUsed + file.size > storageLimit) {
      alert(
        "You have exceeded your storage limit. Please upgrade your plan or delete some files."
      );
      return;
    }

    setUploading(true);
    setUploadProgress(0);
    const storage = getStorage();
    const userId = auth.currentUser.uid;
    const storageRef = ref(
      storage,
      `users/${userId}/${currentPath}/${file.name}`
    );

    try {
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploading(false);
          setUploadProgress(0);
          fetchFiles();
          fetchStorageInfo();
        }
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
    }
  };

  const handleDeleteFile = async (file) => {
    setDeleteConfirmation(file);
  };

  const handleDeleteFolder = async (folder) => {
    setDeleteConfirmation(folder);
  };

  const confirmDelete = async () => {
    const item = deleteConfirmation;
    if (item.isFolder) {
      const storage = getStorage();
      const userId = auth.currentUser.uid;
      const folderRef = ref(storage, `users/${userId}/My Files/${item.name}`);

      try {
        const res = await listAll(folderRef);
        await Promise.all(res.items.map((itemRef) => deleteObject(itemRef)));
        await Promise.all(
          res.prefixes.map(async (prefixRef) => {
            const subfolderRef = ref(storage, prefixRef.fullPath);
            await handleDeleteFolder({ name: subfolderRef.name });
          })
        );
        const folderMarkerRef = ref(storage, `${folderRef.fullPath}/.folder`);
        await deleteObject(folderMarkerRef);
        console.log(`Folder ${item.name} deleted successfully`);
      } catch (error) {
        console.error("Error deleting folder:", error);
      }
    } else {
      const storage = getStorage();
      const userId = auth.currentUser.uid;
      const fileRef = ref(storage, `users/${userId}/My Files/${item.name}`);
      try {
        await deleteObject(fileRef);
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    }
    setDeleteConfirmation(null);
    fetchFiles();
    fetchStorageInfo();
  };

  const cancelDelete = () => {
    setDeleteConfirmation(null);
  };

  const canViewOnSite = (fileType) => {
    return (
      fileType.startsWith("image/") ||
      fileType.startsWith("video/") ||
      fileType.startsWith("audio/")
    );
  };

  const openNewTab = () => {
    const newTab = { id: `tab-${Date.now()}`, title: "New Tab" };
    setTabs([...tabs, newTab]);
    setActiveTab(newTab.id);
  };

  const closeTab = (tabId) => {
    if (tabId === "home") return;
    const newTabs = tabs.filter((tab) => tab.id !== tabId);
    setTabs(newTabs);
    if (activeTab === tabId) {
      setActiveTab(newTabs[newTabs.length - 1].id);
    }
  };

  const filteredFiles = files.filter((file) =>
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleFolderClick = (folder) => {
    if (currentProvider === "local") {
      const newPath = folder.fullPath.replace(
        `users/${auth.currentUser.uid}/`,
        ""
      );
      setCurrentPath(newPath);
    } else {
      // For OneDrive, we'll use the folder's ID to fetch its contents
      fetchOneDriveFiles(
        currentProvider === "onedrive-personal" ? "personal" : "work",
        folder.id
      );
    }
    setSelectedFolder(folder.name);
  };

  const renderFiles = () => {
    let filesToRender = [];
    switch (currentProvider) {
      case "local":
        filesToRender = files;
        break;
      case "onedrive-personal":
        filesToRender = oneDrivePersonalFiles;
        break;
      case "onedrive-work":
        filesToRender = oneDriveWorkFiles;
        break;
      default:
        filesToRender = files;
    }

    return (
      <div
        className={
          viewMode === "grid"
            ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
            : "space-y-2"
        }
      >
        {filesToRender.map((file) => renderFileItem(file))}
      </div>
    );
  };

  const renderFileItem = (item) => {
    const FileIcon =
      item.folder || item.isFolder
        ? FiFolder
        : getFileIcon(item.mimeType || item.type || "application/octet-stream");

    const isFolder = item.folder || item.isFolder;
    const fileName = item.name;
    const fileSize = item.size ? formatStorageSize(item.size) : "N/A";
    const lastModified =
      item.lastModified || item.lastModifiedDateTime
        ? new Date(
            item.lastModified || item.lastModifiedDateTime
          ).toLocaleDateString()
        : "N/A";

    return (
      <div
        key={item.id || item.name}
        className={`p-4 rounded-lg transition-all duration-300 ${
          isDarkMode
            ? "bg-gray-800 hover:bg-gray-700"
            : "bg-white hover:shadow-lg"
        } ${
          isFolder || (item.mimeType && item.mimeType.startsWith("image/"))
            ? "cursor-pointer"
            : "cursor-default"
        }`}
      >
        <div className="flex items-center justify-between mb-2">
          <div
            className="flex items-center flex-grow"
            onClick={() => {
              if (isFolder) {
                handleFolderClick(item);
              } else if (item.mimeType && item.mimeType.startsWith("image/")) {
                setViewedImage(item);
              }
            }}
          >
            <FileIcon
              className={`text-2xl mr-3 ${
                isDarkMode ? "text-blue-400" : "text-blue-500"
              }`}
            />
            <span
              className={`text-sm font-medium ${
                isDarkMode ? "text-blue-300" : "text-blue-600"
              } ${
                isFolder ||
                (item.mimeType && item.mimeType.startsWith("image/"))
                  ? "cursor-pointer"
                  : "cursor-default"
              }`}
            >
              {fileName}
            </span>
          </div>
          {currentProvider === "local" &&
            (isFolder ? (
              <FolderDropdown item={item} isDarkMode={isDarkMode} />
            ) : (
              <FileDropdown item={item} isDarkMode={isDarkMode} />
            ))}
        </div>
        {!isFolder && (
          <p
            className={`text-xs mb-2 ${
              isDarkMode ? "text-gray-400" : "text-gray-600"
            }`}
          >
            {fileSize} • {lastModified}
          </p>
        )}
      </div>
    );
  };

  const FolderDropdown = ({ item, isDarkMode }) => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`p-1 rounded-full transition-colors duration-200 ${
              isDarkMode ? "hover:bg-gray-600" : "hover:bg-gray-200"
            }`}
            onClick={(e) => e.stopPropagation()} // Prevent folder click when clicking dropdown
          >
            <FiMoreVertical />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute right-0 mt-2 w-56 origin-top-right rounded-md shadow-lg ${
              isDarkMode ? "bg-gray-700" : "bg-white"
            } ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFolderClick(item);
                    }}
                    className={`${
                      active
                        ? isDarkMode
                          ? "bg-gray-600 text-white"
                          : "bg-gray-100 text-gray-900"
                        : isDarkMode
                        ? "text-gray-300"
                        : "text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <FiFolder className="mr-3 h-5 w-5" aria-hidden="true" />
                    Open Folder
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteFolder(item);
                    }}
                    className={`${
                      active
                        ? isDarkMode
                          ? "bg-gray-600 text-white"
                          : "bg-gray-100 text-gray-900"
                        : isDarkMode
                        ? "text-gray-300"
                        : "text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <FiTrash2 className="mr-3 h-5 w-5" aria-hidden="true" />
                    Delete Folder
                  </button>
                )}
              </Menu.Item>
              {/* Add more folder-specific actions here */}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const FileDropdown = ({ item, isDarkMode }) => {
    return (
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`p-1 rounded-full transition-colors duration-200 ${
              isDarkMode ? "hover:bg-gray-600" : "hover:bg-gray-200"
            }`}
          >
            <FiMoreVertical />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={`absolute right-0 mt-2 w-56 origin-top-right rounded-md shadow-lg ${
              isDarkMode ? "bg-gray-700" : "bg-white"
            } ring-1 ring-black ring-opacity-5 focus:outline-none`}
          >
            <div className="py-1">
              {!item.isFolder && (
                <>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => window.open(item.url, "_blank")}
                        className={`${
                          active
                            ? isDarkMode
                              ? "bg-gray-600 text-white"
                              : "bg-gray-100 text-gray-900"
                            : isDarkMode
                            ? "text-gray-300"
                            : "text-gray-700"
                        } group flex w-full items-center px-4 py-2 text-sm`}
                      >
                        <FiDownload
                          className="mr-3 h-5 w-5"
                          aria-hidden="true"
                        />
                        Download
                      </button>
                    )}
                  </Menu.Item>
                  {canViewOnSite(item.type) && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={() => {
                            /* Implement on-site view logic */
                          }}
                          className={`${
                            active
                              ? isDarkMode
                                ? "bg-gray-600 text-white"
                                : "bg-gray-100 text-gray-900"
                              : isDarkMode
                              ? "text-gray-300"
                              : "text-gray-700"
                          } group flex w-full items-center px-4 py-2 text-sm`}
                        >
                          <FiEye className="mr-3 h-5 w-5" aria-hidden="true" />
                          View
                        </button>
                      )}
                    </Menu.Item>
                  )}
                </>
              )}
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() =>
                      item.isFolder
                        ? handleDeleteFolder(item)
                        : handleDeleteFile(item)
                    }
                    className={`${
                      active
                        ? isDarkMode
                          ? "bg-gray-600 text-white"
                          : "bg-gray-100 text-gray-900"
                        : isDarkMode
                        ? "text-gray-300"
                        : "text-gray-700"
                    } group flex w-full items-center px-4 py-2 text-sm`}
                  >
                    <FiTrash2 className="mr-3 h-5 w-5" aria-hidden="true" />
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  const renderTabContent = (tab) => {
    let filesToRender = [];
    switch (currentProvider) {
      case "local":
        filesToRender = filteredFiles;
        break;
      case "onedrive-personal":
        filesToRender = oneDrivePersonalFiles;
        break;
      case "onedrive-work":
        filesToRender = oneDriveWorkFiles;
        break;
      default:
        filesToRender = filteredFiles;
    }

    return (
      <div
        className={
          viewMode === "grid"
            ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
            : "space-y-2"
        }
      >
        {filesToRender.map((file) => renderFileItem(file))}
      </div>
    );
  };

  const handleCreateFolder = async () => {
    if (newFolderName.trim() === "") return;

    const userId = auth.currentUser.uid;
    try {
      const storage = getStorage();
      const folderRef = ref(
        storage,
        `users/${userId}/My Files/${newFolderName}/.folder`
      );
      const emptyBlob = new Blob([""], { type: "text/plain" });
      await uploadBytesResumable(folderRef, emptyBlob);

      setNewFolderName("");
      setIsCreateFolderModalOpen(false);
      fetchFiles();
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const handleFolderUpload = (event) => {
    const files = event.target.files;
    // Implement folder upload logic here
    console.log("Uploading folder:", files);
    setIsDropUpOpen(false);
  };

  const dropUpVariants = {
    hidden: { opacity: 0, y: 20, scale: 0.95 },
    visible: { opacity: 1, y: 0, scale: 1 },
  };

  const buttonVariants = {
    closed: { rotate: 0 },
    open: { rotate: 45 },
  };

  const folderTransition = {
    type: "tween",
    ease: "anticipate",
    duration: 0.3,
  };

  const folderVariants = {
    initial: { opacity: 0, x: -20 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
  };

  const breadcrumbVariants = {
    initial: { opacity: 0, y: -10 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 10 },
  };

  const renderBreadcrumbs = () => {
    const pathParts = currentPath.split("/");
    return (
      <div className="flex items-center space-x-2 mb-4">
        <AnimatePresence mode="popLayout">
          {pathParts.map((part, index) => (
            <motion.div
              key={index}
              variants={breadcrumbVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={folderTransition}
              className="flex items-center"
            >
              {index > 0 && <FiChevronRight className="text-gray-400 mx-2" />}
              <button
                onClick={() => handleBreadcrumbClick(index)}
                className={`px-2 py-1 rounded-md transition-colors duration-200 ${
                  index === pathParts.length - 1
                    ? isDarkMode
                      ? "bg-blue-600 text-white"
                      : "bg-blue-100 text-blue-800"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-700"
                    : "text-gray-700 hover:bg-gray-200"
                }`}
              >
                {part}
              </button>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    );
  };

  const handleBreadcrumbClick = (index) => {
    const newPath = currentPath
      .split("/")
      .slice(0, index + 1)
      .join("/");
    setCurrentPath(newPath);
    setSelectedFolder(newPath.split("/").pop() || "My Files");
  };

  const UploadMenu = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <div className="relative inline-block text-left mt-4">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className={`flex items-center justify-center px-4 py-2 text-sm font-medium rounded-md transition-all duration-200 ${
            isDarkMode
              ? "bg-gray-800 text-white hover:bg-gray-700"
              : "bg-white text-gray-800 hover:bg-gray-100"
          } border ${
            isDarkMode ? "border-gray-700" : "border-gray-300"
          } shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
        >
          <FiUpload className="mr-2 h-5 w-5" />
          Upload
          <FiChevronDown
            className={`ml-2 h-5 w-5 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </button>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className={`absolute left-0 mt-2 w-56 rounded-md shadow-lg ${
              isDarkMode ? "bg-gray-800" : "bg-white"
            } ring-1 ring-black ring-opacity-5 focus:outline-none z-10`}
          >
            <div className="py-1">
              <UploadMenuItem
                icon={FiFile}
                label="Upload Files"
                onClick={() => document.getElementById("fileUpload").click()}
              />
              <UploadMenuItem
                icon={FiFolder}
                label="Upload Folder"
                onClick={() => document.getElementById("folderUpload").click()}
              />
              <UploadMenuItem
                icon={FiPlus}
                label="Create Folder"
                onClick={() => setIsCreateFolderModalOpen(true)}
              />
            </div>
          </div>
        </Transition>
      </div>
    );
  };

  const UploadMenuItem = ({ icon: Icon, label, onClick }) => (
    <button
      onClick={onClick}
      className={`flex items-center w-full px-4 py-2 text-sm ${
        isDarkMode
          ? "text-gray-300 hover:bg-gray-700 hover:text-white"
          : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
      } transition-colors duration-150`}
    >
      <Icon className="mr-3 h-5 w-5" />
      {label}
    </button>
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        uploadFile(file);
      });
    },
    [currentPath]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const uploadFile = async (file) => {
    if (storageUsed + file.size > storageLimit) {
      alert(
        "You have exceeded your storage limit. Please upgrade your plan or delete some files."
      );
      return;
    }

    setUploading(true);
    setUploadProgress(0);
    const storage = getStorage();
    const userId = auth.currentUser.uid;
    const storageRef = ref(
      storage,
      `users/${userId}/${currentPath}/${file.name}`
    );

    try {
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setUploadProgress(progress);
        },
        (error) => {
          console.error("Upload error:", error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          setUploading(false);
          setUploadProgress(0);
          fetchFiles();
          fetchStorageInfo();
        }
      );
    } catch (error) {
      console.error("Error uploading file:", error);
      setUploading(false);
    }
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      y: -50,
      scale: 0.95,
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 30,
      },
    },
    exit: {
      opacity: 0,
      y: 50,
      scale: 0.95,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <div
      className={`flex h-screen ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      <div className="flex flex-1 overflow-hidden">
        {/* File Explorer Sidebar */}
        <aside
          className={`w-72 h-full border-r ${
            isDarkMode ? "border-gray-700" : "border-gray-200"
          } overflow-y-auto transition-all duration-300 ease-in-out`}
        >
          <div className="p-4">
            <UploadMenu className="mb-5" />
            <nav className="mt-5">
              <button
                onClick={() => handleProviderChange("local")}
                className={`w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center mb-2 ${
                  currentProvider === "local"
                    ? isDarkMode
                      ? "bg-blue-600 text-white"
                      : "bg-blue-50 text-blue-700"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <FiFolder className="mr-3" size={18} />
                <span className="font-medium">My Files</span>
              </button>
              <button
                onClick={() => handleProviderChange("onedrive-personal")}
                className={`w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center mb-2 ${
                  currentProvider === "onedrive-personal"
                    ? isDarkMode
                      ? "bg-blue-600 text-white"
                      : "bg-blue-50 text-blue-700"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <FiCloud className="mr-3" size={18} />
                <span className="font-medium">OneDrive Personal</span>
              </button>
              <button
                onClick={() => handleProviderChange("onedrive-work")}
                className={`w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center mb-2 ${
                  currentProvider === "onedrive-work"
                    ? isDarkMode
                      ? "bg-blue-600 text-white"
                      : "bg-blue-50 text-blue-700"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <FiCloud className="mr-3" size={18} />
                <span className="font-medium">OneDrive Work</span>
              </button>
            </nav>
          </div>
          <div className="sticky bottom-0 left-0 right-0 w-full p-4 bg-opacity-90 backdrop-blur-sm">
            <h3
              className={`text-sm font-semibold mb-2 ${
                isDarkMode ? "text-gray-400" : "text-gray-600"
              }`}
            >
              Storage
            </h3>
            <div
              className={`h-2 rounded-full ${
                isDarkMode ? "bg-gray-700" : "bg-gray-200"
              }`}
            >
              <div
                className="bg-blue-500 h-2 rounded-full transition-all duration-300 ease-in-out"
                style={{ width: `${(storageUsed / storageLimit) * 100}%` }}
              ></div>
            </div>
            <div className="flex justify-between items-center mt-2">
              <p
                className={`text-xs ${
                  isDarkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {formatStorageSize(storageUsed)}
              </p>
              <p
                className={`text-xs ${
                  isDarkMode ? "text-gray-400" : "text-gray-600"
                }`}
              >
                {formatStorageSize(storageLimit)}
              </p>
            </div>
          </div>
        </aside>

        {/* Main Content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="bg-transparent">
            <TabContainer isDarkMode={isDarkMode}>
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  id={tab.id}
                  title={tab.title}
                  isActive={activeTab === tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  onClose={closeTab}
                  isDarkMode={isDarkMode}
                />
              ))}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={openNewTab}
                className={`ml-2 p-2 rounded-full transition-colors duration-200 ${
                  isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-200"
                }`}
              >
                <FiPlus className="w-5 h-5 text-blue-500" />
              </motion.button>
            </TabContainer>
          </div>
          <div
            className={`flex items-center justify-between p-4 border-b ${
              isDarkMode ? "border-gray-700" : "border-gray-200"
            }`}
          >
            <div className="flex-1">
              {renderBreadcrumbs()}
              <div className="flex items-center space-x-4">
                <div
                  className={`relative ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  <input
                    type="text"
                    placeholder="Search files..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className={`pl-8 pr-4 py-2 rounded-lg ${
                      isDarkMode
                        ? "bg-gray-800 text-white placeholder-gray-400"
                        : "bg-white text-gray-800 placeholder-gray-500"
                    }`}
                  />
                  <FiSearch className="absolute left-2 top-1/2 transform -translate-y-1/2" />
                </div>
              </div>
            </div>

            <div className="flex items-center space-x-2">
              <button
                onClick={() => setViewMode("grid")}
                className={`p-2 rounded-lg ${
                  viewMode === "grid"
                    ? isDarkMode
                      ? "bg-gray-800 text-white"
                      : "bg-white text-gray-800"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                <FiGrid />
              </button>
              <button
                onClick={() => setViewMode("list")}
                className={`p-2 rounded-lg ${
                  viewMode === "list"
                    ? isDarkMode
                      ? "bg-gray-800 text-white"
                      : "bg-white text-gray-800"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                <FiList />
              </button>
            </div>
          </div>
          <main className="flex-1 overflow-y-auto p-6">
            <input {...getInputProps()} />
            {renderTabContent(tabs.find((tab) => tab.id === activeTab))}
          </main>
        </div>
      </div>
    </div>
  );
}

const ImageViewer = ({ image, onClose, isDarkMode }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50"
      onClick={onClose}
    >
      <div className="relative w-full max-w-4xl">
        <img
          src={image.url}
          alt={image.name}
          className="w-full h-auto max-h-[90vh] object-contain rounded-lg shadow-2xl"
          onClick={(e) => e.stopPropagation()}
        />
        <button
          className={`absolute top-4 right-4 p-2 rounded-full transition-colors duration-200 ${
            isDarkMode
              ? "bg-gray-800 text-white hover:bg-gray-700"
              : "bg-white text-gray-800 hover:bg-gray-100"
          }`}
          onClick={onClose}
        >
          <FiX size={24} />
        </button>
        <div
          className={`absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t ${
            isDarkMode
              ? "from-black to-transparent text-white"
              : "from-white to-transparent text-gray-800"
          }`}
        >
          <p className="text-lg font-semibold mb-1">{image.name}</p>
          <p className="text-sm opacity-75">
            {(image.size / 1024 / 1024).toFixed(2)} MB •{" "}
            {new Date(image.lastModified).toLocaleDateString()}
          </p>
        </div>
      </div>
    </motion.div>
  );
};

// Helper function to get the appropriate icon for file types
function getFileIcon(fileType) {
  // Add more file type checks and corresponding icons as needed
  if (fileType.startsWith("image/")) return FiImage;
  if (fileType.startsWith("video/")) return FiFilm;
  if (fileType.startsWith("audio/")) return FiMusic;
  if (fileType === "application/pdf") return FiFileText;
  return FiFile;
}

export default AppFiles;
