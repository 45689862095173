import React, { useState, useContext } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import BoxiaCopilot from "./components/BoxiaCopilot";
import AppRoutes from "./Routes";
import { DarkModeProvider, DarkModeContext } from "./context/DarkModeContext";
import { UserProvider, UserContext } from "./context/UserContext";
import Sidebar from "./components/Sidebar";

function AppContent() {
  const [isCopilotOpen, setIsCopilotOpen] = useState(false);
  const { userData } = useContext(UserContext);
  const { isDarkMode } = useContext(DarkModeContext);
  const location = useLocation();

  const showCopilot = userData && location.pathname.startsWith("/app");
  const showSidebar = location.pathname.startsWith("/app");

  return (
    <div
      className={`flex h-screen overflow-hidden ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-white"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      <div className="flex flex-1">
        {showSidebar && <Sidebar />}
        <div className="flex-1 flex flex-col overflow-hidden">
          <main className="flex-1 overflow-y-auto">
            <AppRoutes />
          </main>
          {showCopilot && (
            <BoxiaCopilot isOpen={isCopilotOpen} setIsOpen={setIsCopilotOpen} />
          )}
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <DarkModeProvider>
      <UserProvider>
        <Router>
          <AppContent />
        </Router>
      </UserProvider>
    </DarkModeProvider>
  );
}

export default App;
