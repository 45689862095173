import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";

const MICROSOFT_PERSONAL_CLIENT_ID = "294f98e4-d12f-46e8-8363-201b6f358e79";
const MICROSOFT_WORK_CLIENT_ID = "7c2319fb-1865-456b-a30d-af865141b3b9";
const REDIRECT_URI = encodeURIComponent(
  "https://boxia.app/microsoft365-oauth-callback"
);
const SCOPES = encodeURIComponent(
  "User.Read Tasks.ReadWrite Tasks.ReadWrite.Shared Files.Read Files.Read.All Files.ReadWrite Files.ReadWrite.All offline_access"
);

function Microsoft365OAuthCallback() {
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state) {
      exchangeCodeForTokens(code, state);
    } else {
      setError("Authorization code not found.");
      navigate("/app/integrations");
    }
  }, [location, navigate]);

  const exchangeCodeForTokens = async (code, state) => {
    try {
      const exchangeMicrosoft365Code = httpsCallable(
        functions,
        "exchangeMicrosoft365Code"
      );
      const accountType = state === "personal" ? "personal" : "work";
      const result = await exchangeMicrosoft365Code({ code, accountType });
      console.log("Exchange result:", result);
      navigate("/app/integrations");
    } catch (error) {
      console.error("Error exchanging code for tokens:", error);
      setError(`Failed to connect Microsoft 365 account: ${error.message}`);
      navigate("/app/integrations");
    }
  };

  return (
    <div>
      {error && <div className="text-red-500">{error}</div>}
      Processing Microsoft 365 authorization...
    </div>
  );
}

export function getMicrosoft365PersonalAuthUrl() {
  return `https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?client_id=${MICROSOFT_PERSONAL_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&response_mode=query&scope=${SCOPES}&state=personal`;
}

export function getMicrosoft365WorkAuthUrl() {
  return `https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize?client_id=${MICROSOFT_WORK_CLIENT_ID}&response_type=code&redirect_uri=${REDIRECT_URI}&response_mode=query&scope=${SCOPES}&state=work`;
}

export default Microsoft365OAuthCallback;