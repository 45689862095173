import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
import { DarkModeContext } from "../context/DarkModeContext";
import Sidebar from "../components/Sidebar";
import { auth } from "../firebase/config";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { syncGitHubProjects } from "../utils/githubSync";

function GitHubOAuthCallback() {
  const [status, setStatus] = useState("Processing...");
  const navigate = useNavigate();
  const location = useLocation();
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      exchangeCodeForToken(code);
    } else {
      setStatus("Error: No code received");
      setTimeout(() => navigate("/app/settings"), 3000);
    }
  }, [location, navigate]);

  const exchangeCodeForToken = async (code) => {
    try {
      console.log("Exchanging code for token...");
      const exchangeGitHubCode = httpsCallable(functions, "exchangeGitHubCode");
      const result = await exchangeGitHubCode({ code });
      console.log("Exchange result:", result);
      if (result.data.success) {
        setStatus("GitHub account connected successfully!");
        const userId = auth.currentUser.uid;
        const db = getFirestore();
        await updateDoc(doc(db, "users", userId), {
          githubConnected: true,
        });

        // Sync GitHub data after successful connection
        await syncGitHubProjects(userId);
      } else {
        setStatus(
          "GitHub account connection completed, but with warnings. Check console for details."
        );
        console.warn(
          "GitHub account connection completed with warnings:",
          result.data
        );
      }
      setTimeout(() => navigate("/app/integrations"), 3000);
    } catch (error) {
      console.error("Error exchanging code for token:", error);
      if (error.details) {
        console.error("Error details:", error.details);
      }
      setStatus(`Error connecting GitHub account: ${error.message}`);
      setTimeout(() => navigate("/app/integrations"), 3000);
    }
  };

  return (
    <div
      className={`flex min-h-screen w-full ${
        isDarkMode ? "bg-gray-900 text-white" : "bg-gray-50 text-gray-900"
      }`}
    >
      <Sidebar />
      <main className="flex-1 p-8 max-w-7xl mx-auto">
        <h1 className="text-4xl font-bold mb-8">GitHub Account Connection</h1>
        <div
          className={`w-full ${
            isDarkMode ? "bg-gray-800" : "bg-white"
          } rounded-lg shadow-lg p-8`}
        >
          <h2 className="text-3xl font-semibold mb-6">{status}</h2>
          <p className="text-lg">You will be redirected shortly.</p>
        </div>
      </main>
    </div>
  );
}

export default GitHubOAuthCallback;