import { db } from "../firebase/config";
import { collection, getDocs, addDoc, deleteDoc, doc, updateDoc, query, orderBy, limit } from "firebase/firestore";

const getUserEventsCollection = (userId) => collection(db, "users", userId, "events");

export const getEvents = async (userId) => {
  try {
    const eventsCollection = getUserEventsCollection(userId);
    const eventsSnapshot = await getDocs(eventsCollection);

    return eventsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error fetching events:", error);
    throw error;
  }
};

export const addEvent = async (userId, event) => {
  try {
    const eventsCollection = getUserEventsCollection(userId);
    const docRef = await addDoc(eventsCollection, event);
    return { id: docRef.id, ...event };
  } catch (error) {
    console.error("Error adding event:", error);
    throw error;
  }
};

export const deleteEvent = async (userId, eventId) => {
  try {
    const eventDoc = doc(db, "users", userId, "events", eventId);
    await deleteDoc(eventDoc);
  } catch (error) {
    console.error("Error deleting event:", error);
    throw error;
  }
};

export const updateEvent = async (userId, event) => {
  try {
    const eventDoc = doc(db, "users", userId, "events", event.id);
    await updateDoc(eventDoc, event);
    return event;
  } catch (error) {
    console.error("Error updating event:", error);
    throw error;
  }
};

export const getRecentEvents = async (userId, limitCount = 5) => {
  try {
    const eventsRef = collection(db, `users/${userId}/events`);
    const eventsQuery = query(eventsRef, orderBy("start", "desc"), limit(limitCount));
    const eventsSnapshot = await getDocs(eventsQuery);

    return eventsSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      start: doc.data().start.toDate(),
      end: doc.data().end.toDate(),
    }));
  } catch (error) {
    console.error("Error fetching recent events:", error);
    throw error;
  }
};

export const getNextDayOfWeek = (dayName) => {
  const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const today = new Date();
  const targetDay = days.indexOf(dayName.toLowerCase());
  const todayDay = today.getDay();
  const daysUntilTarget = (targetDay + 7 - todayDay) % 7;
  today.setDate(today.getDate() + daysUntilTarget);
  return today;
};

export const extractEventDetails = (message) => {
  const eventDetails = {};
  const dateMatch = message.match(/(\w+)\s+(\d+)/);
  const timeMatch = message.match(/(\d+):?(\d+)?\s*(am|pm)?/i);
  const titleMatch = message.match(/(.+)(?=\s+on|\s+at)/i);

  if (dateMatch) {
    const [_, month, day] = dateMatch;
    const year = new Date().getFullYear();
    eventDetails.start = new Date(year, getMonthNumber(month), parseInt(day));
  }

  if (timeMatch) {
    let [_, hours, minutes, ampm] = timeMatch;
    hours = parseInt(hours);
    minutes = minutes ? parseInt(minutes) : 0;
    if (ampm && ampm.toLowerCase() === "pm" && hours !== 12) hours += 12;
    if (ampm && ampm.toLowerCase() === "am" && hours === 12) hours = 0;
    eventDetails.start.setHours(hours, minutes);
    eventDetails.end = new Date(eventDetails.start.getTime() + 60 * 60 * 1000); // Default to 1 hour duration
  }

  if (titleMatch) {
    eventDetails.title = titleMatch[1].trim();
  }

  return eventDetails;
};

const getMonthNumber = (month) => {
  const months = [
    "january", "february", "march", "april", "may", "june",
    "july", "august", "september", "october", "november", "december"
  ];
  return months.indexOf(month.toLowerCase());
};

export const createNewEvent = async (userId, eventData) => {
  try {
    const eventsCollection = getUserEventsCollection(userId);
    const docRef = await addDoc(eventsCollection, {
      ...eventData,
      start: new Date(eventData.start).toISOString(),
      end: new Date(eventData.end).toISOString(),
    });
    return { id: docRef.id, ...eventData };
  } catch (error) {
    console.error("Error creating new event:", error);
    throw error;
  }
};