import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import {
  doc,
  getDoc,
  collection,
  query,
  limit,
  getDocs,
  orderBy,
  Timestamp,
  addDoc,
  updateDoc,
  deleteDoc,
  where,
} from "firebase/firestore";
import Sidebar from "../components/Sidebar";
import { DarkModeContext } from "../context/DarkModeContext";
import { UserContext } from "../context/UserContext";
import { motion, AnimatePresence } from "framer-motion";
import {
  FiFile,
  FiCheckSquare,
  FiCalendar,
  FiSettings,
  FiPlus,
  FiSearch,
  FiActivity,
  FiStar,
  FiTrash,
  FiList,
  FiMoreHorizontal,
  FiMessageSquare,
  FiArrowRight,
} from "react-icons/fi";
import { getRecentEvents } from "../utils/calendarUtils";
import { Menu } from "@headlessui/react";
import { Fragment } from "react";
import { NewEventPanel } from "./AppCalendar";
import { addEvent } from "../utils/calendarUtils";
import {
  StarIcon,
  PlusIcon,
  XIcon as XMarkIcon,
  HomeIcon,
  ChevronDownIcon,
} from "@heroicons/react/solid";
import {
  FaCheckSquare,
  FaStar,
  FaCalendarAlt,
  FaList,
  FaCog,
  FaSun,
} from "react-icons/fa";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function MainApp() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData } = useContext(UserContext);
  const [recentTasks, setRecentTasks] = useState([]);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);
  const [isNewEventPanelOpen, setIsNewEventPanelOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
    isRecurring: false,
    recurrence: "none",
  });
  const [isAddingEvent, setIsAddingEvent] = useState(false);
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState("");
  const [activeList, setActiveList] = useState("myDay");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [todoSections, setTodoSections] = useState({
    myDay: true,
    important: true,
    planned: true,
    lists: true,
  });
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [userLists, setUserLists] = useState([]);
  const [listTodos, setListTodos] = useState({});
  const [recentNotes, setRecentNotes] = useState([]);
  const handleNoteClick = (noteId) => {
    navigate(`/app/notes`, { state: { selectedNoteId: noteId } });
  };
  const [recentFiles, setRecentFiles] = useState([]);
  const [viewedImage, setViewedImage] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userData = userDoc.data();

        if (
          !userData ||
          !userData.subscriptionStatus ||
          userData.subscriptionStatus === "canceled"
        ) {
          navigate("/plan-selection");
        } else if (
          userData.subscriptionStatus !== "trialing" &&
          userData.subscriptionStatus !== "active"
        ) {
          navigate("/plan-selection");
        } else {
          fetchDashboardData(currentUser.uid);
          fetchTodos();
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (userData && userData.uid) {
      fetchUserLists();
    }
  }, [userData]);

  const fetchUserLists = async () => {
    try {
      const listsRef = collection(db, `users/${userData.uid}/lists`);
      const q = query(listsRef, orderBy("order", "asc"));
      const querySnapshot = await getDocs(q);
      const lists = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserLists(lists);

      // Fetch todos for each list
      const todosPromises = lists.map((list) => fetchListTodos(list.id));
      const todosResults = await Promise.all(todosPromises);

      const newListTodos = {};
      lists.forEach((list, index) => {
        newListTodos[list.id] = todosResults[index];
      });
      setListTodos(newListTodos);
    } catch (error) {
      console.error("Error fetching user lists and todos:", error);
    }
  };

  const fetchListTodos = async (listId) => {
    const todosRef = collection(db, `users/${userData.uid}/todos`);
    const q = query(
      todosRef,
      where("listId", "==", listId),
      orderBy("createdAt", "desc"),
      limit(3)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  };

  const fetchTodos = async () => {
    if (!userData || !userData.uid) return;

    setIsLoading(true);
    setError(null);
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const todosRef = collection(db, `users/${userData.uid}/todos`);
      const q = query(
        todosRef,
        where("list", "==", "myDay"),
        where("createdAt", ">=", today),
        where("createdAt", "<", tomorrow),
        orderBy("createdAt", "desc"),
        limit(5)
      );
      const querySnapshot = await getDocs(q);
      const fetchedTodos = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTodos(fetchedTodos);
    } catch (err) {
      console.error("Error fetching todos:", err);
      setError("Failed to load todos. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileClick = (file) => {
    if (file.type?.startsWith("image/")) {
      setViewedImage(file);
    } else if (file.url) {
      window.open(file.url, "_blank");
    } else {
      console.error("File URL not available");
    }
  };

  const addTodo = async (todoData) => {
    try {
      const todosRef = collection(db, `users/${userData.uid}/todos`);
      const newTodoDoc = await addDoc(todosRef, {
        ...todoData,
        completed: false,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
      });

      // Update the local state
      setListTodos((prevListTodos) => ({
        ...prevListTodos,
        [todoData.listId]: [
          { id: newTodoDoc.id, ...todoData },
          ...prevListTodos[todoData.listId].slice(0, 2),
        ],
      }));
    } catch (err) {
      console.error("Error adding todo:", err);
      setError("Failed to add todo. Please try again.");
    }
  };

  const toggleTodo = async (listId, todoId, completed) => {
    try {
      const todoRef = doc(db, `users/${userData.uid}/todos`, todoId);
      await updateDoc(todoRef, {
        completed: !completed,
        updatedAt: Timestamp.now(),
      });

      // Update the local state
      setListTodos((prevListTodos) => ({
        ...prevListTodos,
        [listId]: prevListTodos[listId].map((todo) =>
          todo.id === todoId ? { ...todo, completed: !completed } : todo
        ),
      }));
    } catch (err) {
      console.error("Error updating todo:", err);
      setError("Failed to update todo. Please try again.");
    }
  };

  const fetchDashboardData = async (userId) => {
    try {
      // Fetch recent files
      const filesRef = collection(db, `users/${userId}/files`);
      const filesQuery = query(
        filesRef,
        orderBy("lastModified", "desc"),
        limit(5)
      );
      const filesSnapshot = await getDocs(filesQuery);
      const files = await Promise.all(
        filesSnapshot.docs.map(async (doc) => {
          const fileData = doc.data();
          const storage = getStorage();
          const fileRef = ref(storage, fileData.path);
          try {
            const url = await getDownloadURL(fileRef);
            return {
              id: doc.id,
              ...fileData,
              url,
            };
          } catch (error) {
            console.error(
              `Error getting download URL for file ${fileData.name}:`,
              error
            );
            return null;
          }
        })
      );
      setRecentFiles(files.filter((file) => file !== null));

      // Fetch recent notes
      const notesRef = collection(db, `users/${userId}/notes`);
      const notesQuery = query(
        notesRef,
        orderBy("updatedAt", "desc"),
        limit(3)
      );
      const notesSnapshot = await getDocs(notesQuery);
      const notes = notesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecentNotes(notes);

      // Fetch upcoming events
      const eventsRef = collection(db, `users/${userId}/events`);
      const eventsQuery = query(eventsRef, orderBy("start", "asc"), limit(5));
      const eventsSnapshot = await getDocs(eventsQuery);
      const events = eventsSnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          start:
            data.start instanceof Timestamp
              ? data.start.toDate()
              : new Date(data.start),
          end:
            data.end instanceof Timestamp
              ? data.end.toDate()
              : new Date(data.end),
        };
      });
      setUpcomingEvents(events);

      // Fetch recent activity
      const activityQuery = query(
        collection(db, `users/${userId}/activity`),
        orderBy("timestamp", "desc"),
        limit(10)
      );
      const activitySnapshot = await getDocs(activityQuery);
      const activity = activitySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecentActivity(activity);

      // Fetch recent todos
      const todosRef = collection(db, `users/${userId}/todos`);
      const todosQuery = query(
        todosRef,
        orderBy("createdAt", "desc"),
        limit(5)
      );
      const todosSnapshot = await getDocs(todosQuery);
      const todos = todosSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt:
          doc.data().createdAt instanceof Timestamp
            ? doc.data().createdAt.toDate()
            : new Date(doc.data().createdAt),
      }));
      setTodos(todos);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      // Optionally, you can set an error state here to display to the user
      // setError("Failed to load dashboard data. Please try again.");
    }
  };

  if (!user) {
    return null;
  }

  const cardClass = `p-6 rounded-xl shadow-lg ${
    isDarkMode ? "bg-gray-800" : "bg-white"
  }`;

  const titleClass = "text-xl font-semibold mb-4";

  const handleAddEvent = async () => {
    if (!newEvent.title.trim()) {
      return;
    }
    setIsAddingEvent(true);
    try {
      const eventToAdd = {
        ...newEvent,
        start: newEvent.start.toISOString(),
        end: newEvent.end.toISOString(),
      };
      await addEvent(userData.uid, eventToAdd);
      setIsNewEventPanelOpen(false);
      // Optionally, you can update the recent events list here
      // fetchRecentEvents();
    } catch (error) {
      console.error("Error adding event:", error);
    } finally {
      setIsAddingEvent(false);
    }
  };

  const handleEventClick = (event) => {
    navigate("/app/calendar", { state: { selectedEventId: event.id } });
  };

  return (
    <div
      className={`flex min-h-screen w-full ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      <main className="flex-1 p-6 sm:p-10 overflow-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold">
              {(() => {
                const hour = new Date().getHours();
                const firstName = userData?.name?.split(" ")[0] ?? "there";
                const greeting =
                  hour < 12
                    ? "Good morning"
                    : hour < 18
                    ? "Good afternoon"
                    : "Good evening";
                return `${greeting}, ${firstName}`;
              })()}
            </h1>
            <div className="flex space-x-4">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-full flex items-center transition-all duration-300 shadow-md">
                    <FiPlus className="mr-2" />
                    <span className="font-medium">Create</span>
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 opacity-75"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-150"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-2">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => setIsNewEventPanelOpen(true)}
                            className={`${
                              active
                                ? "bg-blue-50 text-blue-700"
                                : "text-gray-700"
                            } flex items-center px-4 py-2 text-sm w-full hover:bg-blue-50 transition-colors duration-150`}
                          >
                            <FiCalendar className="mr-3" />
                            New Calendar Event
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              /* Handle new task */
                            }}
                            className={`${
                              active
                                ? "bg-blue-50 text-blue-700"
                                : "text-gray-700"
                            } flex items-center px-4 py-2 text-sm w-full hover:bg-blue-50 transition-colors duration-150`}
                          >
                            <FiCheckSquare className="mr-3" />
                            New Task
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => {
                              /* Handle new file */
                            }}
                            className={`${
                              active
                                ? "bg-blue-50 text-blue-700"
                                : "text-gray-700"
                            } flex items-center px-4 py-2 text-sm w-full hover:bg-blue-50 transition-colors duration-150`}
                          >
                            <FiFile className="mr-3" />
                            New File
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              {/* <div className="relative">
                <input
                  type="text"
                  placeholder="Search..."
                  className={`pl-10 pr-4 py-2 rounded-lg ${
                    isDarkMode
                      ? "bg-gray-700 text-white"
                      : "bg-white text-gray-900"
                  }`}
                />
                <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div> */}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
            <QuickAccessCard
              icon={<FiFile />}
              title="Files"
              link="/app/files"
              color="blue"
            />
            <QuickAccessCard
              icon={<FiCheckSquare />}
              title="Tasks"
              link="/app/tasks"
              color="green"
            />
            <QuickAccessCard
              icon={<FiCalendar />}
              title="Calendar"
              link="/app/calendar"
              color="purple"
            />
            <QuickAccessCard
              icon={<FiSettings />}
              title="Settings"
              link="/app/settings"
              color="gray"
            />
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            <div className="bg-gradient-to-br from-blue-50 to-blue-100 rounded-2xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
              <h2 className="text-2xl font-bold mb-4 text-blue-800">
                Messages
              </h2>
              <ul className="space-y-3">
                <li className="flex items-center p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300">
                  <div className="w-10 h-10 bg-blue-200 rounded-full flex items-center justify-center mr-4">
                    <img
                      src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=40&h=40&q=80"
                      alt="Alice Profile"
                      className="rounded-full"
                    />
                  </div>
                  <div className="flex-grow">
                    <span className="text-gray-700 font-medium truncate">
                      Alice Johnson
                    </span>
                    <p className="text-sm text-gray-500">
                      Hey, how's it going?
                    </p>
                  </div>
                  <a
                    href="/app/messages"
                    className="ml-2 text-gray-500 hover:text-blue-500"
                  >
                    <FiArrowRight size={20} />
                  </a>
                </li>
                <li className="flex items-center p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300">
                  <div className="w-10 h-10 bg-blue-200 rounded-full flex items-center justify-center mr-4">
                    <img
                      src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=40&h=40&q=80"
                      alt="Bob Profile"
                      className="rounded-full"
                    />
                  </div>
                  <div className="flex-grow">
                    <span className="text-gray-700 font-medium truncate">
                      Bob Smith
                    </span>
                    <p className="text-sm text-gray-500">Meeting at 3 PM?</p>
                  </div>
                  <a
                    href="/app/messages"
                    className="ml-2 text-gray-500 hover:text-blue-500"
                  >
                    <FiArrowRight size={20} />
                  </a>
                </li>
                <li className="flex items-center p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300">
                  <div className="w-10 h-10 bg-blue-200 rounded-full flex items-center justify-center mr-4">
                    <img
                      src="https://images.unsplash.com/photo-1520813792240-56fc4a3765a7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=40&h=40&q=80"
                      alt="Carol Profile"
                      className="rounded-full"
                    />
                  </div>
                  <div className="flex-grow">
                    <span className="text-gray-700 font-medium truncate">
                      Carol Davis
                    </span>
                    <p className="text-sm text-gray-500">
                      Project update sent!
                    </p>
                  </div>
                  <a
                    href="/app/messages"
                    className="ml-2 text-gray-500 hover:text-blue-500"
                  >
                    <FiArrowRight size={20} />
                  </a>
                </li>
              </ul>
            </div>

            <div className="bg-gradient-to-br from-green-50 to-green-100 rounded-2xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
              <h2 className="text-2xl font-bold mb-4 text-green-800">Notes</h2>
              <ul className="space-y-3">
                {recentNotes.map((note) => (
                  <li
                    key={note.id}
                    className="flex items-center p-3 bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 cursor-pointer"
                    onClick={() => handleNoteClick(note.id)}
                  >
                    <div className="w-10 h-10 bg-green-200 rounded-full flex items-center justify-center mr-4">
                      <FiFile className="text-green-600 text-xl" />
                    </div>
                    <div className="flex-grow">
                      <span className="text-gray-700 font-medium truncate max-w-full">
                        {note.title.length > 20
                          ? `${note.title.slice(0, 20)}...`
                          : note.title}
                      </span>
                      <p className="text-sm text-gray-500 truncate max-w-full">
                        {stripHtml(note.content).length > 50
                          ? `${stripHtml(note.content).slice(0, 50)}...`
                          : stripHtml(note.content)}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="bg-gradient-to-br from-purple-50 to-purple-100 rounded-2xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
              <h2 className="text-2xl font-bold mb-4 text-purple-800">
                Upcoming Events
              </h2>
              <ul className="space-y-4">
                {upcomingEvents.map((event) => (
                  <li
                    key={event.id}
                    className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-300 p-4 cursor-pointer"
                    onClick={() => handleEventClick(event)}
                  >
                    <div className="flex items-center">
                      <div className="w-12 h-12 bg-purple-200 rounded-full flex items-center justify-center mr-4">
                        <FiCalendar className="text-purple-600 text-xl" />
                      </div>
                      <div className="flex-grow min-w-0">
                        <h3 className="text-lg font-semibold text-gray-800 truncate">
                          {event.title}
                        </h3>
                        <p className="text-sm text-gray-500 mt-1">
                          {new Date(event.start).toLocaleDateString("en-US", {
                            weekday: "short",
                            month: "short",
                            day: "numeric",
                          })}
                        </p>
                      </div>
                      <div className="ml-4 flex-shrink-0 w-20">
                        <span className="px-3 py-1 rounded-full text-sm font-medium bg-purple-200 text-purple-800 whitespace-nowrap">
                          {(() => {
                            const time = new Date(
                              event.start
                            ).toLocaleTimeString("en-US", {
                              hour: "numeric",
                              minute: "2-digit",
                            });
                            return time.length > 8 ? time.slice(0, -3) : time;
                          })()}
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            {/* Todo Widget */}
            <div className="lg:col-span-3 bg-gradient-to-br from-yellow-50 to-yellow-100 rounded-2xl shadow-lg p-6 transition-all duration-300 hover:shadow-xl">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-bold text-yellow-800 flex items-center">
                  To-do
                </h2>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                {todoSections.myDay && (
                  <TodoSection
                    title="My Day"
                    icon={<FaSun />}
                    todos={todos.filter((todo) => todo.list === "myDay")}
                    addTodo={addTodo}
                    toggleTodo={toggleTodo}
                  />
                )}
                {todoSections.important && (
                  <TodoSection
                    title="Important"
                    icon={<FaStar />}
                    todos={todos.filter((todo) => todo.important)}
                    addTodo={addTodo}
                    toggleTodo={toggleTodo}
                  />
                )}
                {todoSections.planned && (
                  <TodoSection
                    title="Planned"
                    icon={<FaCalendarAlt />}
                    todos={todos.filter((todo) => todo.dueDate)}
                    addTodo={addTodo}
                    toggleTodo={toggleTodo}
                  />
                )}
                {userLists.map((list) => (
                  <TodoSection
                    key={list.id}
                    title={list.name}
                    icon={<FaList />}
                    todos={listTodos[list.id] || []}
                    addTodo={(title) => addTodo({ title, listId: list.id })}
                    toggleTodo={(todoId, completed) =>
                      toggleTodo(list.id, todoId, completed)
                    }
                  />
                ))}
              </div>

              <button
                onClick={() => navigate("/app/todo")}
                className="mt-4 w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center"
              >
                <FaList className="mr-2" /> View All Todos
              </button>
            </div>
          </div>
        </motion.div>
      </main>

      <NewEventPanel
        isOpen={isNewEventPanelOpen}
        onClose={() => setIsNewEventPanelOpen(false)}
        onSave={handleAddEvent}
        event={newEvent}
        setEvent={setNewEvent}
        isDarkMode={isDarkMode}
        isLoading={isAddingEvent}
      />

      {viewedImage && (
        <ImageViewer
          image={viewedImage}
          onClose={() => setViewedImage(null)}
          isDarkMode={isDarkMode}
        />
      )}
    </div>
  );
}

function TodoSection({ title, icon, todos, addTodo, toggleTodo }) {
  const [newTodo, setNewTodo] = useState("");
  const { isDarkMode } = useContext(DarkModeContext);

  const handleAddTodo = (e) => {
    e.preventDefault();
    if (newTodo.trim()) {
      addTodo(newTodo.trim());
      setNewTodo("");
    }
  };

  return (
    <motion.div
      layout
      className="bg-white rounded-lg p-6 shadow-md transition-all duration-300 hover:shadow-lg"
    >
      <div className="flex justify-between items-center mb-6">
        <h3 className="text-xl font-semibold flex items-center text-gray-900">
          <span className="text-blue-500 mr-3">{icon}</span>
          {title}
        </h3>
      </div>
      <form onSubmit={handleAddTodo} className="mb-6">
        <div className="relative">
          <input
            type="text"
            value={newTodo}
            onChange={(e) => setNewTodo(e.target.value)}
            placeholder="Add a new task"
            className={`w-full p-4 pr-12 rounded-lg bg-gray-50 text-gray-900 border border-gray-200 focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50 transition-all duration-200`}
          />
          <button
            type="submit"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
          >
            <FiPlus size={24} />
          </button>
        </div>
      </form>
      <AnimatePresence>
        <motion.ul className="space-y-4">
          {todos && todos.length > 0 ? (
            todos.map((todo) => (
              <motion.li
                key={todo.id}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="flex items-center p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors duration-200"
              >
                <input
                  type="checkbox"
                  checked={todo.completed}
                  onChange={() => toggleTodo(todo.id, todo.completed)}
                  className="form-checkbox h-5 w-5 text-blue-500 rounded border-gray-300 focus:ring-blue-500 transition duration-150 ease-in-out"
                />
                <span
                  className={`ml-4 text-gray-800 font-medium ${
                    todo.completed ? "line-through text-gray-400" : ""
                  }`}
                >
                  {todo.title}
                </span>
              </motion.li>
            ))
          ) : (
            <li className="text-gray-500 text-center">No tasks yet</li>
          )}
        </motion.ul>
      </AnimatePresence>
    </motion.div>
  );
}

function stripHtml(html) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function QuickAccessCard({ icon, title, link, color }) {
  const navigate = useNavigate();
  const { isDarkMode } = useContext(DarkModeContext);

  const colorClasses = {
    blue: "bg-blue-200 hover:bg-blue-300",
    green: "bg-green-200 hover:bg-green-300",
    purple: "bg-purple-200 hover:bg-purple-300",
    gray: "bg-gray-200 hover:bg-gray-300",
  };

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className={`${colorClasses[color]} rounded-xl p-6 cursor-pointer transition-all duration-300 text-gray-800 shadow-lg`}
      onClick={() => navigate(link)}
    >
      <div className="flex items-center justify-between">
        <div className="text-3xl">{icon}</div>
        <h3 className="text-xl font-semibold">{title}</h3>
      </div>
    </motion.div>
  );
}

const ImageViewer = ({ image, onClose, isDarkMode }) => {
  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center p-4 ${
        isDarkMode ? "bg-black bg-opacity-75" : "bg-white bg-opacity-75"
      }`}
    >
      <div className="relative max-w-4xl w-full">
        <img
          src={image.url}
          alt={image.name}
          className="w-full h-auto rounded-lg shadow-xl"
        />
        <button
          onClick={onClose}
          className={`absolute top-2 right-2 p-2 rounded-full ${
            isDarkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
          }`}
        >
          <XIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default MainApp;
