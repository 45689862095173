import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { DarkModeContext } from "../context/DarkModeContext";
import Sidebar from "../components/Sidebar";
import { motion } from "framer-motion";
import { functions, db } from "../firebase/config";
import { httpsCallable, getFunctions } from "firebase/functions";
import {
  doc,
  getDoc,
  setDoc,
  getDocs,
  query,
  collection,
} from "firebase/firestore";
import { syncGoogleDriveFiles } from "../utils/googleDriveSync";
import { syncTrelloBoards } from "../utils/trelloSync";
import { FaGoogle, FaTrello, FaMicrosoft, FaGithub } from "react-icons/fa";
import { HiLightningBolt } from "react-icons/hi";
import {
  getMicrosoft365PersonalAuthUrl,
  getMicrosoft365WorkAuthUrl,
} from "../components/Microsoft365OAuthCallback";

function AppIntegrations() {
  const { userData } = useContext(UserContext);
  const { isDarkMode } = useContext(DarkModeContext);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);
  const [isTrelloConnected, setIsTrelloConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMicrosoft365PersonalConnected, setIsMicrosoft365PersonalConnected] =
    useState(false);
  const [isMicrosoft365WorkConnected, setIsMicrosoft365WorkConnected] =
    useState(false);
  const [isGitHubConnected, setIsGitHubConnected] = useState(false);

  useEffect(() => {
    if (userData && userData.uid) {
      checkConnections();
    }
  }, [userData]);

  const checkConnections = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await checkGoogleConnection();
      await checkTrelloConnection();
      await checkMicrosoft365Connection();
      await checkGitHubConnection();
    } catch (error) {
      console.error("Error checking connections:", error);
      setError("Failed to check connection status. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const checkMicrosoft365Connection = async () => {
    try {
      const isMicrosoft365Connected = httpsCallable(
        functions,
        "isMicrosoft365Connected"
      );
      const result = await isMicrosoft365Connected();
      setIsMicrosoft365PersonalConnected(result.data.personalConnected);
      setIsMicrosoft365WorkConnected(result.data.workConnected);
    } catch (error) {
      console.error("Error checking Microsoft 365 connection:", error);
    }
  };

  const handleMicrosoft365PersonalConnect = () => {
    const authUrl = getMicrosoft365PersonalAuthUrl();
    window.location.href = authUrl;
  };

  const handleMicrosoft365WorkConnect = () => {
    const authUrl = getMicrosoft365WorkAuthUrl();
    window.location.href = authUrl;
  };

  const checkGoogleConnection = async () => {
    try {
      const userDoc = await getDoc(doc(db, `users/${userData.uid}`));
      setIsGoogleConnected(userDoc.data()?.googleConnected || false);
    } catch (error) {
      console.error("Error checking Google connection:", error);
    }
  };

  const checkTrelloConnection = async () => {
    try {
      const userDoc = await getDoc(
        doc(db, `users/${userData.uid}/integrations/trello`)
      );
      setIsTrelloConnected(userDoc.exists() && userDoc.data().accessToken);
    } catch (error) {
      console.error("Error checking Trello connection:", error);
    }
  };

  const handleConnectGoogle = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const getGoogleAuthUrl = httpsCallable(functions, "getGoogleAuthUrl");
      const result = await getGoogleAuthUrl();
      window.location.href = result.data.authUrl;
    } catch (error) {
      console.error("Error getting Google auth URL:", error);
      setError("Failed to initialize Google authorization");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnectGoogle = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await setDoc(
        doc(db, `users/${userData.uid}`),
        { googleConnected: false },
        { merge: true }
      );
      setIsGoogleConnected(false);
    } catch (error) {
      console.error("Error disconnecting Google account:", error);
      setError("Failed to disconnect Google account");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectTrello = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const functions = getFunctions();
      const getTrelloAuthUrl = httpsCallable(functions, "getTrelloAuthUrl");
      const { data } = await getTrelloAuthUrl();

      const { authUrl, oauthRequestToken } = data;

      const popupWidth = 600;
      const popupHeight = 600;
      const left = (window.screen.width - popupWidth) / 2;
      const top = (window.screen.height - popupHeight) / 2;
      const popup = window.open(
        authUrl,
        "TrelloAuth",
        `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
      );

      const messageListener = async (event) => {
        if (event.origin !== window.location.origin) return;

        if (event.data.type === "TRELLO_AUTH_SUCCESS") {
          window.removeEventListener("message", messageListener);
          popup.close();

          const { token } = event.data;
          const saveTrelloToken = httpsCallable(functions, "saveTrelloToken");
          await saveTrelloToken({ token, oauthRequestToken });

          setIsTrelloConnected(true);
          try {
            await syncTrelloBoards(userData.uid);
            const boards = await getDocs(
              query(collection(db, `users/${userData.uid}/trelloBoards`))
            );
            boards.forEach(async (board) => {
              const setupTrelloWebhook = httpsCallable(
                functions,
                "setupTrelloWebhook"
              );
              await setupTrelloWebhook({ boardId: board.id });
            });
          } catch (syncError) {
            console.error("Error syncing Trello boards:", syncError);
            setError(
              `Connected to Trello, but failed to sync boards: ${syncError.message}`
            );
          }
        } else if (event.data.type === "TRELLO_AUTH_ERROR") {
          window.removeEventListener("message", messageListener);
          popup.close();
          setError(`Trello authorization failed: ${event.data.error}`);
        }
        setIsLoading(false);
      };

      window.addEventListener("message", messageListener);
    } catch (error) {
      console.error("Error initializing Trello authorization:", error);
      setError(`Failed to initialize Trello authorization: ${error.message}`);
      setIsLoading(false);
    }
  };

  const handleDisconnectTrello = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await setDoc(
        doc(db, `users/${userData.uid}/integrations/trello`),
        { accessToken: null },
        { merge: true }
      );
      setIsTrelloConnected(false);
    } catch (error) {
      console.error("Error disconnecting Trello:", error);
      setError("Failed to disconnect Trello");
    } finally {
      setIsLoading(false);
    }
  };

  const handleMicrosoft365PersonalDisconnect = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await setDoc(
        doc(db, `users/${userData.uid}/integrations/microsoft365Personal`),
        { connected: false },
        { merge: true }
      );
      setIsMicrosoft365PersonalConnected(false);
    } catch (err) {
      console.error("Error disconnecting Microsoft 365 Personal:", err);
      setError(
        "Failed to disconnect Microsoft 365 Personal. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleMicrosoft365WorkDisconnect = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const disconnectMicrosoft365Work = httpsCallable(
        functions,
        "disconnectMicrosoft365Work"
      );
      await disconnectMicrosoft365Work();
      setIsMicrosoft365WorkConnected(false);
    } catch (err) {
      console.error("Error disconnecting Microsoft 365 Work:", err);
      setError("Failed to disconnect Microsoft 365 Work. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleConnectGitHub = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const getGitHubAuthUrl = httpsCallable(functions, "getGitHubAuthUrl");
      const result = await getGitHubAuthUrl();
      window.location.href = result.data.authUrl;
    } catch (error) {
      console.error("Error getting GitHub auth URL:", error);
      setError("Failed to initialize GitHub authorization");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisconnectGitHub = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await setDoc(
        doc(db, `users/${userData.uid}/integrations/github`),
        { accessToken: null },
        { merge: true }
      );
      setIsGitHubConnected(false);
    } catch (error) {
      console.error("Error disconnecting GitHub account:", error);
      setError("Failed to disconnect GitHub account");
    } finally {
      setIsLoading(false);
    }
  };

  const checkGitHubConnection = async () => {
    try {
      const isGitHubConnected = httpsCallable(functions, "isGitHubConnected");
      const result = await isGitHubConnected();
      setIsGitHubConnected(result.data.connected);
    } catch (error) {
      console.error("Error checking GitHub connection:", error);
    }
  };

  const mainContainerClass = isDarkMode
    ? "bg-gray-900 text-white"
    : "bg-gray-100 text-gray-800";

  const buttonClass = (color) => `
    mt-6 px-6 py-3 rounded-full font-medium transition-all duration-300 shadow-lg
    ${
      isDarkMode
        ? `bg-${color}-600 hover:bg-${color}-700 text-white`
        : `bg-${color}-500 hover:bg-${color}-600 text-white`
    }
    ${
      isLoading
        ? "opacity-50 cursor-not-allowed"
        : "hover:transform hover:scale-105"
    }
  `;

  const integrationCardClass = `p-8 rounded-xl shadow-xl transition-all duration-300 ${
    isDarkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
  }`;

  const integrations = [
    {
      name: "Google",
      description: "Sync files, calendars, and more with your Google account.",
      icon: FaGoogle,
      color: "bg-red-500",
      isConnected: isGoogleConnected,
      onConnect: handleConnectGoogle,
      onDisconnect: handleDisconnectGoogle,
    },
    {
      name: "Trello",
      description: "Sync tasks and projects with your Trello boards.",
      icon: FaTrello,
      color: "bg-blue-500",
      isConnected: isTrelloConnected,
      onConnect: handleConnectTrello,
      onDisconnect: handleDisconnectTrello,
    },
    {
      name: "Microsoft 365 Personal",
      description:
        "Sync tasks, emails, and more with your personal Microsoft 365 account.",
      icon: FaMicrosoft,
      color: "bg-blue-500",
      isConnected: isMicrosoft365PersonalConnected,
      onConnect: handleMicrosoft365PersonalConnect,
      onDisconnect: handleMicrosoft365PersonalDisconnect,
    },
    {
      name: "Microsoft 365 Work/School",
      description:
        "Sync tasks, emails, and more with your work or school Microsoft 365 account.",
      icon: FaMicrosoft,
      color: "bg-blue-500",
      isConnected: isMicrosoft365WorkConnected,
      onConnect: handleMicrosoft365WorkConnect,
      onDisconnect: handleMicrosoft365WorkDisconnect,
    },
    {
      name: "GitHub",
      description: "Connect your GitHub account to sync repositories and organizations.",
      icon: FaGithub,
      color: "bg-gray-800",
      isConnected: isGitHubConnected,
      onConnect: handleConnectGitHub,
      onDisconnect: handleDisconnectGitHub,
    },
  ];

  return (
    <div
      className={`flex h-screen ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      <div className="flex-1 overflow-auto">
        <div className="max-w-7xl mx-auto px-6 py-12">
          <header className="mb-12">
            <h1 className="text-4xl font-bold tracking-tight mb-3 font-sans">
              Integrations
            </h1>
            <p className="text-xl text-gray-500 dark:text-gray-400">
              Connect your favorite tools and boost your productivity.
            </p>
          </header>

          {error && (
            <div className="mb-8 p-4 bg-red-100 dark:bg-red-900 border-l-4 border-red-500 text-red-700 dark:text-red-300 rounded-md shadow-sm">
              <p className="font-medium">{error}</p>
            </div>
          )}

          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8">
            {integrations.map((integration) => (
              <IntegrationCard
                key={integration.name}
                integration={integration}
                isLoading={isLoading}
                isDarkMode={isDarkMode}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

function IntegrationCard({ integration, isLoading, isDarkMode }) {
  const {
    name,
    description,
    icon: Icon,
    color,
    isConnected,
    onConnect,
    onDisconnect,
  } = integration;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className={`rounded-lg shadow-lg overflow-hidden ${
        isDarkMode ? "bg-gray-800" : "bg-white"
      }`}
    >
      <div className={`h-2 ${color}`} />
      <div className="p-6">
        <div className="flex items-center mb-4">
          {Icon && (
            <Icon className={`text-3xl ${color.replace("bg-", "text-")}`} />
          )}
          <h3 className="ml-3 text-2xl font-semibold">{name}</h3>
        </div>
        <p className={`mb-6 ${isDarkMode ? "text-gray-300" : "text-gray-600"}`}>
          {description}
        </p>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <div
              className={`w-3 h-3 rounded-full mr-2 ${
                isConnected ? "bg-green-500" : "bg-gray-300"
              }`}
            />
            <span className={isConnected ? "text-green-500" : "text-gray-500"}>
              {isConnected ? "Connected" : "Not connected"}
            </span>
          </div>
          <button
            onClick={isConnected ? onDisconnect : onConnect}
            disabled={isLoading}
            className={`px-4 py-2 rounded-md font-medium transition-all duration-300 flex items-center ${
              isConnected
                ? "bg-red-100 text-red-600 hover:bg-red-200"
                : `${color} text-white hover:opacity-90`
            } ${isLoading ? "opacity-50 cursor-not-allowed" : ""}`}
          >
            {isLoading ? (
              <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                />
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            ) : (
              <HiLightningBolt className="mr-2" />
            )}
            {isConnected ? "Disconnect" : "Connect"}
          </button>
        </div>
      </div>
    </motion.div>
  );
}

export default AppIntegrations;
