import { db } from "../firebase/config";
import {
  doc,
  setDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
} from "firebase/firestore";

const MAX_RECENT_ITEMS = 10;

export async function addRecentItem(userId, itemType, item) {
  const recentItemRef = doc(
    db,
    `users/${userId}/recentItems/${itemType}_${item.id}`
  );
  await setDoc(
    recentItemRef,
    {
      ...item,
      type: itemType,
      accessedAt: new Date().toISOString(),
    },
    { merge: true }
  );

  // Trim the list to keep only the most recent items
  const recentItemsRef = collection(db, `users/${userId}/recentItems`);
  const recentItemsQuery = query(
    recentItemsRef,
    orderBy("accessedAt", "desc"),
    limit(MAX_RECENT_ITEMS + 1)
  );
  const recentItemsSnapshot = await getDocs(recentItemsQuery);

  if (recentItemsSnapshot.size > MAX_RECENT_ITEMS) {
    const oldestItem = recentItemsSnapshot.docs[MAX_RECENT_ITEMS];
    await oldestItem.ref.delete();
  }
}

export async function getRecentItems(userId, itemType = null) {
  const recentItemsRef = collection(db, `users/${userId}/recentItems`);
  let recentItemsQuery;

  if (itemType) {
    recentItemsQuery = query(
      recentItemsRef,
      orderBy("accessedAt", "desc"),
      limit(MAX_RECENT_ITEMS)
    );
  } else {
    recentItemsQuery = query(
      recentItemsRef,
      orderBy("accessedAt", "desc"),
      limit(MAX_RECENT_ITEMS)
    );
  }

  const recentItemsSnapshot = await getDocs(recentItemsQuery);
  return recentItemsSnapshot.docs.map((doc) => ({
    id: doc.id.split("_")[1],
    ...doc.data(),
  }));
}
