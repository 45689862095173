import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { UserContext } from "../context/UserContext";
import { db } from "../firebase/config";
import {
  collection,
  query,
  onSnapshot,
  doc,
  updateDoc,
  orderBy,
  getDocs,
  addDoc,
  deleteDoc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import {
  syncTrelloLists,
  updateTrelloListOrder,
  updateTrelloCardPosition,
} from "../utils/trelloSync";
import List from "./List";
import AddList from "./AddList";

function KanbanBoard({ board, isDarkMode }) {
  const { userData } = useContext(UserContext);
  const [lists, setLists] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let unsubscribe;
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        if (board.isTrello) {
          await syncTrelloLists(userData.uid, board.id);
        }

        const listsRef = collection(
          db,
          `users/${userData.uid}/${
            board.isTrello ? "trelloBoards" : "boxiaBoards"
          }/${board.id}/lists`
        );
        const q = query(listsRef, orderBy("pos"));

        unsubscribe = onSnapshot(
          q,
          async (snapshot) => {
            const fetchedLists = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
              cards: [],
            }));

            const listsWithCards = await Promise.all(
              fetchedLists.map(async (list) => {
                const cardsRef = collection(
                  db,
                  `users/${userData.uid}/${
                    board.isTrello ? "trelloBoards" : "boxiaBoards"
                  }/${board.id}/lists/${list.id}/cards`
                );
                const cardsSnapshot = await getDocs(
                  query(cardsRef, orderBy("position"))
                );
                const cards = cardsSnapshot.docs.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }));
                return { ...list, cards };
              })
            );

            setLists(listsWithCards);
            setLoading(false);
          },
          (err) => {
            setError("Failed to load lists. Please try again.");
            setLoading(false);
          }
        );
      } catch (err) {
        setError(
          "An error occurred while loading the board. Please try again."
        );
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [board, userData.uid]);

  const handleListAdded = async (newList) => {
    // Optimistically add the new list to the UI
    setLists((prevLists) => [...prevLists, { ...newList, cards: [] }]);

    // If it's a Trello board, fetch the updated lists to ensure consistency
    if (board.isTrello) {
      try {
        await syncTrelloLists(userData.uid, board.id);
        // Refetch the lists after syncing
        const updatedLists = await fetchLists();
        setLists(updatedLists);
      } catch (error) {
        // If there's an error, remove the optimistically added list
        setLists((prevLists) =>
          prevLists.filter((list) => list.id !== newList.id)
        );
      }
    }
  };

  // Helper function to fetch lists
  const fetchLists = async () => {
    const listsRef = collection(
      db,
      `users/${userData.uid}/${
        board.isTrello ? "trelloBoards" : "boxiaBoards"
      }/${board.id}/lists`
    );
    const q = query(listsRef, orderBy("pos"));
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      cards: [],
    }));
  };

  const handleCardAdded = (listId, newCard) => {
    setLists((prevLists) =>
      prevLists.map((list) => {
        if (list.id === listId) {
          return {
            ...list,
            cards: [...list.cards, newCard].sort(
              (a, b) => a.position - b.position
            ),
          };
        }
        return list;
      })
    );
  };

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    )
      return;

    try {
      if (type === "LIST") {
        const newListOrder = Array.from(lists);
        const [reorderedList] = newListOrder.splice(source.index, 1);
        newListOrder.splice(destination.index, 0, reorderedList);

        setLists(newListOrder);

        const boardRef = doc(
          db,
          `users/${userData.uid}/${
            board.isTrello ? "trelloBoards" : "boxiaBoards"
          }/${board.id}`
        );
        await updateDoc(boardRef, {
          listOrder: newListOrder.map((list) => list.id),
        });

        if (board.isTrello) {
          await updateTrelloListOrder(
            userData.uid,
            board.id,
            newListOrder.map((list) => list.id)
          );
        }
      } else if (type === "CARD") {
        const sourceList = lists.find((list) => list.id === source.droppableId);
        const destList = lists.find(
          (list) => list.id === destination.droppableId
        );
        const draggedCard = sourceList.cards.find(
          (card) => card.id === draggableId
        );

        const newSourceCards = Array.from(sourceList.cards);
        newSourceCards.splice(source.index, 1);
        const newDestCards = Array.from(destList.cards);
        newDestCards.splice(destination.index, 0, draggedCard);

        const newLists = lists.map((list) => {
          if (list.id === sourceList.id)
            return { ...list, cards: newSourceCards };
          if (list.id === destList.id) return { ...list, cards: newDestCards };
          return list;
        });

        setLists(newLists);

        // Update Firestore
        const sourceCardRef = doc(
          db,
          `users/${userData.uid}/${
            board.isTrello ? "trelloBoards" : "boxiaBoards"
          }/${board.id}/lists/${sourceList.id}/cards/${draggedCard.id}`
        );

        const destCardRef = doc(
          db,
          `users/${userData.uid}/${
            board.isTrello ? "trelloBoards" : "boxiaBoards"
          }/${board.id}/lists/${destList.id}/cards/${draggedCard.id}`
        );

        // Check if the card document exists before attempting to delete it
        const sourceCardDoc = await getDoc(sourceCardRef);
        if (sourceCardDoc.exists()) {
          // Delete the card from the source list
          await deleteDoc(sourceCardRef);
        }

        // Add the card to the destination list
        await setDoc(destCardRef, {
          ...draggedCard,
          listId: destList.id,
          position: destination.index,
        });

        if (board.isTrello) {
          const response = await updateTrelloCardPosition(
            userData.uid,
            board.id,
            draggedCard.id,
            destList.id,
            destination.index
          );
          if (!response.success) {
            throw new Error("Failed to update Trello card position");
          }
        }
      }
    } catch (err) {
      setError("Failed to update board. Please try again.");
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className={`flex flex-col h-full w-full ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}>
        <h2 className={`text-3xl font-bold p-6 ${
          isDarkMode ? "text-white" : "text-gray-800"
        }`}>
          {board.name}
        </h2>
        <div className="flex-grow overflow-x-auto overflow-y-hidden w-full">
          <Droppable droppableId={board.id} direction="horizontal" type="LIST">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="flex p-6 space-x-4 min-w-max h-full"
              >
                {lists.map((list, index) => (
                  <List
                    key={list.id}
                    list={list}
                    index={index}
                    boardId={board.id}
                    isTrelloBoard={board.isTrello}
                    isDarkMode={isDarkMode}
                    onCardAdded={handleCardAdded}
                  />
                ))}
                {provided.placeholder}
                <AddList
                  boardId={board.id}
                  isTrelloBoard={board.isTrello}
                  isDarkMode={isDarkMode}
                  onListAdded={handleListAdded}
                />
              </div>
            )}
          </Droppable>
        </div>
      </div>
    </DragDropContext>
  );
}

export default KanbanBoard;
