import React, { useState, useEffect, Fragment, useContext } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
import {
  FaBars,
  FaEllipsisH,
  FaPlus,
  FaTrash,
  FaEdit,
  FaCopy,
} from "react-icons/fa";
import { TrashIcon } from "@heroicons/react/outline";
import { DarkModeContext } from "../context/DarkModeContext";
import { UserContext } from "../context/UserContext";

const MicrosoftWorkTodoItem = ({ todo, toggleTodo, deleteTodo }) => {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <motion.div
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.2 }}
      className={`flex items-center p-4 rounded-lg ${
        isDarkMode ? "bg-gray-800" : "bg-white"
      } shadow-sm hover:shadow-md transition-all duration-200 mb-4`}
    >
      <input
        type="checkbox"
        checked={todo.status === "completed"}
        onChange={() => toggleTodo(todo.id, todo.status)}
        className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500 mr-3"
      />
      <span
        className={`flex-grow ${
          todo.status === "completed" ? "line-through text-gray-500" : ""
        }`}
      >
        {todo.title}
      </span>
      <button
        onClick={() => deleteTodo(todo.id)}
        className="text-gray-400 hover:text-gray-600 focus:outline-none"
      >
        <TrashIcon className="w-5 h-5" />
      </button>
    </motion.div>
  );
};

const MicrosoftWorkListsContent = ({ todos, toggleTodo, deleteTodo }) => {
  const [workLists, setWorkLists] = useState([]);
  const [activeWorkList, setActiveWorkList] = useState(null);
  const [workTodos, setWorkTodos] = useState([]);
  const [newWorkListName, setNewWorkListName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [isNewWorkTaskModalOpen, setIsNewWorkTaskModalOpen] = useState(false);
  const [newWorkTaskTitle, setNewWorkTaskTitle] = useState("");
  const [selectedWorkListForNewTask, setSelectedWorkListForNewTask] =
    useState(null);
  const { isDarkMode } = useContext(DarkModeContext);

  useEffect(() => {
    fetchMicrosoftWorkLists();
  }, []);

  const fetchMicrosoftWorkLists = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const fetchMsftWorkLists = httpsCallable(
        functions,
        "fetchMicrosoftWorkLists"
      );
      const result = await fetchMsftWorkLists();
      setWorkLists(result.data.lists);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching Microsoft work lists:", error);
      if (error.message.includes("token is expired")) {
        try {
          const refreshMicrosoft365Token = httpsCallable(
            functions,
            "refreshMicrosoft365Token"
          );
          await refreshMicrosoft365Token({ accountType: "work" });
          // Retry fetching lists after refreshing the token
          const fetchMsftWorkLists = httpsCallable(
            functions,
            "fetchMicrosoftWorkLists"
          );
          const result = await fetchMsftWorkLists();
          setWorkLists(result.data.lists);
          setIsLoading(false);
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          setIsLoading(false);
          setError(
            "Failed to refresh Microsoft 365 token. Please reconnect your account."
          );
        }
      } else {
        setIsLoading(false);
        setError(
          "Failed to load work lists. Please ensure your Microsoft 365 account is connected and try again."
        );
      }
    }
  };

  const addWorkList = async (e) => {
    e.preventDefault();
    if (!newWorkListName.trim()) return;

    try {
      const createMsftWorkList = httpsCallable(
        functions,
        "createMicrosoftWorkList"
      );
      const result = await createMsftWorkList({ name: newWorkListName });
      setWorkLists([...workLists, result.data]);
      setNewWorkListName("");
    } catch (err) {
      console.error("Error adding Microsoft work list:", err);
      setError("Failed to add work list. Please try again.");
    }
  };

  const deleteWorkList = async (listId) => {
    try {
      const deleteMsftWorkList = httpsCallable(
        functions,
        "deleteMicrosoftWorkList"
      );
      await deleteMsftWorkList({ listId });
      setWorkLists(workLists.filter((list) => list.id !== listId));
    } catch (err) {
      console.error("Error deleting Microsoft work list:", err);
      setError("Failed to delete work list. Please try again.");
    }
  };

  const renameWorkList = async (listId, newName) => {
    try {
      const renameMsftWorkList = httpsCallable(
        functions,
        "updateMicrosoftWorkList"
      );
      const result = await renameMsftWorkList({
        listId,
        updates: { displayName: newName },
      });
      setWorkLists(
        workLists.map((list) =>
          list.id === listId ? { ...list, name: result.data.displayName } : list
        )
      );
    } catch (err) {
      console.error("Error renaming Microsoft work list:", err);
      setError("Failed to rename work list. Please try again.");
    }
  };

  const duplicateWorkList = async (listId) => {
    try {
      const listToDuplicate = workLists.find((list) => list.id === listId);
      if (listToDuplicate) {
        const createMsftWorkList = httpsCallable(
          functions,
          "createMicrosoftWorkList"
        );
        const result = await createMsftWorkList({
          name: `${listToDuplicate.name} (Copy)`,
        });
        setWorkLists([...workLists, result.data]);
      }
    } catch (err) {
      console.error("Error duplicating Microsoft work list:", err);
      setError("Failed to duplicate work list. Please try again.");
    }
  };

  const handleAddNewWorkTask = async () => {
    if (!newWorkTaskTitle.trim() || !selectedWorkListForNewTask) return;

    try {
      const createMsftWorkTask = httpsCallable(
        functions,
        "createMicrosoftWorkTask"
      );
      const result = await createMsftWorkTask({
        listId: selectedWorkListForNewTask,
        title: newWorkTaskTitle,
      });
      setWorkTodos([...workTodos, result.data]);
      closeNewWorkTaskModal();
    } catch (err) {
      console.error("Error adding task to Microsoft work list:", err);
      setError("Failed to add work task. Please try again.");
    }
  };

  const toggleWorkTask = async (listId, taskId, currentStatus) => {
    try {
      const updateMsftWorkTask = httpsCallable(
        functions,
        "updateMicrosoftWorkTask"
      );
      const newStatus =
        currentStatus === "completed" ? "notStarted" : "completed";
      await updateMsftWorkTask({
        listId,
        taskId,
        updates: { status: newStatus },
      });
      setWorkTodos(
        workTodos.map((todo) =>
          todo.id === taskId ? { ...todo, status: newStatus } : todo
        )
      );
    } catch (err) {
      console.error("Error toggling Microsoft work task:", err);
      setError("Failed to update work task. Please try again.");
    }
  };

  const deleteWorkTask = async (listId, taskId) => {
    try {
      const deleteMsftWorkTask = httpsCallable(
        functions,
        "deleteMicrosoftWorkTask"
      );
      await deleteMsftWorkTask({ listId, taskId });
      setWorkTodos(workTodos.filter((todo) => todo.id !== taskId));
    } catch (err) {
      console.error("Error deleting Microsoft work task:", err);
      setError("Failed to delete work task. Please try again.");
    }
  };

  const toggleDropdown = (id) => {
    setOpenDropdownId(openDropdownId === id ? null : id);
  };

  const handleDrop = (fromIndex, toIndex) => {
    const newWorkLists = [...workLists];
    const [movedItem] = newWorkLists.splice(fromIndex, 1);
    newWorkLists.splice(toIndex, 0, movedItem);
    setWorkLists(newWorkLists);
    // Implement the logic to update the order in Microsoft here
  };

  const openNewWorkTaskModal = (listId = null) => {
    setSelectedWorkListForNewTask(listId);
    setIsNewWorkTaskModalOpen(true);
  };

  const closeNewWorkTaskModal = () => {
    setIsNewWorkTaskModalOpen(false);
    setNewWorkTaskTitle("");
    setSelectedWorkListForNewTask(null);
  };

  const MicrosoftWorkListsContent = ({ todos, toggleTodo, deleteTodo }) => {
    return (
      <div>
        {todos.map((todo) => (
          <MicrosoftWorkTodoItem
            key={todo.id}
            todo={todo}
            toggleTodo={toggleTodo}
            deleteTodo={deleteTodo}
          />
        ))}
      </div>
    );
  };

  return (
    <div className="flex-1 flex">
      {/* Work Lists Sidebar */}
      <div className="w-72 h-full overflow-y-auto">
        <div className="p-6">
          <h2 className="text-2xl font-semibold mb-6">My Work Lists</h2>
        </div>
        <div className="px-4 -mt-5">
          {workLists.map((list, index) => (
            <div
              key={list.id}
              draggable
              onDragStart={(e) => e.dataTransfer.setData("text/plain", index)}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => {
                e.preventDefault();
                const fromIndex = Number(e.dataTransfer.getData("text/plain"));
                const toIndex = index;
                handleDrop(fromIndex, toIndex);
              }}
              className={`flex items-center justify-between p-3 rounded-lg mb-2 cursor-pointer transition-all duration-200 ${
                activeWorkList && activeWorkList.id === list.id
                  ? isDarkMode
                    ? "bg-blue-600 text-white"
                    : "bg-blue-100 text-blue-800"
                  : isDarkMode
                  ? "text-gray-300 hover:bg-gray-800"
                  : "text-gray-800 hover:bg-gray-200"
              }`}
            >
              <div
                className="flex items-center flex-grow"
                onClick={() => setActiveWorkList(list)}
              >
                <FaBars className="mr-3 cursor-move text-gray-500" />
                <span className="font-medium">{list.displayName}</span>
              </div>
              <div className="relative dropdown">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleDropdown(list.id);
                  }}
                  className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-150"
                >
                  <FaEllipsisH />
                </button>
                <AnimatePresence>
                  {openDropdownId === list.id && (
                    <motion.div
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      transition={{ duration: 0.1 }}
                      className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg overflow-hidden z-10 ${
                        isDarkMode ? "bg-gray-800" : "bg-white"
                      } ring-1 ring-black ring-opacity-5`}
                    >
                      <div
                        className="py-1"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                      >
                        {[
                          {
                            label: "Delete",
                            action: deleteWorkList,
                            icon: FaTrash,
                          },
                          {
                            label: "Rename",
                            action: renameWorkList,
                            icon: FaEdit,
                          },
                          {
                            label: "Duplicate",
                            action: duplicateWorkList,
                            icon: FaCopy,
                          },
                        ].map((item) => (
                          <motion.button
                            key={item.label}
                            whileHover={{
                              backgroundColor: isDarkMode
                                ? "#374151"
                                : "#F3F4F6",
                            }}
                            transition={{ duration: 0.2 }}
                            onClick={(e) => {
                              e.stopPropagation();
                              item.action(list.id);
                            }}
                            className={`flex items-center w-full px-4 py-2 text-sm ${
                              isDarkMode
                                ? "text-gray-300 hover:text-white"
                                : "text-gray-700 hover:text-gray-900"
                            } text-left transition-colors duration-200`}
                            role="menuitem"
                          >
                            <item.icon className="mr-3 h-4 w-4" />
                            {item.label}
                          </motion.button>
                        ))}
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          ))}
          <form onSubmit={addWorkList} className="mt-4">
            <div className="relative">
              <input
                type="text"
                value={newWorkListName}
                onChange={(e) => setNewWorkListName(e.target.value)}
                placeholder="Create new work list"
                className={`w-full p-3 pr-12 rounded-lg ${
                  isDarkMode
                    ? "bg-gray-800 text-white"
                    : "bg-white text-gray-800"
                } border ${
                  isDarkMode ? "border-gray-700" : "border-gray-300"
                } focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200`}
              />
              <button
                type="submit"
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
              >
                <FaPlus className="h-5 w-5" />
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Work Todos for Active List */}
      <div className="flex-1 p-8">
        <div className="flex justify-between items-center mb-6">
          <h1
            className={`text-3xl font-bold ${
              isDarkMode ? "text-white" : "text-gray-800"
            }`}
          >
            {activeWorkList ? (
              <>
                <span className="mr-2">{activeWorkList.name}</span>
                <span className="text-sm font-normal text-gray-500 bg-gray-200 rounded-full px-3 py-1 ml-2">
                  {workTodos.length} {workTodos.length === 1 ? "task" : "tasks"}
                </span>
              </>
            ) : (
              <span className="text-gray-500">Select a Work List</span>
            )}
          </h1>
          {activeWorkList && (
            <button
              onClick={() =>
                activeWorkList.id === "all"
                  ? setOpenDropdownId("addToWorkList")
                  : openNewWorkTaskModal(activeWorkList.id)
              }
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center"
            >
              <FaPlus className="mr-2" />
              {activeWorkList.id === "all"
                ? "Add to Work List"
                : "Add Work Task"}
            </button>
          )}
        </div>

        {isLoading ? (
          <div className="text-center text-gray-500 mt-8">Loading...</div>
        ) : error ? (
          <div className="text-center text-red-500 mt-8">{error}</div>
        ) : (
          <div>
            {workTodos.map((todo) => (
              <MicrosoftWorkTodoItem
                key={todo.id}
                todo={todo}
                toggleTodo={() =>
                  toggleWorkTask(activeWorkList.id, todo.id, todo.status)
                }
                deleteTodo={() => deleteWorkTask(activeWorkList.id, todo.id)}
              />
            ))}
          </div>
        )}
      </div>

      {/* New Work Task Modal */}
      <Transition appear show={isNewWorkTaskModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeNewWorkTaskModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>

            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Add New Work Task
                </Dialog.Title>
                <div className="mt-2">
                  <input
                    type="text"
                    value={newWorkTaskTitle}
                    onChange={(e) => setNewWorkTaskTitle(e.target.value)}
                    placeholder="Enter task title"
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>

                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                    onClick={handleAddNewWorkTask}
                  >
                    Add Task
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default MicrosoftWorkListsContent;
