import React, { useState, useContext, useEffect } from "react";
import Sidebar from "../components/Sidebar";
import { DarkModeContext } from "../context/DarkModeContext";
import { UserContext } from "../context/UserContext";
import { auth, db, functions } from "../firebase/config";
import {
  updateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { loadStripe } from "@stripe/stripe-js";
import { Tab } from "@headlessui/react";
import {
  FiUser,
  FiLock,
  FiBell,
  FiCreditCard,
  FiEye,
  FiChevronRight,
} from "react-icons/fi";
import { motion, AnimatePresence } from "framer-motion";

const settingsCategories = [
  { id: "account", label: "Account", icon: FiUser },
  { id: "notifications", label: "Notifications", icon: FiBell },
  { id: "subscription", label: "Subscription", icon: FiCreditCard },
  { id: "appearance", label: "Appearance", icon: FiEye },
];

function AppSettings() {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData, isLoading } = useContext(UserContext);
  const [isGoogleConnected, setIsGoogleConnected] = useState(false);

  useEffect(() => {
    checkGoogleConnection();
  }, []);

  const checkGoogleConnection = async () => {
    try {
      const checkConnection = httpsCallable(functions, "isGoogleConnected");
      const result = await checkConnection();
      setIsGoogleConnected(result.data.connected);
    } catch (error) {
      console.error("Error checking Google connection:", error);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full"
        />
      </div>
    );
  }

  if (!userData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="text-xl font-semibold"
        >
          Please log in to access settings.
        </motion.div>
      </div>
    );
  }

  return (
    <div
      className={`min-h-screen flex ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100"
      }`}
    >
      <div
        className={`flex-1 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 ${
          isDarkMode ? "text-white" : "text-gray-900"
        }`}
      >
        <h1 className="text-3xl font-bold mb-8">Settings</h1>
        <Tab.Group>
          <Tab.List
            className={`flex space-x-1 rounded-xl p-1 ${
              isDarkMode ? "bg-blue-900/20" : "bg-blue-200"
            }`}
          >
            {settingsCategories.map((category) => (
              <Tab
                key={category.id}
                className={({ selected }) =>
                  `w-full rounded-lg py-2.5 text-sm font-medium leading-5 
                  ${
                    selected
                      ? isDarkMode
                        ? "bg-blue-800 text-white shadow"
                        : "bg-white shadow text-blue-800"
                      : isDarkMode
                      ? "text-blue-200 hover:bg-blue-900 hover:text-white"
                      : "text-blue-800 hover:bg-blue-100 hover:text-blue-900"
                  }`
                }
              >
                <div className="flex items-center justify-center space-x-2">
                  <category.icon className="w-5 h-5" />
                  <span>{category.label}</span>
                </div>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            <Tab.Panel>
              <AccountSettings />
            </Tab.Panel>
            <Tab.Panel>
              <NotificationSettings />
            </Tab.Panel>
            <Tab.Panel>
              <SubscriptionSettings />
            </Tab.Panel>
            <Tab.Panel>
              <AppearanceSettings />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}

function SettingsCategoryItem({ category, isLast }) {
  const Icon = category.icon;

  return (
    <motion.div
      whileHover={{ backgroundColor: "#f9fafb" }}
      className={`px-6 py-5 ${!isLast && "border-b border-gray-200"}`}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">
            <Icon className="h-6 w-6 text-gray-400" />
          </div>
          <div className="flex-1 min-w-0">
            <h3 className="text-lg font-medium text-gray-900">
              {category.label}
            </h3>
            <p className="text-sm text-gray-500">
              Manage your {category.label.toLowerCase()} settings
            </p>
          </div>
        </div>
        <div className="flex-shrink-0">
          <FiChevronRight className="h-5 w-5 text-gray-400" />
        </div>
      </div>
    </motion.div>
  );
}

function AccountSettings() {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (userData) {
      setEmail(userData.email || "");
    }
  }, [userData]);

  const handleUpdateEmail = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);
    try {
      const user = auth.currentUser;
      await updateEmail(user, email);
      await updateDoc(doc(db, "users", user.uid), { email });
      setSuccess("Email updated successfully");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);
    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match");
      setLoading(false);
      return;
    }
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setSuccess("Password updated successfully");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const inputClass = `w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
    isDarkMode
      ? "bg-gray-800 border-gray-700 text-white"
      : "bg-white border-gray-300 text-gray-900"
  }`;

  const buttonClass =
    "mt-4 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div
        className={`p-6 rounded-xl shadow-lg ${
          isDarkMode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <h2 className="text-2xl font-semibold mb-6">Account Information</h2>
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="mb-4 p-3 rounded-lg bg-red-100 border border-red-400 text-red-700"
            >
              {error}
            </motion.div>
          )}
          {success && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="mb-4 p-3 rounded-lg bg-green-100 border border-green-400 text-green-700"
            >
              {success}
            </motion.div>
          )}
        </AnimatePresence>
        <div className="flex flex-col md:flex-row md:space-x-8">
          <div className="flex-1">
            <h3 className="text-xl font-semibold mb-4">Email</h3>
            <form onSubmit={handleUpdateEmail} className="mb-8">
              <div className="mb-4">
                <label htmlFor="email" className="block mb-2 font-medium">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={inputClass}
                  placeholder="your@email.com"
                />
              </div>
              <motion.button
                type="submit"
                className={`${buttonClass} ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {loading ? "Updating..." : "Update Email"}
              </motion.button>
            </form>
          </div>
          <div className="flex-1 mt-8 md:mt-0">
            <h3 className="text-xl font-semibold mb-4">Password</h3>
            <form onSubmit={handleUpdatePassword}>
              <div className="mb-4">
                <label
                  htmlFor="currentPassword"
                  className="block mb-2 font-medium"
                >
                  Current Password
                </label>
                <input
                  type="password"
                  id="currentPassword"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className={inputClass}
                  placeholder="Enter current password"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="newPassword" className="block mb-2 font-medium">
                  New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className={inputClass}
                  placeholder="Enter new password"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirmNewPassword"
                  className="block mb-2 font-medium"
                >
                  Confirm New Password
                </label>
                <input
                  type="password"
                  id="confirmNewPassword"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  className={inputClass}
                  placeholder="Confirm new password"
                />
              </div>
              <motion.button
                type="submit"
                className={`${buttonClass} ${
                  loading ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={loading}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {loading ? "Updating..." : "Update Password"}
              </motion.button>
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

function SecuritySettings() {
  const { isDarkMode } = useContext(DarkModeContext);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setLoading(true);
    if (newPassword !== confirmNewPassword) {
      setError("New passwords do not match");
      setLoading(false);
      return;
    }
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setSuccess("Password updated successfully");
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const inputClass = `w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 ${
    isDarkMode
      ? "bg-gray-800 border-gray-700 text-white"
      : "bg-white border-gray-300 text-gray-900"
  }`;

  const buttonClass =
    "mt-4 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2";

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div
        className={`p-6 rounded-xl shadow-lg ${
          isDarkMode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <h2 className="text-2xl font-semibold mb-6">Change Password</h2>
        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="mb-4 p-3 rounded-lg bg-red-100 border border-red-400 text-red-700"
            >
              {error}
            </motion.div>
          )}
          {success && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className="mb-4 p-3 rounded-lg bg-green-100 border border-green-400 text-green-700"
            >
              {success}
            </motion.div>
          )}
        </AnimatePresence>
        <form onSubmit={handleUpdatePassword}>
          <div className="mb-4">
            <label htmlFor="currentPassword" className="block mb-2 font-medium">
              Current Password
            </label>
            <input
              type="password"
              id="currentPassword"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className={inputClass}
              placeholder="Enter current password"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="newPassword" className="block mb-2 font-medium">
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className={inputClass}
              placeholder="Enter new password"
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirmNewPassword"
              className="block mb-2 font-medium"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmNewPassword"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              className={inputClass}
              placeholder="Confirm new password"
            />
          </div>
          <motion.button
            type="submit"
            className={`${buttonClass} ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            {loading ? "Updating..." : "Update Password"}
          </motion.button>
        </form>
      </div>
    </motion.div>
  );
}

function NotificationSettings() {
  const { isDarkMode } = useContext(DarkModeContext);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className={`p-6 rounded-xl shadow-lg ${
        isDarkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
      }`}
    >
      <h2 className="text-2xl font-semibold mb-6">Notification Settings</h2>
      <p className={isDarkMode ? "text-gray-300" : "text-gray-600"}>
        Notification settings coming soon...
      </p>
    </motion.div>
  );
}

function SubscriptionSettings() {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUpgrade = async (planId, billingInterval) => {
    setLoading(true);
    setError(null);
    try {
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(
        functions,
        "createCheckoutSessionCallable"
      );
      const result = await createCheckoutSession({
        planId,
        billingInterval,
        userId: userData.uid,
        userEmail: userData.email,
      });

      const { sessionId } = result.data;
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId });
    } catch (err) {
      console.error("Error upgrading subscription:", err);
      setError("Failed to upgrade subscription. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleManageSubscription = async () => {
    setLoading(true);
    setError(null);
    try {
      const functions = getFunctions();
      const createPortalSession = httpsCallable(
        functions,
        "createPortalSession"
      );
      const result = await createPortalSession();

      window.location.href = result.data.url;
    } catch (err) {
      console.error("Error accessing subscription management:", err);
      setError("Failed to access subscription management. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const buttonClass = `mt-4 px-6 py-3 rounded-md transition-colors duration-200 ${
    isDarkMode
      ? "bg-blue-600 hover:bg-blue-700 text-white"
      : "bg-blue-500 hover:bg-blue-600 text-white"
  }`;

  return (
    <div className="space-y-8">
      <div
        className={`p-6 rounded-xl shadow-lg ${
          isDarkMode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <h2 className="text-2xl font-semibold mb-6">Subscription Settings</h2>
        {error && (
          <div className="mb-4 p-3 rounded-lg bg-red-100 border border-red-400 text-red-700">
            {error}
          </div>
        )}
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Current Plan</h3>
          <p className="text-gray-600 dark:text-gray-300">
            {userData.planId
              ? userData.planId.charAt(0).toUpperCase() +
                userData.planId.slice(1)
              : "Free"}
          </p>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold mb-2">Status</h3>
          <p className="text-gray-600 dark:text-gray-300">
            {userData.subscriptionStatus || "N/A"}
          </p>
        </div>
        {userData.planId === "free" && (
          <div className="flex flex-col space-y-4">
            <button
              onClick={() => handleUpgrade("pro", "monthly")}
              className={buttonClass}
              disabled={loading}
            >
              {loading ? "Processing..." : "Upgrade to Pro (Monthly)"}
            </button>
            <button
              onClick={() => handleUpgrade("pro", "yearly")}
              className={`${buttonClass} bg-green-500 hover:bg-green-600`}
              disabled={loading}
            >
              {loading ? "Processing..." : "Upgrade to Pro (Yearly)"}
            </button>
          </div>
        )}
        {userData.planId !== "free" && (
          <button
            onClick={handleManageSubscription}
            className={buttonClass}
            disabled={loading}
          >
            {loading ? "Processing..." : "Manage Subscription"}
          </button>
        )}
      </div>
    </div>
  );
}

function AppearanceSettings() {
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);

  const themes = [
    { name: "Light", color: "bg-white border-gray-300" },
    { name: "Boxia Dark", color: "bg-gray-900 border-gray-700" },
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div
        className={`p-6 rounded-xl shadow-lg ${
          isDarkMode ? "bg-gray-800" : "bg-white"
        }`}
      >
        <h2 className="text-2xl font-semibold mb-6">Appearance Settings</h2>
        <div className="space-y-4">
          <h3 className="text-lg font-medium">Theme</h3>
          <div className="flex space-x-4">
            {themes.map((theme) => (
              <button
                key={theme.name}
                onClick={() => toggleDarkMode()}
                className={`flex flex-col items-center space-y-2 focus:outline-none group`}
              >
                <div
                  className={`w-12 h-12 rounded-full border-2 ${theme.color} ${
                    theme.border
                  } ${
                    (isDarkMode && theme.name === "Boxia Dark") ||
                    (!isDarkMode && theme.name === "Light")
                      ? "ring-2 ring-blue-500"
                      : ""
                  }`}
                />
                <span
                  className={`text-sm ${
                    (isDarkMode && theme.name === "Boxia Dark") ||
                    (!isDarkMode && theme.name === "Light")
                      ? "text-blue-500"
                      : ""
                  }`}
                >
                  {theme.name}
                </span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default AppSettings;
