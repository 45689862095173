import { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { DarkModeContext } from "../context/DarkModeContext";
import { UserContext } from "../context/UserContext";
import Sidebar from "../components/Sidebar";
import {
  FaSun,
  FaStar,
  FaCalendarAlt,
  FaInbox,
  FaEllipsisH,
  FaFlag,
  FaTasks,
  FaList,
  FaPlus,
  FaClock,
  FaTrash,
  FaBars,
  FaEdit,
  FaSave,
  FaCopy,
  FaMicrosoft,
} from "react-icons/fa";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
  writeBatch,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
import { db } from "../firebase/config";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon as XMarkIcon } from "@heroicons/react/outline";
import {
  ClipboardIcon,
  PlusIcon,
  PencilIcon,
  TrashIcon,
  CalendarIcon,
  ClockIcon,
} from "@heroicons/react/outline";
import { useNavigate, useLocation } from "react-router-dom";
import { getMicrosoft365WorkAuthUrl } from "../components/Microsoft365OAuthCallback";
import MicrosoftWorkListsContent from "../components/MicrosoftWorkListsContent";
import MyDayContent from "../components/MyDay";

function AppTodo() {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState("myDay");
  const [cachedData, setCachedData] = useState({});
  const [msftPersonalTodos, setMsftPersonalTodos] = useState([]);
  const [msftWorkTodos, setMsftWorkTodos] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Function to get cached data or fetch new data
  const getDataWithCache = useCallback(
    async (key, fetchFunction) => {
      // Check if data is in state cache
      if (cachedData[key]) {
        return cachedData[key];
      }

      // Check if data is in localStorage
      const storedData = localStorage.getItem(key);
      if (storedData) {
        const parsedData = JSON.parse(storedData);
        setCachedData((prev) => ({ ...prev, [key]: parsedData }));
        return parsedData;
      }

      // If not in cache, fetch data
      const fetchedData = await fetchFunction();

      // Store in state cache and localStorage
      setCachedData((prev) => ({ ...prev, [key]: fetchedData }));
      localStorage.setItem(key, JSON.stringify(fetchedData));

      return fetchedData;
    },
    [cachedData]
  );

  // Function to invalidate cache
  const invalidateCache = useCallback((key) => {
    setCachedData((prev) => {
      const newCache = { ...prev };
      delete newCache[key];
      return newCache;
    });
    localStorage.removeItem(key);
  }, []);

  const todoTabs = [
    { id: "myDay", name: "My Day", icon: FaSun },
    { id: "important", name: "Important", icon: FaStar },
    { id: "planned", name: "Planned", icon: FaCalendarAlt },
    { id: "lists", name: "Lists", icon: FaList },
  ];

  const msftTabs = [
    { id: "msftPersonal", name: "Microsoft Personal", icon: FaMicrosoft },
    { id: "msftWork", name: "Microsoft Work", icon: FaMicrosoft },
  ];

  useEffect(() => {
    const path = location.pathname;
    if (path === "/app/todo/msft-personal") {
      setActiveTab("msftPersonal");
    } else if (path === "/app/todo/msft-work") {
      setActiveTab("msftWork");
    } else {
      setActiveTab("myDay");
    }
  }, [location]);

  const handleTabClick = (tabId) => {
    if (tabId === "msftPersonal") {
      navigate("/app/todo/msft-personal");
    } else if (tabId === "msftWork") {
      navigate("/app/todo/msft-work");
    } else {
      setActiveTab(tabId);
    }
  };

  useEffect(() => {
    if (userData && userData.uid) {
      fetchMicrosoftTodos();
    }
  }, [userData]);

  const fetchMicrosoftTodos = async () => {
    try {
      const getMsftPersonalTodos = httpsCallable(
        functions,
        "getMsftPersonalTodos"
      );
      const getMsftWorkTodos = httpsCallable(functions, "getMsftWorkTodos");

      const personalResult = await getMsftPersonalTodos();
      const workResult = await getMsftWorkTodos();

      setMsftPersonalTodos(personalResult.data);
      setMsftWorkTodos(workResult.data);
    } catch (error) {
      console.error("Error fetching Microsoft todos:", error);
      setError("Failed to fetch Microsoft todos. Please try again.");
    }
  };

  const MicrosoftPersonalContent = () => {
    return (
      <div className="flex-1 p-6 max-w-5xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Microsoft Personal Tasks</h1>
        </div>
        {/* Render msftPersonalTodos similar to other todo lists */}
        {msftPersonalTodos.map((todo) => (
          <TodoItem key={todo.id} todo={todo} />
        ))}
      </div>
    );
  };

  const MicrosoftWorkContent = () => {
    return (
      <div className="flex-1 p-6 max-w-5xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Microsoft Work Tasks</h1>
        </div>
        {/* Render msftWorkTodos similar to other todo lists */}
        {msftWorkTodos.map((todo) => (
          <TodoItem key={todo.id} todo={todo} />
        ))}
      </div>
    );
  };

  const TodoItem = ({ todo, toggleTodo, deleteTodo }) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
        transition={{ duration: 0.2 }}
        className={`flex items-center p-4 rounded-lg ${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } shadow-sm hover:shadow-md transition-all duration-200 mb-4`}
      >
        <input
          type="checkbox"
          checked={todo.status === "completed"}
          onChange={() => toggleTodo(todo.id, todo.status)}
          className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500 mr-3"
        />
        <span
          className={`flex-grow ${
            todo.status === "completed" ? "line-through text-gray-500" : ""
          }`}
        >
          {todo.title}
        </span>
        <button
          onClick={() => deleteTodo(todo.id)}
          className="text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <TrashIcon className="w-5 h-5" />
        </button>
      </motion.div>
    );
  };

  const toggleMicrosoftTodo = async (id, status) => {
    try {
      const toggleMsftTodo = httpsCallable(functions, "toggleMsftTodo");
      await toggleMsftTodo({
        id,
        status: status === "completed" ? "notStarted" : "completed",
      });
      // Update local state
      setMsftPersonalTodos((prev) =>
        prev.map((todo) =>
          todo.id === id
            ? {
                ...todo,
                status: status === "completed" ? "notStarted" : "completed",
              }
            : todo
        )
      );
      setMsftWorkTodos((prev) =>
        prev.map((todo) =>
          todo.id === id
            ? {
                ...todo,
                status: status === "completed" ? "notStarted" : "completed",
              }
            : todo
        )
      );
    } catch (error) {
      console.error("Error toggling Microsoft todo:", error);
      setError("Failed to update todo. Please try again.");
    }
  };

  const deleteMicrosoftTodo = async (id) => {
    try {
      const deleteMsftTodo = httpsCallable(functions, "deleteMsftTodo");
      await deleteMsftTodo({ id });
      // Update local state
      setMsftPersonalTodos((prev) => prev.filter((todo) => todo.id !== id));
      setMsftWorkTodos((prev) => prev.filter((todo) => todo.id !== id));
    } catch (error) {
      console.error("Error deleting Microsoft todo:", error);
      setError("Failed to delete todo. Please try again.");
    }
  };



  const ImportantContent = () => {
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filter, setFilter] = useState("all"); // "all", "todo", "completed"

    useEffect(() => {
      if (userData && userData.uid) {
        fetchImportantTodos();
      }
    }, [userData]);

    const fetchImportantTodos = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const todosRef = collection(db, `users/${userData.uid}/todos`);
        const q = query(
          todosRef,
          where("important", "==", true),
          orderBy("createdAt", "desc")
        );
        const querySnapshot = await getDocs(q);
        const fetchedTodos = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort todos by priority
        const sortedTodos = fetchedTodos.sort((a, b) => {
          const priorityOrder = { high: 0, medium: 1, low: 2 };
          return priorityOrder[a.priority] - priorityOrder[b.priority];
        });

        setTodos(sortedTodos);
      } catch (err) {
        console.error("Error fetching important todos:", err);
        setError("Failed to load important todos. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const addTodo = async (e) => {
      e.preventDefault();
      if (!newTodo.trim()) return;

      try {
        const todosRef = collection(db, `users/${userData.uid}/todos`);
        const newTodoDoc = await addDoc(todosRef, {
          title: newTodo,
          completed: false,
          important: true,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          dueDate: null,
          priority: "high",
        });
        const newTodoData = {
          id: newTodoDoc.id,
          title: newTodo,
          completed: false,
          important: true,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          dueDate: null,
          priority: "high",
        };
        setTodos((prevTodos) => {
          const updatedTodos = [newTodoData, ...prevTodos];
          return updatedTodos.sort((a, b) => {
            const priorityOrder = { high: 0, medium: 1, low: 2 };
            return priorityOrder[a.priority] - priorityOrder[b.priority];
          });
        });
        setNewTodo("");
      } catch (err) {
        console.error("Error adding important todo:", err);
        setError("Failed to add todo. Please try again.");
      }
    };

    const toggleTodo = async (id, completed) => {
      try {
        const todoRef = doc(db, `users/${userData.uid}/todos`, id);
        await updateDoc(todoRef, {
          completed: !completed,
          updatedAt: Timestamp.now(),
        });
        setTodos((prevTodos) =>
          prevTodos.map((todo) =>
            todo.id === id ? { ...todo, completed: !completed } : todo
          )
        );
      } catch (err) {
        console.error("Error updating todo:", err);
        setError("Failed to update todo. Please try again.");
      }
    };

    const deleteTodo = async (id) => {
      try {
        await deleteDoc(doc(db, `users/${userData.uid}/todos`, id));
        setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
      } catch (err) {
        console.error("Error deleting todo:", err);
        setError("Failed to delete todo. Please try again.");
      }
    };

    const updatePriority = async (id, newPriority) => {
      try {
        const todoRef = doc(db, `users/${userData.uid}/todos`, id);
        await updateDoc(todoRef, {
          priority: newPriority,
          updatedAt: Timestamp.now(),
        });
        setTodos((prevTodos) =>
          prevTodos.map((todo) =>
            todo.id === id ? { ...todo, priority: newPriority } : todo
          )
        );
      } catch (err) {
        console.error("Error updating todo priority:", err);
        setError("Failed to update todo priority. Please try again.");
      }
    };

    const filteredTodos = todos.filter((todo) => {
      if (filter === "todo") return !todo.completed;
      if (filter === "completed") return todo.completed;
      return true;
    });

    const priorityColors = {
      high: "text-red-500",
      medium: "text-yellow-500",
      low: "text-green-500",
    };

    return (
      <div className="flex-1 p-6 max-w-5xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Important Tasks</h1>
          <div className="flex items-center">
            <FaStar className="w-5 h-5 mr-2 text-yellow-500" />
            <p
              className={`text-sm font-medium ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              }`}
            >
              {filteredTodos.length} important{" "}
              {filteredTodos.length === 1 ? "task" : "tasks"}
            </p>
          </div>
        </div>

        <form onSubmit={addTodo} className="mb-8">
          <div className="relative">
            <input
              type="text"
              value={newTodo}
              onChange={(e) => setNewTodo(e.target.value)}
              placeholder="Add an important task"
              className={`w-full p-4 pr-12 rounded-lg ${
                isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
              } border ${
                isDarkMode ? "border-gray-600" : "border-gray-300"
              } focus:ring-2 focus:ring-blue-500 focus:border-transparent`}
            />
            <button
              type="submit"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600"
            >
              <PlusIcon className="w-6 h-6" />
            </button>
          </div>
        </form>

        <div className="flex space-x-2 mb-4">
          <button
            onClick={() => setFilter("all")}
            className={`px-3 py-1 rounded ${
              filter === "all"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            All
          </button>
          <button
            onClick={() => setFilter("todo")}
            className={`px-3 py-1 rounded ${
              filter === "todo"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            To Do
          </button>
          <button
            onClick={() => setFilter("completed")}
            className={`px-3 py-1 rounded ${
              filter === "completed"
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700"
            }`}
          >
            Completed
          </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="text-red-500 text-center">{error}</div>
        ) : (
          <AnimatePresence>
            {filteredTodos.map((todo) => (
              <motion.div
                key={todo.id}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className={`flex items-center p-4 rounded-lg ${
                  isDarkMode ? "bg-gray-800" : "bg-white"
                } shadow-sm hover:shadow-md transition-shadow duration-200 mb-4`}
              >
                <input
                  type="checkbox"
                  checked={todo.completed}
                  onChange={() => toggleTodo(todo.id, todo.completed)}
                  className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500 mr-3"
                />
                <span
                  className={`flex-grow ${
                    todo.completed ? "line-through text-gray-500" : ""
                  }`}
                >
                  {todo.title}
                </span>
                <div className="flex items-center space-x-2">
                  <select
                    value={todo.priority}
                    onChange={(e) => updatePriority(todo.id, e.target.value)}
                    className={`${
                      priorityColors[todo.priority]
                    } bg-transparent border-none`}
                  >
                    <option value="high">High</option>
                    <option value="medium">Medium</option>
                    <option value="low">Low</option>
                  </select>
                  <button
                    onClick={() => deleteTodo(todo.id)}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        )}

        {filteredTodos.length === 0 && !isLoading && (
          <div className="text-center text-gray-500 mt-8">
            No important tasks found. Add some important tasks to get started!
          </div>
        )}
      </div>
    );
  };

  const PlannedContent = () => {
    const [todos, setTodos] = useState([]);
    const [newTodo, setNewTodo] = useState("");
    const [newDueDate, setNewDueDate] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (userData && userData.uid) {
        fetchPlannedTodos();
      }
    }, [userData]);

    const fetchPlannedTodos = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const todosRef = collection(db, `users/${userData.uid}/todos`);
        const q = query(
          todosRef,
          where("dueDate", "!=", null),
          orderBy("dueDate", "asc")
        );
        const querySnapshot = await getDocs(q);
        const fetchedTodos = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTodos(fetchedTodos);
      } catch (err) {
        console.error("Error fetching planned todos:", err);
        setError("Failed to load planned todos. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const addTodo = async (e) => {
      e.preventDefault();
      if (!newTodo.trim() || !newDueDate) return;

      try {
        const dueDate = new Date(newDueDate);
        const todosRef = collection(db, `users/${userData.uid}/todos`);
        const newTodoDoc = await addDoc(todosRef, {
          title: newTodo,
          completed: false,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          dueDate: Timestamp.fromDate(dueDate),
          priority: "medium",
        });
        setTodos((prevTodos) => [
          {
            id: newTodoDoc.id,
            title: newTodo,
            completed: false,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            dueDate: Timestamp.fromDate(dueDate),
            priority: "medium",
          },
          ...prevTodos,
        ]);
        setNewTodo("");
        setNewDueDate("");
      } catch (err) {
        console.error("Error adding planned todo:", err);
        setError("Failed to add todo. Please try again.");
      }
    };

    const toggleTodo = async (id, completed) => {
      try {
        const todoRef = doc(db, `users/${userData.uid}/todos`, id);
        await updateDoc(todoRef, {
          completed: !completed,
          updatedAt: Timestamp.now(),
        });
        setTodos((prevTodos) =>
          prevTodos.map((todo) =>
            todo.id === id ? { ...todo, completed: !completed } : todo
          )
        );
      } catch (err) {
        console.error("Error updating todo:", err);
        setError("Failed to update todo. Please try again.");
      }
    };

    const deleteTodo = async (id) => {
      try {
        await deleteDoc(doc(db, `users/${userData.uid}/todos`, id));
        setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
      } catch (err) {
        console.error("Error deleting todo:", err);
        setError("Failed to delete todo. Please try again.");
      }
    };

    const updateDueDate = async (id, newDueDate) => {
      try {
        const todoRef = doc(db, `users/${userData.uid}/todos`, id);
        const updatedDate = new Date(newDueDate);
        await updateDoc(todoRef, {
          dueDate: Timestamp.fromDate(updatedDate),
          updatedAt: Timestamp.now(),
        });
        setTodos((prevTodos) =>
          prevTodos.map((todo) =>
            todo.id === id
              ? { ...todo, dueDate: Timestamp.fromDate(updatedDate) }
              : todo
          )
        );
      } catch (err) {
        console.error("Error updating todo due date:", err);
        setError("Failed to update todo due date. Please try again.");
      }
    };

    const formatDate = (date) => {
      return date.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const formatTime = (date) => {
      return date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      });
    };

    return (
      <div className="flex-1 p-6 max-w-5xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">Planned Tasks</h1>
        </div>

        <form onSubmit={addTodo} className="mb-8">
          <div className="relative">
            <input
              type="text"
              value={newTodo}
              onChange={(e) => setNewTodo(e.target.value)}
              placeholder="Add a planned task"
              className={`w-full p-4 pr-24 rounded-lg ${
                isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
              } border ${
                isDarkMode ? "border-gray-600" : "border-gray-300"
              } focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm`}
            />
            <input
              type="datetime-local"
              value={newDueDate}
              onChange={(e) => setNewDueDate(e.target.value)}
              className={`absolute right-14 top-1/2 transform -translate-y-1/2 p-2 rounded-lg ${
                isDarkMode
                  ? "bg-gray-600 text-white"
                  : "bg-gray-100 text-gray-800"
              } border ${isDarkMode ? "border-gray-500" : "border-gray-200"}`}
            />
            <button
              type="submit"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600"
            >
              <PlusIcon className="w-6 h-6" />
            </button>
          </div>
        </form>

        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : error ? (
          <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg">
            {error}
          </div>
        ) : (
          <div className="space-y-4">
            <AnimatePresence>
              {todos.map((todo) => (
                <motion.div
                  key={todo.id}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className={`flex items-center p-4 rounded-lg ${
                    isDarkMode ? "bg-gray-800" : "bg-white"
                  } shadow-sm hover:shadow-md transition-all duration-200`}
                >
                  <input
                    type="checkbox"
                    checked={todo.completed}
                    onChange={() => toggleTodo(todo.id, todo.completed)}
                    className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500 mr-4"
                  />
                  <div className="flex-grow">
                    <span
                      className={`text-lg ${
                        todo.completed ? "line-through text-gray-500" : ""
                      }`}
                    >
                      {todo.title}
                    </span>
                    <div className="text-sm text-gray-500 flex items-center mt-1">
                      <CalendarIcon className="w-4 h-4 mr-1" />
                      {formatDate(todo.dueDate.toDate())}
                      <ClockIcon className="w-4 h-4 ml-2 mr-1" />
                      {formatTime(todo.dueDate.toDate())}
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <input
                      type="datetime-local"
                      value={todo.dueDate.toDate().toISOString().slice(0, 16)}
                      onChange={(e) => updateDueDate(todo.id, e.target.value)}
                      className={`p-2 rounded-lg ${
                        isDarkMode
                          ? "bg-gray-700 text-white"
                          : "bg-gray-100 text-gray-800"
                      } border ${
                        isDarkMode ? "border-gray-600" : "border-gray-300"
                      }`}
                    />
                    <button
                      onClick={() => deleteTodo(todo.id)}
                      className="text-gray-400 hover:text-gray-600 focus:outline-none"
                    >
                      <TrashIcon className="w-5 h-5" />
                    </button>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        )}

        {todos.length === 0 && !isLoading && (
          <div className="text-center text-gray-500 mt-12">
            <ClipboardIcon className="w-16 h-16 mx-auto mb-4 text-gray-400" />
            <p className="text-xl font-medium mb-2">No planned tasks</p>
            <p className="text-sm">
              Add some tasks with due dates to get started!
            </p>
          </div>
        )}
      </div>
    );
  };

  const ListsContent = () => {
    const [lists, setLists] = useState([]);
    const [activeList, setActiveList] = useState({
      id: "all",
      name: "All Lists",
    });
    const [todos, setTodos] = useState([]);
    const [newListName, setNewListName] = useState("");
    const [newTodo, setNewTodo] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (userData && userData.uid) {
        fetchLists();
      }
    }, [userData]);

    useEffect(() => {
      if (activeList && lists.length > 0) {
        fetchTodos(activeList.id);
      }
    }, [activeList, lists]);

    // const fetchLists = async () => {
    //   setIsLoading(true);
    //   setError(null);
    //   try {
    //     const listsRef = collection(db, `users/${userData.uid}/lists`);
    //     const q = query(listsRef, orderBy("createdAt", "desc"));
    //     const querySnapshot = await getDocs(q);
    //     const fetchedLists = querySnapshot.docs.map((doc) => ({
    //       id: doc.id,
    //       ...doc.data(),
    //     }));
    //     setLists(fetchedLists);
    //     if (fetchedLists.length > 0) {
    //       setActiveList({ id: "all", name: "All Lists" });
    //     }
    //   } catch (err) {
    //     console.error("Error fetching lists:", err);
    //     setError("Failed to load lists. Please try again.");
    //   } finally {
    //     setIsLoading(false);
    //   }
    // };

    const fetchTodos = async (listId) => {
      setIsLoading(true);
      setError(null);
      try {
        const todosRef = collection(db, `users/${userData.uid}/todos`);
        let q;
        if (listId === "all") {
          if (lists.length === 0) {
            // If there are no lists, return an empty array of todos
            setTodos([]);
            setIsLoading(false);
            return;
          }
          q = query(
            todosRef,
            where(
              "listId",
              "in",
              lists.map((list) => list.id)
            ),
            orderBy("createdAt", "desc")
          );
        } else {
          q = query(
            todosRef,
            where("listId", "==", listId),
            orderBy("createdAt", "desc")
          );
        }
        const querySnapshot = await getDocs(q);
        const fetchedTodos = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTodos(fetchedTodos);
      } catch (err) {
        console.error("Error fetching todos:", err);
        setError("Failed to load todos. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const addList = async (e) => {
      e.preventDefault();
      if (!newListName.trim()) return;

      try {
        const listsRef = collection(db, `users/${userData.uid}/lists`);
        const newListDoc = await addDoc(listsRef, {
          name: newListName,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        });
        const newList = {
          id: newListDoc.id,
          name: newListName,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        };
        setLists((prevLists) => [newList, ...prevLists]);
        setNewListName("");
        setActiveList(newList);
      } catch (err) {
        console.error("Error adding list:", err);
        setError("Failed to add list. Please try again.");
      }
    };

    // const deleteList = async (listId) => {
    //   try {
    //     await deleteDoc(doc(db, `users/${userData.uid}/lists`, listId));
    //     setLists((prevLists) => prevLists.filter((list) => list.id !== listId));
    //     if (activeList && activeList.id === listId) {
    //       setActiveList({ id: "all", name: "All Lists" });
    //     }
    //   } catch (err) {
    //     console.error("Error deleting list:", err);
    //     setError("Failed to delete list. Please try again.");
    //   }
    // };

    const addTodo = async (e) => {
      e.preventDefault();
      if (!newTodo.trim() || activeList.id === "all") return;

      try {
        const todosRef = collection(db, `users/${userData.uid}/todos`);
        const newTodoDoc = await addDoc(todosRef, {
          title: newTodo,
          completed: false,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          listId: activeList.id,
        });
        setTodos((prevTodos) => [
          {
            id: newTodoDoc.id,
            title: newTodo,
            completed: false,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            listId: activeList.id,
          },
          ...prevTodos,
        ]);
        setNewTodo("");
      } catch (err) {
        console.error("Error adding todo:", err);
        setError("Failed to add todo. Please try again.");
      }
    };

    const toggleTodo = async (id, completed) => {
      try {
        const todoRef = doc(db, `users/${userData.uid}/todos`, id);
        await updateDoc(todoRef, {
          completed: !completed,
          updatedAt: Timestamp.now(),
        });
        setTodos((prevTodos) =>
          prevTodos.map((todo) =>
            todo.id === id ? { ...todo, completed: !completed } : todo
          )
        );
      } catch (err) {
        console.error("Error updating todo:", err);
        setError("Failed to update todo. Please try again.");
      }
    };

    const deleteTodo = async (id) => {
      try {
        await deleteDoc(doc(db, `users/${userData.uid}/todos`, id));
        setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
      } catch (err) {
        console.error("Error deleting todo:", err);
        setError("Failed to delete todo. Please try again.");
      }
    };

    // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    // const [selectedTodo, setSelectedTodo] = useState(null);

    // const toggleDropdown = (todo) => {
    //   if (selectedTodo && selectedTodo.id === todo.id) {
    //     setIsDropdownOpen(!isDropdownOpen);
    //   } else {
    //     setSelectedTodo(todo);
    //     setIsDropdownOpen(true);
    //   }
    // };

    // const closeDropdown = () => {
    //   setIsDropdownOpen(false);
    //   setSelectedTodo(null);
    // };

    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     if (isDropdownOpen && !event.target.closest(".dropdown")) {
    //       closeDropdown();
    //     }
    //   };

    //   document.addEventListener("mousedown", handleClickOutside);
    //   return () => {
    //     document.removeEventListener("mousedown", handleClickOutside);
    //   };
    // }, [isDropdownOpen]);

    const [openDropdownId, setOpenDropdownId] = useState(null);

    const toggleDropdown = (listId) => {
      setOpenDropdownId(openDropdownId === listId ? null : listId);
    };

    const closeDropdown = () => {
      setOpenDropdownId(null);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (openDropdownId && !event.target.closest(".dropdown")) {
          closeDropdown();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [openDropdownId]);

    const deleteList = async (listId) => {
      try {
        await deleteDoc(doc(db, `users/${userData.uid}/lists`, listId));
        setLists(lists.filter((list) => list.id !== listId));
        if (activeList.id === listId) {
          setActiveList({ id: "all", name: "All Lists" });
        }
        closeDropdown();
      } catch (err) {
        console.error("Error deleting list:", err);
        setError("Failed to delete list. Please try again.");
      }
    };

    const renameList = async (listId) => {
      const newName = prompt("Enter new list name:");
      if (newName) {
        try {
          await updateDoc(doc(db, `users/${userData.uid}/lists`, listId), {
            name: newName,
          });
          setLists(
            lists.map((list) =>
              list.id === listId ? { ...list, name: newName } : list
            )
          );
          if (activeList.id === listId) {
            setActiveList((prev) => ({ ...prev, name: newName }));
          }
          closeDropdown();
        } catch (err) {
          console.error("Error renaming list:", err);
          setError("Failed to rename list. Please try again.");
        }
      }
    };

    const duplicateList = async (listId) => {
      try {
        const listToDuplicate = lists.find((list) => list.id === listId);
        const newListRef = await addDoc(
          collection(db, `users/${userData.uid}/lists`),
          {
            name: `${listToDuplicate.name} (Copy)`,
            createdAt: Timestamp.now(),
            order: lists.length,
          }
        );
        const newList = {
          id: newListRef.id,
          name: `${listToDuplicate.name} (Copy)`,
          order: lists.length,
        };
        setLists([...lists, newList]);
        closeDropdown();
      } catch (err) {
        console.error("Error duplicating list:", err);
        setError("Failed to duplicate list. Please try again.");
      }
    };

    const updateListsOrder = async (newLists) => {
      setIsLoading(true);
      setError(null);
      try {
        const batch = writeBatch(db);
        newLists.forEach((list, index) => {
          const listRef = doc(db, `users/${userData.uid}/lists`, list.id);
          batch.update(listRef, { order: index });
        });
        await batch.commit();

        // Fetch the updated lists from Firestore to ensure consistency
        await fetchLists();
      } catch (err) {
        console.error("Error updating lists order:", err);
        setError("Failed to update lists order. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const fetchLists = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const listsRef = collection(db, `users/${userData.uid}/lists`);
        const q = query(listsRef, orderBy("order", "asc"));
        const querySnapshot = await getDocs(q);
        const updatedLists = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLists(updatedLists);
        // After fetching lists, fetch todos for "all" lists
        await fetchTodos("all");
      } catch (err) {
        console.error("Error fetching lists:", err);
        setError("Failed to fetch lists. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (userData && userData.uid) {
        fetchLists();
      }
    }, [userData]);

    const handleDrop = (fromIndex, toIndex) => {
      const newLists = [...lists];
      const [movedItem] = newLists.splice(fromIndex, 1);
      newLists.splice(toIndex, 0, movedItem);
      updateListsOrder(newLists);
    };

    const addTodoToList = (listId) => {
      openNewTaskModal(listId);
    };

    const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
    const [newTaskTitle, setNewTaskTitle] = useState("");
    const [selectedListForNewTask, setSelectedListForNewTask] = useState(null);

    const openNewTaskModal = (listId = null) => {
      setSelectedListForNewTask(listId);
      setIsNewTaskModalOpen(true);
    };

    const closeNewTaskModal = () => {
      setIsNewTaskModalOpen(false);
      setNewTaskTitle("");
      setSelectedListForNewTask(null);
    };

    const handleAddNewTask = async () => {
      if (!newTaskTitle.trim() || !selectedListForNewTask) return;

      try {
        const todosRef = collection(db, `users/${userData.uid}/todos`);
        const newTodoDoc = await addDoc(todosRef, {
          title: newTaskTitle,
          completed: false,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          listId: selectedListForNewTask,
        });

        setTodos((prevTodos) => [
          {
            id: newTodoDoc.id,
            title: newTaskTitle,
            completed: false,
            createdAt: Timestamp.now(),
            updatedAt: Timestamp.now(),
            listId: selectedListForNewTask,
          },
          ...prevTodos,
        ]);

        closeNewTaskModal();
      } catch (err) {
        console.error("Error adding todo:", err);
        setError("Failed to add todo. Please try again.");
      }
    };

    return (
      <div className="flex-1 flex">
        {/* Lists Sidebar */}
        <div className="w-72 h-full overflow-y-auto">
          <div className="p-6">
            <h2 className="text-2xl font-semibold mb-6">My Lists</h2>
          </div>
          <div className="px-4 -mt-5">
            <div
              className={`flex items-center justify-between p-3 rounded-lg mb-2 cursor-pointer transition-all duration-200 ${
                activeList && activeList.id === "all"
                  ? isDarkMode
                    ? "bg-blue-600 text-white"
                    : "bg-blue-100 text-blue-800"
                  : isDarkMode
                  ? "text-gray-300 hover:bg-gray-800"
                  : "text-gray-800 hover:bg-gray-200"
              }`}
              onClick={() => setActiveList({ id: "all", name: "All Lists" })}
            >
              <span className="font-medium">All Lists</span>
            </div>
            {lists.map((list, index) => (
              <div
                key={list.id}
                draggable
                onDragStart={(e) => e.dataTransfer.setData("text/plain", index)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => {
                  e.preventDefault();
                  const fromIndex = Number(
                    e.dataTransfer.getData("text/plain")
                  );
                  const toIndex = index;
                  handleDrop(fromIndex, toIndex);
                }}
                className={`flex items-center justify-between p-3 rounded-lg mb-2 cursor-pointer transition-all duration-200 ${
                  activeList && activeList.id === list.id
                    ? isDarkMode
                      ? "bg-blue-600 text-white"
                      : "bg-blue-100 text-blue-800"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-800 hover:bg-gray-200"
                }`}
              >
                <div
                  className="flex items-center flex-grow"
                  onClick={() => setActiveList(list)}
                >
                  <FaBars className="mr-3 cursor-move text-gray-500" />
                  <span className="font-medium">{list.name}</span>
                </div>
                <div className="relative dropdown">
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleDropdown(list.id);
                    }}
                    className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors duration-150"
                  >
                    <FaEllipsisH />
                  </button>
                  <AnimatePresence>
                    {openDropdownId === list.id && (
                      <motion.div
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        transition={{ duration: 0.1 }}
                        className={`absolute right-0 mt-2 w-48 rounded-md shadow-lg overflow-hidden z-10 ${
                          isDarkMode ? "bg-gray-800" : "bg-white"
                        } ring-1 ring-black ring-opacity-5`}
                      >
                        <div
                          className="py-1"
                          role="menu"
                          aria-orientation="vertical"
                          aria-labelledby="options-menu"
                        >
                          {[
                            {
                              label: "Delete",
                              action: deleteList,
                              icon: FaTrash,
                            },
                            {
                              label: "Rename",
                              action: renameList,
                              icon: FaEdit,
                            },
                            {
                              label: "Duplicate",
                              action: duplicateList,
                              icon: FaCopy,
                            },
                          ].map((item) => (
                            <motion.button
                              key={item.label}
                              whileHover={{
                                backgroundColor: isDarkMode
                                  ? "#374151"
                                  : "#F3F4F6",
                              }}
                              transition={{ duration: 0.2 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                item.action(list.id);
                              }}
                              className={`flex items-center w-full px-4 py-2 text-sm ${
                                isDarkMode
                                  ? "text-gray-300 hover:text-white"
                                  : "text-gray-700 hover:text-gray-900"
                              } text-left transition-colors duration-200`}
                              role="menuitem"
                            >
                              <item.icon className="mr-3 h-4 w-4" />
                              {item.label}
                            </motion.button>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            ))}
            <form onSubmit={addList} className="mt-4">
              <div className="relative">
                <input
                  type="text"
                  value={newListName}
                  onChange={(e) => setNewListName(e.target.value)}
                  placeholder="Create new list"
                  className={`w-full p-3 pr-12 rounded-lg ${
                    isDarkMode
                      ? "bg-gray-800 text-white"
                      : "bg-white text-gray-800"
                  } border ${
                    isDarkMode ? "border-gray-700" : "border-gray-300"
                  } focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200`}
                />
                <button
                  type="submit"
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600 transition-colors duration-200"
                >
                  <FaPlus className="h-5 w-5" />
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Todos for Active List */}
        <div className="flex-1 p-8">
          <div className="flex justify-between items-center mb-6">
            <h1
              className={`text-3xl font-bold ${
                isDarkMode ? "text-white" : "text-gray-800"
              }`}
            >
              {activeList ? (
                <>
                  <span className="mr-2">{activeList.name}</span>
                  <span className="text-sm font-normal text-gray-500 bg-gray-200 rounded-full px-3 py-1 ml-2">
                    {todos.length} {todos.length === 1 ? "task" : "tasks"}
                  </span>
                </>
              ) : (
                <span className="text-gray-500">Select a List</span>
              )}
            </h1>
            {activeList && (
              <button
                onClick={() =>
                  activeList.id === "all"
                    ? setOpenDropdownId("addToList")
                    : openNewTaskModal(activeList.id)
                }
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors duration-200 flex items-center"
              >
                <FaPlus className="mr-2" />
                {activeList.id === "all" ? "Add to List" : "Add Task"}
              </button>
            )}
          </div>

          {/* Dropdown for "Add to List" button */}
          <AnimatePresence>
            {openDropdownId === "addToList" && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2, ease: "easeOut" }}
                className={`absolute right-8 mt-2 w-56 rounded-lg shadow-xl overflow-hidden z-10 ${
                  isDarkMode ? "bg-gray-800" : "bg-white"
                } border ${isDarkMode ? "border-gray-700" : "border-gray-200"}`}
              >
                <div
                  className="py-2"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  {lists.length > 0 ? (
                    lists.map((list) => (
                      <motion.button
                        key={list.id}
                        whileHover={{ x: 5 }}
                        transition={{ duration: 0.2 }}
                        onClick={() => {
                          addTodoToList(list.id);
                          setOpenDropdownId(null);
                        }}
                        className={`flex items-center w-full px-4 py-3 text-sm ${
                          isDarkMode
                            ? "text-gray-300 hover:bg-gray-700 hover:text-white"
                            : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                        } text-left transition-colors duration-200`}
                        role="menuitem"
                      >
                        <span className="mr-2">
                          <FaList
                            className={
                              isDarkMode ? "text-gray-400" : "text-gray-500"
                            }
                          />
                        </span>
                        {list.name}
                      </motion.button>
                    ))
                  ) : (
                    <div className="px-4 py-3 text-sm text-gray-500">
                      No Lists Found
                    </div>
                  )}
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* New Task Modal */}
          <Transition.Root show={isNewTaskModalOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-50"
              onClose={closeNewTaskModal}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity" />
              </Transition.Child>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  >
                    <Dialog.Panel
                      className={`relative transform overflow-hidden rounded-xl ${
                        isDarkMode
                          ? "bg-gray-800 text-white"
                          : "bg-white text-gray-900"
                      } px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6`}
                    >
                      <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                        <button
                          type="button"
                          className={`rounded-md ${
                            isDarkMode
                              ? "text-gray-400 hover:text-gray-300"
                              : "text-gray-400 hover:text-gray-500"
                          } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                          onClick={closeNewTaskModal}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                          <Dialog.Title
                            as="h3"
                            className={`text-2xl font-semibold leading-6 ${
                              isDarkMode ? "text-white" : "text-gray-900"
                            }`}
                          >
                            Add New Task
                          </Dialog.Title>
                          <div className="mt-6">
                            <div>
                              <label
                                htmlFor="task-title"
                                className={`block text-sm font-medium mb-2 ${
                                  isDarkMode ? "text-gray-300" : "text-gray-700"
                                }`}
                              >
                                Task Title
                              </label>
                              <input
                                type="text"
                                id="task-title"
                                value={newTaskTitle}
                                onChange={(e) =>
                                  setNewTaskTitle(e.target.value)
                                }
                                className={`w-full px-4 py-2 rounded-lg border ${
                                  isDarkMode
                                    ? "bg-gray-700 border-gray-600 text-white"
                                    : "bg-white border-gray-300 text-gray-900"
                                } focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-all`}
                                placeholder="Enter task title"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-6 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className={`inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto ${
                            isDarkMode
                              ? "bg-blue-600 hover:bg-blue-500"
                              : "bg-blue-600 hover:bg-blue-700"
                          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
                          onClick={handleAddNewTask}
                        >
                          Add Task
                        </button>
                        <button
                          type="button"
                          className={`mt-3 inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset sm:mt-0 sm:w-auto ${
                            isDarkMode
                              ? "bg-gray-700 text-gray-300 hover:bg-gray-600 ring-gray-500"
                              : "bg-white text-gray-900 hover:bg-gray-50 ring-gray-300"
                          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                          onClick={closeNewTaskModal}
                        >
                          Cancel
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">{error}</div>
          ) : (
            <AnimatePresence>
              {todos.map((todo) => (
                <motion.div
                  key={todo.id}
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -10 }}
                  transition={{ duration: 0.2 }}
                  className={`flex items-center p-4 rounded-lg ${
                    isDarkMode ? "bg-gray-800" : "bg-white"
                  } shadow-sm hover:shadow-md transition-shadow duration-200 mb-4`}
                >
                  <input
                    type="checkbox"
                    checked={todo.completed}
                    onChange={() => toggleTodo(todo.id, todo.completed)}
                    className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500 mr-3"
                  />
                  <span
                    className={`flex-grow ${
                      todo.completed ? "line-through text-gray-500" : ""
                    }`}
                  >
                    {todo.title}
                  </span>
                  {activeList && activeList.id === "all" && (
                    <span className="text-sm text-gray-500 mr-4">
                      {lists.find((list) => list.id === todo.listId)?.name ||
                        "Unknown List"}
                    </span>
                  )}
                  <button
                    onClick={() => deleteTodo(todo.id)}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                  >
                    <FaEllipsisH />
                  </button>
                </motion.div>
              ))}
            </AnimatePresence>
          )}

          {todos.length === 0 && !isLoading && activeList && (
            <div className="text-center text-gray-500 mt-8">
              {activeList.id === "all"
                ? "No tasks in any list. Create a list and add some tasks to get started!"
                : "No tasks in this list. Add some tasks to get started!"}
            </div>
          )}
        </div>
      </div>
    );
  };

  const msftInnerTabs = [
    { id: "msftMyDay", name: "My Day", icon: FaSun },
    { id: "msftImportant", name: "Important", icon: FaStar },
    { id: "msftPlanned", name: "Planned", icon: FaCalendarAlt },
    { id: "msftLists", name: "Lists", icon: FaList },
  ];

  const [activeMsftTab, setActiveMsftTab] = useState("msftMyDay");

  const handleMsftTabClick = (tabId) => {
    setActiveMsftTab(tabId);
  };

  const MicrosoftSidebar = () => (
    <aside className="w-72 h-full overflow-y-auto transition-all duration-300 ease-in-out">
      <div className="p-4">
        <nav className="mt-5">
          {msftInnerTabs.map((tab) => (
            <button
              key={tab.id}
              onClick={() => handleMsftTabClick(tab.id)}
              className={`w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center mb-2 ${
                activeMsftTab === tab.id
                  ? isDarkMode
                    ? "bg-blue-600 text-white"
                    : "bg-blue-50 text-blue-700"
                  : isDarkMode
                  ? "text-gray-300 hover:bg-gray-800"
                  : "text-gray-700 hover:bg-gray-100"
              }`}
            >
              <tab.icon className="mr-3" size={18} />
              <span className="font-medium">{tab.name}</span>
            </button>
          ))}
        </nav>
      </div>
    </aside>
  );

  const MicrosoftContent = () => {
    return (
      <div className="flex flex-1">
        <MicrosoftSidebar />
        <div className="flex-1">
          {activeMsftTab === "msftMyDay" && (
            <div className="p-6 max-w-5xl mx-auto">
              <h1 className="text-3xl font-bold mb-8">
                Microsoft {activeTab === "msftPersonal" ? "Personal" : "Work"}{" "}
                My Day
              </h1>
              {(activeTab === "msftPersonal"
                ? msftPersonalTodos
                : msftWorkTodos
              )
                .filter((todo) => todo.list === "myDay")
                .map((todo) => (
                  <TodoItem
                    key={todo.id}
                    todo={todo}
                    toggleTodo={toggleMicrosoftTodo}
                    deleteTodo={deleteMicrosoftTodo}
                  />
                ))}
            </div>
          )}
          {activeMsftTab === "msftLists" && activeTab === "msftWork" && (
            <MicrosoftWorkListsContent
              todos={msftWorkTodos}
              toggleTodo={toggleMicrosoftTodo}
              deleteTodo={deleteMicrosoftTodo}
              isDarkMode={isDarkMode}
            />
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`flex h-screen ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-white"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-800"
      }`}
    >
      <div className="flex flex-1">
        {/* Todo Sidebar */}
        <aside
          className={`w-72 h-full ${
            isDarkMode ? "" : ""
          } overflow-y-auto transition-all duration-300 ease-in-out`}
        >
          <div className="p-4">
            <nav className="mt-5">
              {todoTabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center mb-2 ${
                    activeTab === tab.id
                      ? isDarkMode
                        ? "bg-blue-600 text-white"
                        : "bg-blue-50 text-blue-700"
                      : isDarkMode
                      ? "text-gray-300 hover:bg-gray-800"
                      : "text-gray-700 hover:bg-gray-100"
                  }`}
                >
                  <tab.icon className="mr-3" size={18} />
                  <span className="font-medium">{tab.name}</span>
                </button>
              ))}
            </nav>
            <div className="mt-8">
              <h3 className="text-sm font-semibold text-gray-500 uppercase tracking-wider mb-2">
                Microsoft Todo
              </h3>
              {msftTabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center mb-2 ${
                    activeTab === tab.id
                      ? isDarkMode
                        ? "bg-blue-600 text-white"
                        : "bg-blue-50 text-blue-700"
                      : isDarkMode
                      ? "text-gray-300 hover:bg-gray-800"
                      : "text-gray-700 hover:bg-gray-100"
                  }`}
                >
                  <tab.icon className="mr-3" size={18} />
                  <span className="font-medium">{tab.name}</span>
                </button>
              ))}
            </div>
          </div>
        </aside>

        {/* Main Content Area */}
        {activeTab === "myDay" && <MyDayContent />}
        {activeTab === "important" && <ImportantContent />}
        {activeTab === "planned" && <PlannedContent />}
        {activeTab === "lists" && <ListsContent />}
        {(activeTab === "msftPersonal" || activeTab === "msftWork") && (
          <MicrosoftContent />
        )}
      </div>
    </div>
  );
}

export default AppTodo;
