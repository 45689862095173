import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { motion } from "framer-motion";

function Card({ card, index, isDarkMode }) {
  if (!card || !card.id) {
    return null;
  }

  const draggableId = card.id.toString();

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(provided) => (
        <motion.div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          whileHover={{ scale: 1.03 }}
          whileTap={{ scale: 0.97 }}
          transition={{ duration: 0.2 }}
          className={`p-4 mb-3 rounded-lg ${
            isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
          } shadow-sm hover:shadow-md transition-all duration-200 ease-in-out border ${
            isDarkMode ? "border-gray-600" : "border-gray-200"
          } relative`}
        >
          <h3 className="text-sm font-semibold truncate">
            {card.name || "Unnamed Card"}
          </h3>
          {card &&
            card.labels &&
            card.labels.map((label, i) => (
              <motion.span
                key={i}
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: label.color }}
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.2 }}
              ></motion.span>
            ))}
          {card && card.description && (
            <motion.p
              className={`text-xs ${
                isDarkMode ? "text-gray-300" : "text-gray-600"
              } line-clamp-2`}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
            >
              {card.description}
            </motion.p>
          )}
          <motion.div
            className={`mt-2 text-xs ${
              isDarkMode ? "text-gray-400" : "text-gray-500"
            } flex justify-between items-center`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <span>
              {card && card.dueDate
                ? new Date(card.dueDate).toLocaleDateString()
                : "No due date"}
            </span>
            <span>{card && card.assignee ? card.assignee : "Unassigned"}</span>
          </motion.div>
        </motion.div>
      )}
    </Draggable>
  );
}

export default Card;