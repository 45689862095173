import React, { useState, useEffect, useContext, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DarkModeContext } from "../context/DarkModeContext";
import { UserContext } from "../context/UserContext";
import Sidebar from "../components/Sidebar";
import {
  getEvents,
  addEvent,
  updateEvent,
  deleteEvent,
} from "../utils/calendarUtils";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  XIcon as XMarkIcon,
  CalendarIcon,
  ClockIcon,
  RefreshIcon as ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/outline";
import {
  format,
  addDays,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  isSameDay,
  addMonths,
  subMonths,
  setHours,
  setMinutes,
} from "date-fns";
import "../styles/datepicker.css";
import "../styles/calendar-dark-mode.css";
import EventEmitter from "../utils/eventEmitter";
import { getRecentEvents } from "../utils/calendarUtils";
import { useLocation } from "react-router-dom";

const localizer = momentLocalizer(moment);

Modal.setAppElement("#root");

function CustomToolbar({ label, onNavigate, onView, view }) {
  const { isDarkMode } = useContext(DarkModeContext);

  const buttonClass = (isActive) =>
    `px-3 py-1 rounded-full text-sm font-medium transition-colors duration-200 ${
      isActive
        ? "bg-indigo-600 text-white"
        : isDarkMode
        ? "bg-gray-700 text-gray-200 hover:bg-gray-600"
        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
    }`;

  return (
    <div className="flex justify-between items-center mb-6 mt-2 mx-2">
      <div className="flex items-center space-x-2">
        <button
          onClick={() => onNavigate("TODAY")}
          className={buttonClass(false)}
        >
          Today
        </button>
        <button
          onClick={() => onNavigate("PREV")}
          className={buttonClass(false)}
        >
          &lt;
        </button>
        <button
          onClick={() => onNavigate("NEXT")}
          className={buttonClass(false)}
        >
          &gt;
        </button>
      </div>
      <span className="text-lg font-semibold">{label}</span>
      <div className="flex items-center space-x-2">
        {["month", "week", "day", "agenda"].map((viewType) => (
          <button
            key={viewType}
            onClick={() => onView(viewType)}
            className={buttonClass(view === viewType)}
          >
            {viewType.charAt(0).toUpperCase() + viewType.slice(1)}
          </button>
        ))}
      </div>
    </div>
  );
}

function AppCalendar() {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData } = useContext(UserContext);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: new Date(),
    end: new Date(),
    isRecurring: false,
    recurrence: "none",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.state && location.state.openNewEventPanel) {
      setIsNewEventPanelOpen(true);
    }
  }, [location]);

  const [isNewEventPanelOpen, setIsNewEventPanelOpen] = useState(false);
  const [isAddingEvent, setIsAddingEvent] = useState(false);
  const [recentEvents, setRecentEvents] = useState([]);

  useEffect(() => {
    if (userData && userData.uid) {
      fetchEvents(userData.uid);
      fetchRecentEvents();
    }
  }, [userData]);

  const fetchRecentEvents = async () => {
    try {
      const recentEvents = await getRecentEvents(userData.uid, 5);
      setRecentEvents(recentEvents);
    } catch (error) {
      console.error("Error fetching recent events:", error);
    }
  };

  useEffect(() => {
    if (userData && userData.uid) {
      fetchEvents(userData.uid);
    }

    // Listen for calendarEventAdded event
    const refreshEvents = () => {
      if (userData && userData.uid) {
        fetchEvents(userData.uid);
      }
    };
    EventEmitter.on("calendarEventAdded", refreshEvents);

    // Clean up the event listener
    return () => {
      EventEmitter.removeListener("calendarEventAdded", refreshEvents);
    };
  }, [userData]);

  const fetchEvents = async (userId) => {
    try {
      const result = await getEvents(userId);
      console.log("Raw events data:", result);
      const formattedEvents = result.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
      }));
      console.log("Formatted events:", formattedEvents);
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const handleAddEvent = async () => {
    if (!newEvent.title.trim()) {
      return;
    }
    setIsAddingEvent(true);
    try {
      const eventToAdd = {
        ...newEvent,
        start: newEvent.start.toISOString(),
        end: newEvent.end.toISOString(),
      };
      await addEvent(userData.uid, eventToAdd);
      if (newEvent.isRecurring) {
        const recurringEvents = generateRecurringEvents(newEvent);
        for (const event of recurringEvents) {
          await addEvent(userData.uid, event);
        }
      }
      await fetchEvents(userData.uid);
      setIsNewEventPanelOpen(false);
    } catch (error) {
      console.error("Error adding event:", error);
    } finally {
      setIsAddingEvent(false);
    }
  };

  const generateRecurringEvents = (event) => {
    const events = [];
    const { recurrence } = event;
    let currentStart = new Date(event.start);
    let currentEnd = new Date(event.end);
    const endDate = new Date(currentStart);
    endDate.setFullYear(endDate.getFullYear() + 1); // Generate events for one year

    // Skip the first occurrence as it's already added
    switch (recurrence) {
      case "daily":
        currentStart.setDate(currentStart.getDate() + 1);
        currentEnd.setDate(currentEnd.getDate() + 1);
        break;
      case "weekly":
        currentStart.setDate(currentStart.getDate() + 7);
        currentEnd.setDate(currentEnd.getDate() + 7);
        break;
      case "monthly":
        currentStart.setMonth(currentStart.getMonth() + 1);
        currentEnd.setMonth(currentEnd.getMonth() + 1);
        break;
      case "yearly":
        currentStart.setFullYear(currentStart.getFullYear() + 1);
        currentEnd.setFullYear(currentEnd.getFullYear() + 1);
        break;
    }

    while (currentStart < endDate) {
      events.push({
        ...event,
        start: currentStart.toISOString(),
        end: currentEnd.toISOString(),
      });

      switch (recurrence) {
        case "daily":
          currentStart.setDate(currentStart.getDate() + 1);
          currentEnd.setDate(currentEnd.getDate() + 1);
          break;
        case "weekly":
          currentStart.setDate(currentStart.getDate() + 7);
          currentEnd.setDate(currentEnd.getDate() + 7);
          break;
        case "monthly":
          currentStart.setMonth(currentStart.getMonth() + 1);
          currentEnd.setMonth(currentEnd.getMonth() + 1);
          break;
        case "yearly":
          currentStart.setFullYear(currentStart.getFullYear() + 1);
          currentEnd.setFullYear(currentEnd.getFullYear() + 1);
          break;
      }
    }

    return events;
  };

  const handleUpdateEvent = async (updatedEvent) => {
    console.log("Updating event:", updatedEvent);
    try {
      await updateEvent(userData.uid, updatedEvent);
      console.log("Event updated in database");

      // Update the local state
      setEvents((prevEvents) => {
        const updatedEvents = prevEvents.map((event) =>
          event.id === updatedEvent.id ? { ...updatedEvent } : event
        );
        console.log("Updated events:", updatedEvents);
        return updatedEvents;
      });

      setIsModalOpen(false);
      console.log("Modal closed");
    } catch (error) {
      console.error("Error updating event:", error);
      // You might want to show an error message to the user here
    }
  };

  const handleDeleteEvent = async (event) => {
    await deleteEvent(userData.uid, event.id);
    fetchEvents();
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setNewEvent(event);
    setIsModalOpen(true);
  };

  function EventDetailsModal({
    isOpen,
    onClose,
    event,
    setEvent,
    onSave,
    onDelete,
    isDarkMode,
  }) {
    const [editedEvent, setEditedEvent] = useState(
      event || {
        title: "",
        start: new Date(),
        end: new Date(),
        isRecurring: false,
        recurrence: "none",
      }
    );
    const [isStartPickerOpen, setIsStartPickerOpen] = useState(false);
    const [isEndPickerOpen, setIsEndPickerOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());

    useEffect(() => {
      if (event) {
        setEditedEvent(event);
      }
    }, [event]);

    const handleSave = () => {
      onSave(editedEvent);
      onClose();
    };

    const handleDelete = () => {
      // Optimistically delete the event
      onDelete(editedEvent);
      // Close the modal
      onClose();
    };

    const CustomInput = React.forwardRef(({ value, onClick, isStart }, ref) => (
      <div className="relative">
        <input
          ref={ref}
          className={`w-full py-2 px-3 rounded-md ${
            isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-900"
          } border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500`}
          value={format(value, "MMM d, yyyy h:mm aa")}
          onClick={() => {
            onClick();
            if (isStart) {
              setIsStartPickerOpen(true);
              setIsEndPickerOpen(false);
            } else {
              setIsEndPickerOpen(true);
              setIsStartPickerOpen(false);
            }
          }}
          readOnly
        />
        <CalendarIcon
          className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 cursor-pointer"
          onClick={onClick}
        />
      </div>
    ));

    const renderHeader = () => {
      const dateFormat = "MMMM yyyy";
      return (
        <div className="flex items-center justify-between p-2">
          <button
            onClick={prevMonth}
            className="p-1 rounded-full hover:bg-gray-200"
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
          <span className="text-lg font-bold">
            {format(currentMonth, dateFormat)}
          </span>
          <button
            onClick={nextMonth}
            className="p-1 rounded-full hover:bg-gray-200"
          >
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </div>
      );
    };

    const renderDays = () => {
      const dateFormat = "EEEEEE";
      const days = [];
      let startDate = startOfWeek(currentMonth);
      for (let i = 0; i < 7; i++) {
        days.push(
          <div
            key={i}
            className="text-center font-medium text-gray-500 text-xs uppercase"
          >
            {format(addDays(startDate, i), dateFormat)}
          </div>
        );
      }
      return <div className="grid grid-cols-7 gap-1 mb-2">{days}</div>;
    };

    const renderCells = (selectedDate, onChange) => {
      const monthStart = startOfMonth(currentMonth);
      const monthEnd = endOfMonth(monthStart);
      const startDate = startOfWeek(monthStart);
      const endDate = endOfWeek(monthEnd);
      const rows = [];
      let days = [];
      let day = startDate;

      while (day <= endDate) {
        for (let i = 0; i < 7; i++) {
          const cloneDay = day;
          const isCurrentMonth = isSameMonth(day, monthStart);
          const isToday = isSameDay(day, new Date());
          const isSelected = isSameDay(day, selectedDate);

          days.push(
            <div
              key={day}
              className={`p-2 text-center cursor-pointer ${
                !isCurrentMonth
                  ? isDarkMode
                    ? "text-gray-600 hover:bg-gray-700"
                    : "text-gray-400 hover:bg-gray-100"
                  : isSelected
                  ? "bg-indigo-600 text-white rounded-full"
                  : isDarkMode
                  ? "text-white hover:bg-gray-700"
                  : "hover:bg-gray-100"
              } ${
                isToday && !isSelected
                  ? isDarkMode
                    ? "border border-indigo-400 text-indigo-400"
                    : "border border-indigo-600 text-indigo-600"
                  : ""
              } rounded-full`}
              onClick={() => onChange(cloneDay)}
            >
              {format(day, "d")}
            </div>
          );
          day = addDays(day, 1);
        }
        rows.push(
          <div key={day} className="grid grid-cols-7 gap-1 mb-1">
            {days}
          </div>
        );
        days = [];
      }
      return <div className="mb-2">{rows}</div>;
    };

    const prevMonth = () => {
      setCurrentMonth(subMonths(currentMonth, 1));
    };

    const nextMonth = () => {
      setCurrentMonth(addMonths(currentMonth, 1));
    };

    const CustomDatePicker = ({
      selectedDate,
      onChange,
      isOpen,
      onClose,
      minDate,
    }) => {
      const [showTimePicker, setShowTimePicker] = useState(false);
      const [tempDate, setTempDate] = useState(selectedDate);

      const handleDateSelect = (date) => {
        if (!minDate || date >= minDate) {
          setTempDate(date);
          setShowTimePicker(true);
        }
      };

      const handleTimeSelect = (hours, minutes) => {
        const newDate = setMinutes(setHours(tempDate, hours), minutes);
        onChange(newDate);
        setShowTimePicker(false);
        onClose();
      };

      const renderTimePicker = () => {
        const hours = Array.from({ length: 12 }, (_, i) => i + 1);
        const minutes = [0, 15, 30, 45];
        const periods = ["AM", "PM"];

        return (
          <div
            className={`p-4 ${
              isDarkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
            }`}
          >
            <h3 className="text-lg font-semibold mb-4">Select Time</h3>
            <div className="flex items-center space-x-2">
              <select
                value={tempDate.getHours() % 12 || 12}
                onChange={(e) => {
                  const newHour = parseInt(e.target.value);
                  const newDate = setHours(
                    tempDate,
                    newHour + (tempDate.getHours() >= 12 ? 12 : 0)
                  );
                  setTempDate(newDate);
                }}
                className={`form-select block w-20 ${
                  isDarkMode
                    ? "bg-gray-700 text-white"
                    : "bg-white text-gray-900"
                } border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
              >
                {hours.map((hour) => (
                  <option key={hour} value={hour}>
                    {hour.toString().padStart(2, "0")}
                  </option>
                ))}
              </select>
              <span>:</span>
              <select
                value={tempDate.getMinutes()}
                onChange={(e) => {
                  const newDate = setMinutes(
                    tempDate,
                    parseInt(e.target.value)
                  );
                  setTempDate(newDate);
                }}
                className={`form-select block w-20 ${
                  isDarkMode
                    ? "bg-gray-700 text-white"
                    : "bg-white text-gray-900"
                } border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
              >
                {minutes.map((minute) => (
                  <option key={minute} value={minute}>
                    {minute.toString().padStart(2, "0")}
                  </option>
                ))}
              </select>
              <select
                value={tempDate.getHours() >= 12 ? "PM" : "AM"}
                onChange={(e) => {
                  const newHours =
                    e.target.value === "PM"
                      ? (tempDate.getHours() % 12) + 12
                      : tempDate.getHours() % 12;
                  const newDate = setHours(tempDate, newHours);
                  setTempDate(newDate);
                }}
                className={`form-select block w-20 ${
                  isDarkMode
                    ? "bg-gray-700 text-white"
                    : "bg-white text-gray-900"
                } border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
              >
                {periods.map((period) => (
                  <option key={period} value={period}>
                    {period}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-4 flex justify-end">
              <button
                onClick={() => {
                  onChange(tempDate);
                  setShowTimePicker(false);
                  onClose();
                }}
                className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Confirm
              </button>
            </div>
          </div>
        );
      };

      return (
        <Transition
          show={isOpen}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            className={`absolute z-10 mt-1 w-72 rounded-md shadow-lg ${
              isDarkMode ? "bg-gray-800" : "bg-white"
            }`}
          >
            {!showTimePicker ? (
              <div className="p-2">
                {renderHeader()}
                {renderDays()}
                {renderCells(selectedDate, handleDateSelect)}
              </div>
            ) : (
              renderTimePicker()
            )}
            <div className="border-t border-gray-200 p-2 flex justify-end">
              <button
                onClick={onClose}
                className="px-3 py-1 text-sm font-medium text-indigo-600 hover:bg-indigo-50 rounded"
              >
                Cancel
              </button>
            </div>
          </div>
        </Transition>
      );
    };

    const handleStartDateChange = (date) => {
      setEditedEvent((prevEvent) => ({
        ...prevEvent,
        start: date,
        end: date > prevEvent.end ? date : prevEvent.end,
      }));
      setIsStartPickerOpen(false);
    };

    const handleEndDateChange = (date) => {
      if (date >= editedEvent.start) {
        setEditedEvent((prevEvent) => ({
          ...prevEvent,
          end: date,
        }));
      }
      setIsEndPickerOpen(false);
    };

    return (
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 backdrop-blur-md transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-visible rounded-lg ${
                    isDarkMode
                      ? "bg-gray-800 text-white"
                      : "bg-white text-gray-900"
                  } px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6`}
                >
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={onClose}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                      <h3
                        className={`text-2xl font-bold mb-6 ${
                          isDarkMode ? "text-white" : "text-gray-900"
                        }`}
                      >
                        {editedEvent.id ? "Edit Event" : "New Event"}
                      </h3>
                      <div className="space-y-6">
                        <div>
                          <label
                            htmlFor="event-title"
                            className={`block text-sm font-medium mb-2 ${
                              isDarkMode ? "text-gray-300" : "text-gray-700"
                            }`}
                          >
                            Event Title
                          </label>
                          <input
                            type="text"
                            id="event-title"
                            value={editedEvent.title || ""}
                            onChange={(e) =>
                              setEditedEvent({
                                ...editedEvent,
                                title: e.target.value,
                              })
                            }
                            className={`w-full px-4 py-2 rounded-lg border ${
                              isDarkMode
                                ? "bg-gray-800 border-gray-600 text-white"
                                : "bg-white border-gray-300 text-gray-900"
                            } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all`}
                            placeholder="Enter event title"
                          />
                        </div>
                        {/* Date Pickers */}
                        <div className="grid grid-cols-2 gap-6">
                          <div>
                            <label
                              htmlFor="event-start"
                              className={`block text-sm font-medium mb-2 ${
                                isDarkMode ? "text-gray-300" : "text-gray-700"
                              }`}
                            >
                              Start
                            </label>
                            <div className="relative">
                              <CustomInput
                                value={editedEvent.start}
                                onClick={() => setIsStartPickerOpen(true)}
                                isStart={true}
                              />
                              <CustomDatePicker
                                selectedDate={editedEvent.start}
                                onChange={handleStartDateChange}
                                isOpen={isStartPickerOpen}
                                onClose={() => setIsStartPickerOpen(false)}
                              />
                            </div>
                          </div>
                          <div>
                            <label
                              htmlFor="event-end"
                              className={`block text-sm font-medium mb-2 ${
                                isDarkMode ? "text-gray-300" : "text-gray-700"
                              }`}
                            >
                              End
                            </label>
                            <div className="relative">
                              <CustomInput
                                value={editedEvent.end}
                                onClick={() => setIsEndPickerOpen(true)}
                                isStart={false}
                              />
                              <CustomDatePicker
                                selectedDate={editedEvent.end}
                                onChange={handleEndDateChange}
                                isOpen={isEndPickerOpen}
                                onClose={() => setIsEndPickerOpen(false)}
                                minDate={editedEvent.start}
                              />
                            </div>
                          </div>
                        </div>
                        {/* Recurring Event Toggle */}
                        <div className="flex items-center">
                          <label
                            htmlFor="is-recurring"
                            className={`flex items-center cursor-pointer ${
                              isDarkMode ? "text-gray-300" : "text-gray-700"
                            }`}
                          >
                            <div className="relative">
                              <input
                                type="checkbox"
                                id="is-recurring"
                                className="sr-only"
                                checked={editedEvent.isRecurring}
                                onChange={(e) =>
                                  setEditedEvent({
                                    ...editedEvent,
                                    isRecurring: e.target.checked,
                                    recurrence: e.target.checked
                                      ? "daily"
                                      : "none",
                                  })
                                }
                              />
                              <div
                                className={`w-14 h-8 ${
                                  isDarkMode ? "bg-gray-600" : "bg-gray-300"
                                } rounded-full shadow-inner transition-colors duration-300 ease-in-out`}
                              ></div>
                              <div
                                className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transition-transform duration-300 ease-in-out transform ${
                                  editedEvent.isRecurring
                                    ? "translate-x-6"
                                    : "translate-x-0"
                                }`}
                              ></div>
                            </div>
                            <span className="ml-3 text-sm font-medium">
                              Recurring Event
                            </span>
                          </label>
                        </div>
                        {/* Recurrence Settings */}
                        {editedEvent.isRecurring && (
                          <div
                            className={`mt-4 p-4 rounded-lg transition-all duration-300 ease-in-out ${
                              isDarkMode ? "bg-gray-700" : "bg-gray-100"
                            }`}
                          >
                            <h4
                              className={`text-sm font-semibold mb-2 ${
                                isDarkMode ? "text-gray-200" : "text-gray-700"
                              }`}
                            >
                              Recurrence Settings
                            </h4>
                            <p
                              className={`text-xs mb-2 ${
                                isDarkMode ? "text-gray-400" : "text-gray-500"
                              }`}
                            >
                              Choose how often this event should repeat
                            </p>
                            <div className="flex space-x-2">
                              {["daily", "weekly", "monthly", "yearly"].map(
                                (option) => (
                                  <button
                                    key={option}
                                    onClick={() =>
                                      setEditedEvent({
                                        ...editedEvent,
                                        recurrence: option,
                                      })
                                    }
                                    className={`px-3 py-1 text-xs font-medium rounded-full transition-colors duration-200 ${
                                      editedEvent.recurrence === option
                                        ? isDarkMode
                                          ? "bg-blue-600 text-white"
                                          : "bg-blue-100 text-blue-800"
                                        : isDarkMode
                                        ? "bg-gray-600 text-gray-300 hover:bg-gray-500"
                                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                    }`}
                                  >
                                    {option.charAt(0).toUpperCase() +
                                      option.slice(1)}
                                  </button>
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Action Buttons */}
                  <div className="mt-8 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className={`w-full sm:w-auto px-6 py-3 rounded-lg text-white font-medium ${
                        isDarkMode
                          ? "bg-blue-600 hover:bg-blue-700"
                          : "bg-blue-500 hover:bg-blue-600"
                      } transition-colors`}
                      onClick={handleSave}
                    >
                      Save Event
                    </button>
                    <button
                      type="button"
                      className={`mt-3 sm:mt-0 sm:mr-3 w-full sm:w-auto px-6 py-3 rounded-lg font-medium ${
                        isDarkMode
                          ? "bg-red-600 text-white hover:bg-red-700"
                          : "bg-red-100 text-red-800 hover:bg-red-200"
                      } transition-colors`}
                      onClick={handleDelete}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      className={`mt-3 sm:mt-0 sm:mr-3 w-full sm:w-auto px-6 py-3 rounded-lg font-medium ${
                        isDarkMode
                          ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                          : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                      } transition-colors`}
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }

  return (
    <div
      className={`flex h-screen ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      
      <div className="flex-1 flex flex-col p-8">
        <header className="flex justify-between items-center mb-8">
          <div>
            <h1 className="text-3xl font-bold">Calendar</h1>
            <p className="text-sm text-gray-500 mt-1">
              Manage your events efficiently
            </p>
          </div>
          <div className="flex space-x-4">
            <button
              onClick={() => {
                setSelectedEvent(null);
                setNewEvent({
                  title: "",
                  start: new Date(),
                  end: new Date(),
                  isRecurring: false,
                  recurrence: "none",
                });
                setIsNewEventPanelOpen(true);
              }}
              className={`
                px-6 py-3 
                rounded-full
                text-white font-semibold
                transition-transform duration-300 ease-in-out
                transform hover:scale-105
                shadow-md hover:shadow-lg
                ${isDarkMode
                  ? "bg-gradient-to-r from-indigo-500 to-purple-600 hover:from-indigo-600 hover:to-purple-700"
                  : "bg-gradient-to-r from-blue-400 to-indigo-500 hover:from-blue-500 hover:to-indigo-600"
                }
                focus:outline-none focus:ring-2 focus:ring-offset-2 
                ${isDarkMode ? "focus:ring-purple-400" : "focus:ring-blue-300"}
              `}
            >
              <span className="flex items-center justify-center transition-opacity duration-300 ease-in-out hover:opacity-90">
                <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
                Add Event
              </span>
            </button>
          </div>
        </header>
        <div
          className={`flex-1 rounded-2xl shadow-lg overflow-hidden ${
            isDarkMode ? "bg-gray-800" : "bg-white"
          }`}
        >
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: "100%" }}
            className={isDarkMode ? "dark-calendar" : "light-calendar"}
            components={{
              toolbar: CustomToolbar,
            }}
            onSelectEvent={handleSelectEvent}
            key={events.length}
          />
        </div>
      </div>

      <NewEventPanel
        isOpen={isNewEventPanelOpen}
        onClose={() => setIsNewEventPanelOpen(false)}
        onSave={handleAddEvent}
        event={newEvent}
        setEvent={setNewEvent}
        isDarkMode={isDarkMode}
        isLoading={isAddingEvent}
      />

      <EventDetailsModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        event={selectedEvent}
        setEvent={setSelectedEvent}
        onSave={handleUpdateEvent}
        onDelete={handleDeleteEvent}
        isDarkMode={isDarkMode}
      />
    </div>
  );
}

function NewEventPanel({
  isOpen,
  onClose,
  onSave,
  event,
  setEvent,
  isDarkMode,
  isLoading,
}) {
  const [isStartPickerOpen, setIsStartPickerOpen] = useState(false);
  const [isEndPickerOpen, setIsEndPickerOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [error, setError] = useState("");

  const handleSave = async () => {
    if (!event.title.trim()) {
      setError("Event name is required");
      return;
    }
    try {
      await onSave();
      onClose();
    } catch (error) {
      console.error("Error saving event:", error);
      setError("Failed to save event. Please try again.");
    }
  };

  const CustomInput = React.forwardRef(({ value, onClick, isStart }, ref) => (
    <div className="relative">
      <input
        ref={ref}
        className={`w-full py-2 px-3 rounded-md ${
          isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-900"
        } border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500`}
        value={format(value, "MMM d, yyyy h:mm aa")}
        onClick={() => {
          onClick();
          if (isStart) {
            setIsStartPickerOpen(true);
            setIsEndPickerOpen(false);
          } else {
            setIsEndPickerOpen(true);
            setIsStartPickerOpen(false);
          }
        }}
        readOnly
      />
      <CalendarIcon
        className="absolute right-3 top-1/2 transform -translate-y-1/2 w-5 h-5 text-gray-400 cursor-pointer"
        onClick={onClick}
      />
    </div>
  ));

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    return (
      <div className="flex items-center justify-between p-2">
        <button
          onClick={prevMonth}
          className="p-1 rounded-full hover:bg-gray-200"
        >
          <ChevronLeftIcon className="w-5 h-5" />
        </button>
        <span className="text-lg font-bold">
          {format(currentMonth, dateFormat)}
        </span>
        <button
          onClick={nextMonth}
          className="p-1 rounded-full hover:bg-gray-200"
        >
          <ChevronRightIcon className="w-5 h-5" />
        </button>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "EEEEEE";
    const days = [];
    let startDate = startOfWeek(currentMonth);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          key={i}
          className="text-center font-medium text-gray-500 text-xs uppercase"
        >
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    return <div className="grid grid-cols-7 gap-1 mb-2">{days}</div>;
  };

  const renderCells = (selectedDate, onChange) => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const rows = [];
    let days = [];
    let day = startDate;

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        const cloneDay = day;
        const isCurrentMonth = isSameMonth(day, monthStart);
        const isToday = isSameDay(day, new Date());
        const isSelected = isSameDay(day, selectedDate);

        days.push(
          <div
            key={day}
            className={`p-2 text-center cursor-pointer ${
              !isCurrentMonth
                ? isDarkMode
                  ? "text-gray-600 hover:bg-gray-700"
                  : "text-gray-400 hover:bg-gray-100"
                : isSelected
                ? "bg-indigo-600 text-white rounded-full"
                : isDarkMode
                ? "text-white hover:bg-gray-700"
                : "hover:bg-gray-100"
            } ${
              isToday && !isSelected
                ? isDarkMode
                  ? "border border-indigo-400 text-indigo-400"
                  : "border border-indigo-600 text-indigo-600"
                : ""
            } rounded-full`}
            onClick={() => onChange(cloneDay)}
          >
            {format(day, "d")}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div key={day} className="grid grid-cols-7 gap-1 mb-1">
          {days}
        </div>
      );
      days = [];
    }
    return <div className="mb-2">{rows}</div>;
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const CustomDatePicker = ({
    selectedDate,
    onChange,
    isOpen,
    onClose,
    minDate,
  }) => {
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [tempDate, setTempDate] = useState(selectedDate);

    const handleDateSelect = (date) => {
      if (!minDate || date >= minDate) {
        setTempDate(date);
        setShowTimePicker(true);
      }
    };

    const handleTimeSelect = (hours, minutes) => {
      const newDate = setMinutes(setHours(tempDate, hours), minutes);
      onChange(newDate);
      setShowTimePicker(false);
      onClose();
    };

    const renderTimePicker = () => {
      const hours = Array.from({ length: 12 }, (_, i) => i + 1);
      const minutes = [0, 15, 30, 45];
      const periods = ["AM", "PM"];

      return (
        <div
          className={`p-4 ${
            isDarkMode ? "bg-gray-800 text-white" : "bg-white text-gray-900"
          }`}
        >
          <h3 className="text-lg font-semibold mb-4">Select Time</h3>
          <div className="flex items-center space-x-2">
            <select
              value={tempDate.getHours() % 12 || 12}
              onChange={(e) => {
                const newHour = parseInt(e.target.value);
                const newDate = setHours(
                  tempDate,
                  newHour + (tempDate.getHours() >= 12 ? 12 : 0)
                );
                setTempDate(newDate);
              }}
              className={`form-select block w-20 ${
                isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-900"
              } border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
            >
              {hours.map((hour) => (
                <option key={hour} value={hour}>
                  {hour.toString().padStart(2, "0")}
                </option>
              ))}
            </select>
            <span>:</span>
            <select
              value={tempDate.getMinutes()}
              onChange={(e) => {
                const newDate = setMinutes(tempDate, parseInt(e.target.value));
                setTempDate(newDate);
              }}
              className={`form-select block w-20 ${
                isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-900"
              } border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
            >
              {minutes.map((minute) => (
                <option key={minute} value={minute}>
                  {minute.toString().padStart(2, "0")}
                </option>
              ))}
            </select>
            <select
              value={tempDate.getHours() >= 12 ? "PM" : "AM"}
              onChange={(e) => {
                const newHours =
                  e.target.value === "PM"
                    ? (tempDate.getHours() % 12) + 12
                    : tempDate.getHours() % 12;
                const newDate = setHours(tempDate, newHours);
                setTempDate(newDate);
              }}
              className={`form-select block w-20 ${
                isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-900"
              } border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
            >
              {periods.map((period) => (
                <option key={period} value={period}>
                  {period}
                </option>
              ))}
            </select>
          </div>
          <div className="mt-4 flex justify-end">
            <button
              onClick={() => {
                onChange(tempDate);
                setShowTimePicker(false);
                onClose();
              }}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Confirm
            </button>
          </div>
        </div>
      );
    };

    return (
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div
          className={`absolute z-10 mt-1 w-72 rounded-md shadow-lg ${
            isDarkMode ? "bg-gray-800" : "bg-white"
          }`}
        >
          {!showTimePicker ? (
            <div className="p-2">
              {renderHeader()}
              {renderDays()}
              {renderCells(selectedDate, handleDateSelect)}
            </div>
          ) : (
            renderTimePicker()
          )}
          <div className="border-t border-gray-200 p-2 flex justify-end">
            <button
              onClick={onClose}
              className="px-3 py-1 text-sm font-medium text-indigo-600 hover:bg-indigo-50 rounded"
            >
              Cancel
            </button>
          </div>
        </div>
      </Transition>
    );
  };

  const handleStartDateChange = (date) => {
    setEvent((prevEvent) => ({
      ...prevEvent,
      start: date,
      end: date > prevEvent.end ? date : prevEvent.end,
    }));
    setIsStartPickerOpen(false);
  };

  const handleEndDateChange = (date) => {
    if (date >= event.start) {
      setEvent((prevEvent) => ({
        ...prevEvent,
        end: date,
      }));
    }
    setIsEndPickerOpen(false);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 backdrop-blur-md transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative transform overflow-visible rounded-lg ${
                  isDarkMode
                    ? "bg-gray-800/90 backdrop-blur-sm"
                    : "bg-white/90 backdrop-blur-sm"
                } px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6`}
              >
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className={`rounded-md ${
                      isDarkMode
                        ? "bg-gray-800 text-gray-400 hover:text-gray-300"
                        : "bg-white text-gray-400 hover:text-gray-500"
                    } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <h3
                      className={`text-2xl font-bold mb-6 ${
                        isDarkMode ? "text-white" : "text-gray-900"
                      }`}
                    >
                      {event.id ? "Edit Event" : "New Event"}
                    </h3>
                    <div className="space-y-6">
                      <div>
                        <label
                          htmlFor="event-title"
                          className={`block text-sm font-medium mb-2 ${
                            isDarkMode ? "text-gray-300" : "text-gray-700"
                          }`}
                        >
                          Event Title
                        </label>
                        <input
                          type="text"
                          id="event-title"
                          value={event.title}
                          onChange={(e) => {
                            setEvent({ ...event, title: e.target.value });
                            setError("");
                          }}
                          className={`w-full px-4 py-2 rounded-lg border ${
                            isDarkMode
                              ? "bg-gray-800 border-gray-600 text-white"
                              : "bg-white border-gray-300 text-gray-900"
                          } focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all ${
                            error ? "border-red-500" : ""
                          }`}
                          placeholder="Enter event title"
                        />
                        {error && (
                          <p className="mt-2 text-sm text-red-600">{error}</p>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-6">
                        <div>
                          <label
                            htmlFor="event-start"
                            className={`block text-sm font-medium mb-2 ${
                              isDarkMode ? "text-gray-300" : "text-gray-700"
                            }`}
                          >
                            Start
                          </label>
                          <div className="relative">
                            <CustomInput
                              value={event.start}
                              onClick={() => setIsStartPickerOpen(true)}
                              isStart={true}
                            />
                            <CustomDatePicker
                              selectedDate={event.start}
                              onChange={handleStartDateChange}
                              isOpen={isStartPickerOpen}
                              onClose={() => setIsStartPickerOpen(false)}
                            />
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="event-end"
                            className={`block text-sm font-medium mb-2 ${
                              isDarkMode ? "text-gray-300" : "text-gray-700"
                            }`}
                          >
                            End
                          </label>
                          <div className="relative">
                            <CustomInput
                              value={event.end}
                              onClick={() => setIsEndPickerOpen(true)}
                              isStart={false}
                            />
                            <CustomDatePicker
                              selectedDate={event.end}
                              onChange={handleEndDateChange}
                              isOpen={isEndPickerOpen}
                              onClose={() => setIsEndPickerOpen(false)}
                              minDate={event.start}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="is-recurring"
                          className={`flex items-center cursor-pointer ${
                            isDarkMode ? "text-gray-300" : "text-gray-700"
                          }`}
                        >
                          <div className="relative">
                            <input
                              type="checkbox"
                              id="is-recurring"
                              className="sr-only"
                              checked={event.isRecurring}
                              onChange={(e) =>
                                setEvent({
                                  ...event,
                                  isRecurring: e.target.checked,
                                  recurrence: e.target.checked
                                    ? "daily"
                                    : "none",
                                })
                              }
                            />
                            <div
                              className={`w-14 h-8 ${
                                isDarkMode ? "bg-gray-600" : "bg-gray-300"
                              } rounded-full shadow-inner transition-colors duration-300 ease-in-out`}
                            ></div>
                            <div
                              className={`absolute left-1 top-1 w-6 h-6 bg-white rounded-full shadow transition-transform duration-300 ease-in-out transform ${
                                event.isRecurring
                                  ? "translate-x-6"
                                  : "translate-x-0"
                              }`}
                            ></div>
                          </div>
                          <span className="ml-3 text-sm font-medium">
                            Recurring Event
                          </span>
                        </label>
                      </div>
                      {event.isRecurring && (
                        <div
                          className="mt-4 p-4 bg-opacity-50 rounded-lg transition-all duration-300 ease-in-out"
                          style={{
                            backgroundColor: isDarkMode
                              ? "rgba(55, 65, 81, 0.5)"
                              : "rgba(243, 244, 246, 0.5)",
                          }}
                        >
                          <h4
                            className={`text-sm font-semibold mb-2 ${
                              isDarkMode ? "text-gray-200" : "text-gray-700"
                            }`}
                          >
                            Recurrence Settings
                          </h4>
                          <p
                            className={`text-xs mb-2 ${
                              isDarkMode ? "text-gray-400" : "text-gray-500"
                            }`}
                          >
                            Choose how often this event should repeat
                          </p>
                          <div className="flex space-x-2">
                            {["daily", "weekly", "monthly", "yearly"].map(
                              (option) => (
                                <button
                                  key={option}
                                  onClick={() =>
                                    setEvent({ ...event, recurrence: option })
                                  }
                                  className={`px-3 py-1 text-xs font-medium rounded-full transition-colors duration-200 ${
                                    event.recurrence === option
                                      ? isDarkMode
                                        ? "bg-indigo-600 text-white"
                                        : "bg-indigo-100 text-indigo-800"
                                      : isDarkMode
                                      ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                                  }`}
                                >
                                  {option.charAt(0).toUpperCase() +
                                    option.slice(1)}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-8 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={`w-full sm:w-auto px-6 py-3 rounded-lg text-white font-medium ${
                      isDarkMode
                        ? "bg-blue-600 hover:bg-blue-700"
                        : "bg-blue-500 hover:bg-blue-600"
                    } transition-colors ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={handleSave}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span className="flex items-center justify-center">
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        Saving...
                      </span>
                    ) : (
                      "Save Event"
                    )}
                  </button>
                  <button
                    type="button"
                    className={`mt-3 sm:mt-0 sm:mr-3 w-full sm:w-auto px-6 py-3 rounded-lg font-medium ${
                      isDarkMode
                        ? "bg-gray-700 text-gray-300 hover:bg-gray-600"
                        : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                    } transition-colors ${
                      isLoading ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={onClose}
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                </div>
                {isLoading && (
                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                    <svg
                      className="animate-spin h-10 w-10 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AppCalendar;
export { NewEventPanel };
