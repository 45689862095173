import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Card from "./Card";
import AddCard from "./AddCard";

function List({
  list,
  index,
  boardId,
  isTrelloBoard,
  isDarkMode,
  onCardAdded,
}) {

  return (
    <Draggable draggableId={list.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`m-3 p-5 w-80 rounded-xl shadow-lg transition-all duration-300 ${
            isDarkMode ? "bg-gray-800 text-gray-100" : "bg-white text-gray-800"
          }`}
        >
          <h3
            {...provided.dragHandleProps}
            className="font-semibold mb-4 text-xl tracking-wide flex items-center justify-between"
          >
            <span>{list.name}</span>
            <span className="text-sm font-normal text-gray-500">
              {list.cards ? list.cards.length : 0} cards
            </span>
          </h3>
          <Droppable droppableId={list.id} type="CARD">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="space-y-3 mb-4"
              >
                {list.cards && list.cards.length > 0 ? (
                  list.cards.map((card, cardIndex) => (
                    <Card
                      key={card.id}
                      card={card}
                      index={cardIndex}
                      isDarkMode={isDarkMode}
                    />
                  ))
                ) : (
                  <p className="text-gray-500 text-sm">No cards in this list</p>
                )}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <AddCard
            listId={list.id}
            boardId={boardId}
            isTrelloBoard={isTrelloBoard}
            isDarkMode={isDarkMode}
            onCardAdded={onCardAdded}
          />
        </div>
      )}
    </Draggable>
  );
}

export default List;