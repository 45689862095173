import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../firebase/config";
import {
  doc,
  collection,
  query,
  getDocs,
  writeBatch,
} from "firebase/firestore";

const functions = getFunctions();

export async function syncGoogleDriveFiles(userId, folderId = "root") {
  try {
    const listGoogleDriveFiles = httpsCallable(
      functions,
      "listGoogleDriveFiles"
    );
    const result = await listGoogleDriveFiles({ folderId });
    const files = result.data.files;

    const filesRef = collection(db, `users/${userId}/googleDriveFiles`);
    const batch = writeBatch(db);

    // Delete existing files
    const existingFiles = await getDocs(query(filesRef));
    existingFiles.forEach((doc) => {
      batch.delete(doc.ref);
    });

    // Add new files
    files.forEach((file) => {
      const fileDoc = doc(filesRef, file.id);
      batch.set(fileDoc, file);
    });

    await batch.commit();

    console.log(`Synced ${files.length} Google Drive files`);
    return files;
  } catch (error) {
    console.error("Error syncing Google Drive files:", error);
    if (
      error.code === "failed-precondition" &&
      error.message.includes("Google Drive is not connected")
    ) {
      throw new Error(
        "Google Drive is not connected. Please connect your account in the settings."
      );
    }
    throw new Error(`Failed to sync Google Drive files: ${error.message}`);
  }
}

export async function getGoogleDriveFiles(userId) {
  const filesRef = collection(db, `users/${userId}/googleDriveFiles`);
  const snapshot = await getDocs(query(filesRef));
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
}
