import { Fragment, useState, useEffect, useContext } from "react";
import { DarkModeContext } from "../context/DarkModeContext";
import { UserContext } from "../context/UserContext";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";
import {
  collection,
  query,
  where,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  Timestamp,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase/config";
import {
  ClipboardIcon,
  PlusIcon,
  PencilIcon,
  TrashIcon,
  CalendarIcon,
  ClockIcon,
} from "@heroicons/react/outline";

const MyDayContent = () => {
  const [todos, setTodos] = useState([]);
  const [newTodo, setNewTodo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (userData && userData.uid) {
      fetchMyDayTodos();
    }
  }, [userData]);

  const fetchMyDayTodos = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);

      const todosRef = collection(db, `users/${userData.uid}/todos`);
      const q = query(
        todosRef,
        where("list", "==", "myDay"),
        where("createdAt", ">=", today),
        where("createdAt", "<", tomorrow),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      const fetchedTodos = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTodos(fetchedTodos);
    } catch (err) {
      console.error("Error fetching todos:", err);
      setError("Failed to load todos. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const addTodo = async (e) => {
    e.preventDefault();
    if (!newTodo.trim()) return;

    try {
      const todosRef = collection(db, `users/${userData.uid}/todos`);
      const newTodoDoc = await addDoc(todosRef, {
        title: newTodo,
        completed: false,
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        list: "myDay",
        priority: "medium",
      });
      setTodos((prevTodos) => [
        {
          id: newTodoDoc.id,
          title: newTodo,
          completed: false,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
          list: "myDay",
          priority: "medium",
        },
        ...prevTodos,
      ]);
      setNewTodo("");
    } catch (err) {
      console.error("Error adding todo:", err);
      setError("Failed to add todo. Please try again.");
    }
  };

  const toggleTodo = async (id, completed) => {
    try {
      const todoRef = doc(db, `users/${userData.uid}/todos`, id);
      await updateDoc(todoRef, {
        completed: !completed,
        updatedAt: Timestamp.now(),
      });
      setTodos((prevTodos) =>
        prevTodos.map((todo) =>
          todo.id === id ? { ...todo, completed: !completed } : todo
        )
      );
    } catch (err) {
      console.error("Error updating todo:", err);
      setError("Failed to update todo. Please try again.");
    }
  };

  const deleteTodo = async (id) => {
    try {
      await deleteDoc(doc(db, `users/${userData.uid}/todos`, id));
      setTodos((prevTodos) => prevTodos.filter((todo) => todo.id !== id));
    } catch (err) {
      console.error("Error deleting todo:", err);
      setError("Failed to delete todo. Please try again.");
    }
  };

  const clearCompletedTodos = async () => {
    const batch = writeBatch(db);
    const completedTodos = todos.filter((todo) => todo.completed);

    completedTodos.forEach((todo) => {
      const todoRef = doc(db, `users/${userData.uid}/todos`, todo.id);
      batch.delete(todoRef);
    });

    try {
      await batch.commit();
      setTodos((prevTodos) => prevTodos.filter((todo) => !todo.completed));
    } catch (err) {
      console.error("Error clearing completed todos:", err);
      setError("Failed to clear completed todos. Please try again.");
    }
  };

  return (
    <div className="flex-1 p-6 max-w-5xl mx-auto">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-3xl font-bold">My Day</h1>
        <div className="flex items-center">
          <CalendarIcon className="w-5 h-5 mr-2 text-gray-500" />
          <p
            className={`text-sm font-medium ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            {new Date().toLocaleDateString("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
            })}
          </p>
          <span className="mx-2 text-gray-400">•</span>
          <ClockIcon className="w-5 h-5 mr-2 text-gray-500" />
          <p
            className={`text-sm font-medium ${
              isDarkMode ? "text-gray-300" : "text-gray-600"
            }`}
          >
            {new Date().toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
            })}
          </p>
        </div>
      </div>

      <form onSubmit={addTodo} className="mb-8">
        <div className="relative">
          <input
            type="text"
            value={newTodo}
            onChange={(e) => setNewTodo(e.target.value)}
            placeholder="Add a task"
            className={`w-full p-4 pr-12 rounded-lg ${
              isDarkMode ? "bg-gray-700 text-white" : "bg-white text-gray-800"
            } border ${
              isDarkMode ? "border-gray-600" : "border-gray-300"
            } focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm`}
          />
          <button
            type="submit"
            className="absolute right-2 top-1/2 transform -translate-y-1/2 text-blue-500 hover:text-blue-600"
          >
            <PlusIcon className="w-6 h-6" />
          </button>
        </div>
      </form>

      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="text-red-500 text-center p-4 bg-red-100 rounded-lg">
          {error}
        </div>
      ) : (
        <div className="space-y-4">
          <AnimatePresence>
            {todos.map((todo) => (
              <motion.div
                key={todo.id}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                transition={{ duration: 0.2 }}
                className={`flex items-center p-4 rounded-lg ${
                  isDarkMode ? "bg-gray-800" : "bg-white"
                } shadow-sm hover:shadow-md transition-all duration-200`}
              >
                <input
                  type="checkbox"
                  checked={todo.completed}
                  onChange={() => toggleTodo(todo.id, todo.completed)}
                  className="form-checkbox h-5 w-5 text-blue-600 rounded focus:ring-blue-500 mr-4"
                />
                <span
                  className={`flex-grow text-lg ${
                    todo.completed ? "line-through text-gray-500" : ""
                  }`}
                >
                  {todo.title}
                </span>
                <div className="flex items-center space-x-2">
                  <button
                    onClick={() => /* Edit todo function */ {}}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                  >
                    <PencilIcon className="w-5 h-5" />
                  </button>
                  <button
                    onClick={() => deleteTodo(todo.id)}
                    className="text-gray-400 hover:text-gray-600 focus:outline-none"
                  >
                    <TrashIcon className="w-5 h-5" />
                  </button>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      )}

      {todos.length > 0 && (
        <div className="mt-8 flex justify-between items-center border-t pt-4">
          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <svg
                className="w-5 h-5 text-blue-500 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                ></path>
              </svg>
              <p
                className={`text-sm font-medium ${
                  isDarkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                {todos.filter((t) => !t.completed).length} tasks remaining
              </p>
            </div>
          </div>
          <button
            onClick={clearCompletedTodos}
            className={`px-4 py-2 rounded-md ${
              isDarkMode
                ? "bg-gray-700 text-white hover:bg-gray-600"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"
            } transition-colors duration-200 text-sm font-medium flex items-center`}
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
            Clear completed
          </button>
        </div>
      )}

      {todos.length === 0 && !isLoading && (
        <div className="text-center text-gray-500 mt-12">
          <ClipboardIcon className="w-16 h-16 mx-auto mb-4 text-gray-400" />
          <p className="text-xl font-medium mb-2">No tasks for today</p>
          <p className="text-sm">Add some tasks to get started!</p>
        </div>
      )}
    </div>
  );
};

export default MyDayContent;
