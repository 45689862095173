import React, { useEffect, useState, useCallback, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { db, auth } from "../firebase/config";
import { doc, setDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { DarkModeContext } from "../context/DarkModeContext";
import { getFunctions, httpsCallable } from "firebase/functions";

const SessionData = {
  customerId: "",
  subscriptionId: "",
  planId: "",
  billingInterval: "",
  subscriptionStatus: "",
};

function SubscriptionSuccessContent() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { isDarkMode, toggleDarkMode } = useContext(DarkModeContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sid = searchParams.get("session_id");
    setSessionId(sid);
  }, [location]);

  const handleSubscription = useCallback(async (user, sessionId) => {
    try {
      const functions = getFunctions();
      const retrieveSessionFunction = httpsCallable(
        functions,
        "retrieveSessionCallable"
      );
      const result = await retrieveSessionFunction({ session_id: sessionId });
      const sessionData = result.data;

      if (!sessionData) {
        throw new Error("No session data found");
      }

      // Update user document in Firestore
      const userDoc = doc(db, "users", user.uid);
      await setDoc(
        userDoc,
        {
          customerId: sessionData.customerId,
          subscriptionId: sessionData.subscriptionId,
          planId: sessionData.planId,
          billingInterval: sessionData.billingInterval,
          subscriptionStatus: sessionData.subscriptionStatus,
        },
        { merge: true }
      );

      setLoading(false);
    } catch (error) {
      console.error("Error handling subscription:", error);
      setError(
        "An error occurred while processing your subscription. Please contact support."
      );
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (sessionId === null) return; // Wait until sessionId is set

    if (!sessionId) {
      setError("No session ID found");
      setLoading(false);
      return;
    }

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        handleSubscription(user, sessionId);
      } else {
        setError("No authenticated user found. Please log in and try again.");
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [sessionId, handleSubscription]);

  return (
    <main
      className={`min-h-screen flex flex-col transition-colors duration-300 ${
        isDarkMode ? "dark bg-[#1a1a1a] text-white" : "bg-white text-gray-800"
      }`}
    >
      <div
        className={`flex-grow flex items-center justify-center px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
          isDarkMode
            ? "bg-gradient-to-b from-gray-900 via-gray-850 to-gray-800"
            : "bg-gradient-to-b from-indigo-50 via-white to-indigo-100"
        }`}
      >
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl p-8 max-w-md w-full">
          {loading ? (
            <div className="text-center">
              <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-500 mx-auto mb-4"></div>
              <p className="text-gray-600 dark:text-gray-300">
                Processing your subscription...
              </p>
            </div>
          ) : error ? (
            <div className="text-center">
              <svg
                className="w-12 h-12 text-red-500 mx-auto mb-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-100 mb-2">
                Oops!
              </h2>
              <p className="text-gray-600 dark:text-gray-300 mb-4">{error}</p>
              <button
                onClick={() => navigate("/")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 px-4 rounded-lg transition duration-300 ease-in-out"
              >
                Return Home
              </button>
            </div>
          ) : (
            <div className="text-center">
              <svg
                className="w-16 h-16 text-green-500 mx-auto mb-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-100 mb-2">
                Subscription Successful!
              </h1>
              <p className="text-gray-600 dark:text-gray-300 mb-6">
                Thank you for subscribing. Your account has been upgraded.
              </p>
              <button
                onClick={() => navigate("/app")}
                className="bg-indigo-500 hover:bg-indigo-600 text-white font-semibold py-2 px-6 rounded-lg transition duration-300 ease-in-out"
              >
                Go to Boxia
              </button>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default function SubscriptionSuccess() {
  return <SubscriptionSuccessContent />;
}
