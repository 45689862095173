import React, { useEffect } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";

function TrelloCallback() {
  useEffect(() => {
    const handleCallback = async () => {
      const token = new URLSearchParams(window.location.hash.slice(1)).get(
        "token"
      );

      if (token) {
        try {
          const functions = getFunctions();
          const saveTrelloToken = httpsCallable(functions, "saveTrelloToken");
          await saveTrelloToken({ token });
          window.opener.postMessage(
            { type: "TRELLO_AUTH_SUCCESS", token },
            window.location.origin
          );
        } catch (error) {
          console.error("Error saving Trello token:", error);
          window.opener.postMessage(
            { type: "TRELLO_AUTH_ERROR", error: error.message },
            window.location.origin
          );
        } finally {
          window.close();
        }
      } else {
        window.opener.postMessage(
          { type: "TRELLO_AUTH_ERROR", error: "No token received" },
          window.location.origin
        );
        window.close();
      }
    };

    handleCallback();
  }, []);

  return <div>Processing Trello authorization...</div>;
}

export default TrelloCallback;
