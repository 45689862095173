import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, GithubAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCVmYgpEylTjwrtKO-2ts3zJ8kk8EWmUk0",
  authDomain: "boxia-app-platform-dffc5.firebaseapp.com",
  projectId: "boxia-app-platform-dffc5",
  storageBucket: "gs://boxia-app-platform-dffc5.appspot.com",
  messagingSenderId: "398390208443",
  appId: "1:398390208443:web:da5f3fe64b6ec876be869e",
  measurementId: "G-SN7FYXJQTM",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const githubProvider = new GithubAuthProvider();
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export { app, auth, db, functions, storage, googleProvider, githubProvider };