import React, { useState, useContext } from "react";
import { UserContext } from "../context/UserContext";
import { db } from "../firebase/config";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { createTrelloCard } from "../utils/trelloSync";
import { PlusIcon, XIcon } from "@heroicons/react/solid";

function AddCard({ listId, boardId, isTrelloBoard, isDarkMode, onCardAdded }) {
  const { userData } = useContext(UserContext);
  const [isAdding, setIsAdding] = useState(false);
  const [cardName, setCardName] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cardName.trim()) return;

    try {
      const cardData = {
        name: cardName.trim(),
        description: "",
        createdAt: serverTimestamp(),
        position: Date.now(),
      };

      if (isTrelloBoard) {
        // Optimistically add the card with a temporary ID and loading state
        const tempCard = {
          id: `temp-${Date.now()}`,
          ...cardData,
          isLoading: true,
        };
        onCardAdded(listId, tempCard);

        // Create the card in Trello
        const newCard = await createTrelloCard(
          userData.uid,
          boardId,
          listId,
          cardData
        );

        // Update the card with the real data and set isLoading to false
        onCardAdded(listId, { ...newCard, isLoading: false, isComplete: true });

        // After 2 seconds, remove the isComplete flag
        setTimeout(() => {
          onCardAdded(listId, { ...newCard, isComplete: false });
        }, 2000);
      } else {
        const cardsRef = collection(
          db,
          `users/${userData.uid}/boxiaBoards/${boardId}/lists/${listId}/cards`
        );
        const docRef = await addDoc(cardsRef, cardData);
        const newCard = { id: docRef.id, ...cardData };
        onCardAdded(listId, newCard);
      }

      setCardName("");
      setIsAdding(false);
    } catch (error) {
      console.error("Error adding card:", error);
      setError("Failed to add card. Please try again.");
    }
  };

  if (!isAdding) {
    return (
      <button
        onClick={() => setIsAdding(true)}
        className={`w-full text-left p-3 rounded-lg flex items-center transition-all duration-300 ${
          isDarkMode
            ? "text-gray-300 hover:bg-gray-700"
            : "text-gray-600 hover:bg-gray-100"
        }`}
      >
        <PlusIcon className="h-5 w-5 mr-2" />
        <span className="font-medium">Add a card</span>
      </button>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="mt-3">
      <div className="relative">
        <input
          type="text"
          value={cardName}
          onChange={(e) => setCardName(e.target.value)}
          placeholder="Enter card title"
          className={`w-full p-3 rounded-lg pr-10 transition-all duration-300 ${
            isDarkMode
              ? "bg-gray-700 text-white placeholder-gray-400"
              : "bg-white text-gray-800 placeholder-gray-400"
          } focus:outline-none focus:ring-2 focus:ring-blue-500`}
          autoFocus
        />
        <button
          type="button"
          onClick={() => setIsAdding(false)}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
        >
          <XIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="flex justify-end mt-2">
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300 flex items-center"
        >
          <PlusIcon className="h-5 w-5 mr-1" />
          Add Card
        </button>
      </div>
    </form>
  );
}

export default AddCard;