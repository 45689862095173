import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
// import dashboardPreview from "../assets/images/boxia-dashboard-preview.png";
import googleLogo from "../assets/images/logos/google.svg";
import microsoftLogo from "../assets/images/logos/microsoft.svg";
import jiraLogo from "../assets/images/logos/jira.svg";
import trelloLogo from "../assets/images/logos/trello.svg";
import slackLogo from "../assets/images/logos/slack.svg";
import githubLogo from "../assets/images/logos/github.svg";
import dropboxLogo from "../assets/images/logos/dropbox.svg";
import salesforceLogo from "../assets/images/logos/salesforce.svg";
import asanaLogo from "../assets/images/logos/asana.svg";
import evernoteLogo from "../assets/images/logos/evernote.svg";
import zapierLogo from "../assets/images/logos/zapier.svg";
import hubspotLogo from "../assets/images/logos/hubspot.svg";
import openaiLogo from "../assets/images/logos/openai-lockup.svg";
import microsoftBig from "../assets/images/logos/Microsoft_logo_(2012).svg";
import amazonLogo from "../assets/images/logos/Amazon_logo.svg";
import metaLogo from "../assets/images/logos/Meta.svg";
import googleBig from "../assets/images/logos/googlebig.svg";
import githubBig from "../assets/images/logos/githubbig.svg";

import BoxiaHome from "../assets/images/Boxia Home.png";

import { BentoCard, BentoGrid } from "../@/components/magicui/bento-grid";
import { CalendarIcon, FileTextIcon } from "@radix-ui/react-icons";
import {
  CheckSquareIcon,
  FileIcon,
  BrainIcon,
  LinkIcon,
  LockIcon,
  TrophyIcon,
  MessageSquareIcon,
} from "lucide-react";

import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import { getApp } from "firebase/app";

import { motion } from "framer-motion";

const FAQItem = ({ question, answer, darkMode }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 dark:border-gray-700">
      <button
        className="flex justify-between items-center w-full py-4 text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`font-semibold ${
            darkMode ? "text-gray-200" : "text-gray-800"
          }`}
        >
          {question}
        </span>
        <svg
          className={`w-6 h-6 transition-transform duration-300 ${
            isOpen ? "transform rotate-180" : ""
          } ${darkMode ? "text-gray-400" : "text-gray-600"}`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <div
        className={`overflow-hidden transition-all duration-300 ease-in-out ${
          isOpen ? "max-h-96 opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <div className={`pb-4 ${darkMode ? "text-gray-300" : "text-gray-700"}`}>
          <p>{answer}</p>
        </div>
      </div>
    </div>
  );
};

// const WaitlistForm = ({ darkMode }) => {
//   const [name, setName] = useState("");
//   const [email, setEmail] = useState("");
//   const [status, setStatus] = useState(""); // For user feedback

//   useEffect(() => {
//     try {
//       const app = getApp();
//       console.log("Firebase app initialized successfully:", app.name);
//     } catch (error) {
//       console.error("Firebase app initialization error:", error);
//     }
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setStatus("submitting");

//     try {
//       console.log("Attempting to add document to waitlist collection");
//       console.log("Name:", name);
//       console.log("Email:", email);

//       const docRef = await addDoc(collection(db, "waitlist"), {
//         name,
//         email,
//         timestamp: new Date(),
//       });

//       console.log("Document written with ID: ", docRef.id);
//       setStatus("success");
//       setName("");
//       setEmail("");
//     } catch (error) {
//       console.error("Error adding document: ", error);
//       console.log("Error code:", error.code);
//       console.log("Error message:", error.message);
//       console.log("Error details:", error.details);
//       setStatus("error");
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit} className="w-full max-w-md">
//       <div
//         className={`p-4 rounded-lg ${
//           darkMode ? "bg-gray-800" : "bg-white"
//         } shadow-lg`}
//       >
//         <input
//           className={`appearance-none bg-transparent border-b-2 w-full mb-4 py-2 px-3 leading-tight focus:outline-none ${
//             darkMode
//               ? "text-gray-300 border-gray-600 focus:border-indigo-500"
//               : "text-gray-700 border-gray-300 focus:border-indigo-600"
//           }`}
//           type="text"
//           placeholder="Your Name"
//           value={name}
//           onChange={(e) => setName(e.target.value)}
//           required
//           disabled={status === "submitting"}
//         />
//         <input
//           className={`appearance-none bg-transparent border-b-2 w-full mb-6 py-2 px-3 leading-tight focus:outline-none ${
//             darkMode
//               ? "text-gray-300 border-gray-600 focus:border-indigo-500"
//               : "text-gray-700 border-gray-300 focus:border-indigo-600"
//           }`}
//           type="email"
//           placeholder="Your Email"
//           value={email}
//           onChange={(e) => setEmail(e.target.value)}
//           required
//           disabled={status === "submitting"}
//         />
//         <button
//           className={`w-full py-2 px-4 rounded-full font-medium text-white bg-indigo-600 hover:bg-indigo-700 transition-colors duration-200 ${
//             status === "submitting" ? "opacity-50 cursor-not-allowed" : ""
//           }`}
//           type="submit"
//           disabled={status === "submitting"}
//         >
//           {status === "submitting" ? "Joining..." : "Join Waitlist"}
//         </button>
//         {status === "success" && (
//           <p
//             className={`mt-2 text-sm ${
//               darkMode ? "text-green-400" : "text-green-600"
//             }`}
//           >
//             Successfully joined the waitlist!
//           </p>
//         )}
//         {status === "error" && (
//           <p
//             className={`mt-2 text-sm ${
//               darkMode ? "text-red-400" : "text-red-600"
//             }`}
//           >
//             An error occurred. Please try again later.
//           </p>
//         )}
//       </div>
//     </form>
//   );
// };

const LandingPage = () => {
  const [darkMode, setDarkMode] = useState(false); // Default to light mode
  const [logoFade, setLogoFade] = useState(false);
  const [annualBilling, setAnnualBilling] = useState(true);

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);

  const handleDarkModeToggle = () => {
    setLogoFade(true);
    setTimeout(() => {
      setDarkMode(!darkMode);
      setLogoFade(false);
    }, 150);
  };

  const integrations = [
    { name: "Google", logo: googleLogo },
    { name: "Microsoft", logo: microsoftLogo },
    { name: "Jira", logo: jiraLogo },
    { name: "Trello", logo: trelloLogo },
    { name: "Slack", logo: slackLogo },
    { name: "GitHub", logo: githubLogo },
    { name: "Dropbox", logo: dropboxLogo },
    { name: "Salesforce", logo: salesforceLogo },
    { name: "Asana", logo: asanaLogo },
    { name: "Evernote", logo: evernoteLogo },
    { name: "Zapier", logo: zapierLogo },
    { name: "HubSpot", logo: hubspotLogo },
  ];

  const waitlistFormUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSdKV5mQfOP17K0RlAyGUtCgLmjLzHINjIvGKshTEOvHbmmEZg/viewform";

  return (
    <div className="flex flex-col">
      <main
        className={`flex-grow overflow-y-auto transition-colors duration-300 ${
          darkMode ? "dark bg-[#1a1a1a] text-white" : "bg-white text-gray-800"
        }`}
      >
        <NavBar darkMode={darkMode} logoFade={logoFade} />

        {/* Hero Section */}
        <section
          className={`pt-[200px] py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900"
              : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100"
          }`}
        >
          <div className="max-w-7xl mx-auto flex flex-col lg:flex-row items-center justify-between">
            <div className="lg:w-1/2 text-left lg:pr-12 mb-12 lg:mb-0">
              <h1
                className={`text-5xl sm:text-6xl md:text-7xl font-bold mb-6 leading-tight ${
                  darkMode ? "text-white" : "text-gray-900"
                }`}
              >
                Supercharge Your{" "}
                <span
                  className={`bg-clip-text text-transparent ${
                    darkMode
                      ? "bg-gradient-to-br from-blue-500 to-purple-600"
                      : "bg-gradient-to-br from-teal-400 to-indigo-500"
                  }`}
                >
                  Productivity
                </span>
              </h1>
              <p
                className={`text-xl sm:text-2xl mb-10 max-w-2xl ${
                  darkMode ? "text-gray-300" : "text-gray-700"
                }`}
              >
                Experience the future of work with Boxia. Seamlessly manage
                tasks, notes, files, and more in one simple platform.
              </p>
              <div className="flex flex-col sm:flex-row items-center gap-4">
                <div className="flex flex-col items-center">
                  <div className="flex items-center">
                    <a
                      href={waitlistFormUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`w-full sm:w-auto px-8 py-4 text-lg font-semibold rounded-full transition-all duration-300 ease-in-out ${
                        darkMode
                          ? "bg-indigo-600 text-white hover:bg-indigo-700"
                          : "bg-indigo-500 text-white hover:bg-indigo-600"
                      }`}
                    >
                      Join Waitlist
                    </a>
                  </div>
                  <span
                    className={`mt-2 text-sm ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    2000+ joined
                  </span>
                </div>
                <a
                  href="/features"
                  className={`w-full sm:w-auto px-8 py-4 text-lg font-semibold rounded-full transition-all duration-300 ease-in-out transform -translate-y-[15px] ${
                    darkMode
                      ? "bg-indigo-800 text-white hover:bg-indigo-700"
                      : "bg-indigo-200 text-indigo-800 hover:bg-indigo-300"
                  }`}
                >
                  Explore Features
                </a>
              </div>
            </div>

            <div className="lg:w-1/2 mt-12 lg:mt-0">
              <div
                className={`rounded-3xl shadow-2xl overflow-hidden ${
                  darkMode ? "bg-gray-800" : "bg-white"
                }`}
              >
                <img
                  src={BoxiaHome}
                  alt="Boxia Dashboard Preview"
                  className="w-full h-auto object-contain opacity-90 hover:opacity-100"
                />
              </div>
            </div>
          </div>

          {/* <div className="flex justify-center mt-8">
            <div className="relative">
              <a
                href="/blog/boxia-launch"
                className={`inline-flex items-center px-4 py-2 text-sm font-medium rounded-full transition-all duration-300 ease-in-out ${
                  darkMode
                    ? "bg-gray-800 text-white hover:bg-gray-700"
                    : "bg-white text-gray-800 hover:bg-gray-100"
                }`}
              >
                <span>Boxia Launch</span>
                <span
                  className={`ml-2 ${
                    darkMode ? "text-gray-400" : "text-gray-600"
                  }`}
                >
                  Read more
                  <svg
                    className="inline-block ml-1 w-4 h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 16 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M9.75 4.75 13.25 8m0 0-3.5 3.25M13.25 8H2.75"
                    ></path>
                  </svg>
                </span>
              </a>
            </div>
          </div> */}

          {/* Built by Engineers Section */}
          <div className="max-w-7xl mx-auto text-center mt-10 -mb-10">
            <h2
              className={`text-4xl font-bold mb-10 ${
                darkMode ? "text-gray-100" : "text-gray-900"
              } transition-colors duration-300`}
            >
              Built by Engineers from
            </h2>
            <div className="flex flex-wrap justify-center items-center gap-8 sm:gap-12">
              {[
                { name: "OpenAI", logo: openaiLogo },
                { name: "Microsoft", logo: microsoftBig },
                { name: "Google", logo: googleBig },
                { name: "Amazon", logo: amazonLogo },
                { name: "Meta", logo: metaLogo },
                { name: "GitHub", logo: githubBig },
              ].map((company) => (
                <div
                  key={company.name}
                  className="flex items-center justify-center w-32 h-16"
                >
                  <img
                    src={company.logo}
                    alt={`${company.name} logo`}
                    className={`w-full h-full object-contain transition-all duration-300 ${
                      darkMode
                        ? "invert brightness-0 contrast-100 opacity-70 hover:opacity-100"
                        : "opacity-70 hover:opacity-100"
                    }`}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Key Features Showcase */}
        <section
          className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-b from-gray-900 via-gray-850 to-gray-800"
              : "bg-gradient-to-b from-indigo-50 via-white to-indigo-100"
          }`}
        >
          <div className="max-w-7xl mx-auto">
            <h2
              className={`text-4xl md:text-5xl font-bold text-center mb-4 transition-opacity duration-300 ${
                logoFade ? "opacity-0" : "opacity-100"
              } ${darkMode ? "text-indigo-200" : "text-indigo-900"}`}
            >
              Your All-in-One Productivity Powerhouse
            </h2>
            <p
              className={`text-xl text-center mb-16 max-w-3xl mx-auto transition-opacity duration-300 ${
                logoFade ? "opacity-0" : "opacity-100"
              } ${darkMode ? "text-indigo-300" : "text-indigo-700"}`}
            >
              Discover how Boxia can transform your workflow and boost your
              efficiency
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: "📋",
                  title: "Task Management",
                  description:
                    "Create, organize, and prioritize to-do lists with ease",
                  subFeatures: [
                    "Customizable task lists",
                    "Priority settings",
                    "Deadline reminders",
                  ],
                },
                {
                  icon: "📝",
                  title: "Smart Note-taking",
                  description:
                    "Capture ideas, OCR photos, and organize information effortlessly",
                  subFeatures: [
                    "Rich text editing",
                    "Image OCR",
                    "Tag-based organization",
                  ],
                },
                {
                  icon: "📅",
                  title: "Integrated Calendar",
                  description:
                    "Schedule events, set reminders, and collaborate on shared calendars",
                  subFeatures: [
                    "Multiple calendar views",
                    "Event sharing",
                    "Recurring events",
                  ],
                },
                {
                  icon: "📁",
                  title: "Unified File Storage",
                  description:
                    "Store and organize documents, images, and files in one place",
                  subFeatures: [
                    "Cloud sync",
                    "Version history",
                    "Advanced search",
                  ],
                },
                {
                  icon: "🤖",
                  title: "AI-Powered Assistance",
                  description:
                    "Get personalized productivity recommendations and insights",
                  subFeatures: [
                    "Smart suggestions",
                    "Workflow optimization",
                    "Productivity analytics",
                  ],
                },
                {
                  icon: "🔗",
                  title: "Seamless Integrations",
                  description:
                    "Connect with your favorite tools and services for a unified workflow",
                  subFeatures: [
                    "API connections",
                    "Third-party app support",
                    "Custom webhooks",
                  ],
                },
                {
                  icon: "🔒",
                  title: "Privacy Focused",
                  description:
                    "End-to-end encryption and granular privacy controls for your data",
                  subFeatures: [
                    "Data encryption",
                    "Access controls",
                    "GDPR compliance",
                  ],
                },
                {
                  icon: "🏆",
                  title: "Gamified Productivity",
                  description:
                    "Stay motivated with engaging elements and milestone celebrations",
                  subFeatures: [
                    "Achievement badges",
                    "Progress tracking",
                    "Team challenges",
                  ],
                },
                {
                  icon: "💬",
                  title: "All-in-One Messaging",
                  description:
                    "Centralize your communications across multiple platforms",
                  subFeatures: [
                    "Chat integration",
                    "Email management",
                    "Video conferencing",
                  ],
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl group relative overflow-hidden ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  }`}
                >
                  <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-600 to-purple-600 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></div>
                  <div className="flex items-center mb-6">
                    <div
                      className={`text-4xl rounded-full w-16 h-16 flex items-center justify-center mr-4 transition-all duration-300 group-hover:scale-110 ${
                        darkMode ? "bg-indigo-900" : "bg-indigo-100"
                      }`}
                    >
                      {feature.icon}
                    </div>
                    <h3
                      className={`text-xl font-semibold transition-opacity duration-300 ${
                        logoFade ? "opacity-0" : "opacity-100"
                      } ${darkMode ? "text-gray-200" : "text-gray-800"}`}
                    >
                      {feature.title}
                    </h3>
                  </div>
                  <p
                    className={`transition-opacity duration-300 ${
                      logoFade ? "opacity-0" : "opacity-100"
                    } ${darkMode ? "text-gray-400" : "text-gray-600"}`}
                  >
                    {feature.description}
                  </p>
                  <div className="mt-6">
                    <a
                      href="#"
                      className={`font-medium hover:underline transition-opacity duration-300 ${
                        logoFade ? "opacity-0" : "opacity-100"
                      } ${darkMode ? "text-indigo-400" : "text-indigo-600"}`}
                    >
                      Learn more →
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* AI-Powered Productivity Section */}
        <section
          className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-b from-gray-800 via-gray-850 to-gray-900"
              : "bg-gradient-to-b from-indigo-100 via-white to-purple-100"
          }`}
        >
          <div className="max-w-7xl mx-auto">
            <h2
              className={`text-4xl md:text-5xl font-bold text-center mb-4 ${
                darkMode ? "text-indigo-200" : "text-indigo-900"
              }`}
            >
              Supercharge Your Workflow with AI
            </h2>
            <p
              className={`text-xl text-center mb-16 max-w-3xl mx-auto ${
                darkMode ? "text-indigo-300" : "text-indigo-700"
              }`}
            >
              Harness the power of cutting-edge AI to revolutionize your
              productivity
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                {
                  icon: "🧠",
                  title: "Smart Task Prioritization",
                  description:
                    "Our AI analyzes your workload and optimizes task prioritization in real-time.",
                  subFeatures: [
                    "Workload analysis",
                    "Real-time optimization",
                    "Adaptive prioritization",
                  ],
                },
                {
                  icon: "🗓️",
                  title: "Intelligent Calendar Management",
                  description:
                    "AI-powered scheduling that maximizes your productivity and minimizes conflicts.",
                  subFeatures: [
                    "Smart scheduling",
                    "Conflict resolution",
                    "Productivity optimization",
                  ],
                },
                {
                  icon: "📊",
                  title: "Contextual Note Organization",
                  description:
                    "Advanced AI categorizes and links your notes, making information retrieval effortless.",
                  subFeatures: [
                    "Auto-categorization",
                    "Smart linking",
                    "Effortless retrieval",
                  ],
                },
                {
                  icon: "🔮",
                  title: "Predictive File Suggestions",
                  description:
                    "Our AI anticipates your needs, suggesting relevant files before you even search.",
                  subFeatures: [
                    "Anticipatory suggestions",
                    "Context-aware recommendations",
                    "Proactive file management",
                  ],
                },
                {
                  icon: "📈",
                  title: "Personalized Productivity Insights",
                  description:
                    "Gain deep, AI-driven insights to continuously improve your workflow.",
                  subFeatures: [
                    "Performance analytics",
                    "Workflow optimization",
                    "Continuous improvement",
                  ],
                },
                {
                  icon: "🔄",
                  title: "Adaptive Learning",
                  description:
                    "Our AI evolves with you, constantly refining its understanding of your work habits.",
                  subFeatures: [
                    "Habit recognition",
                    "Personalized adaptations",
                    "Continuous learning",
                  ],
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl group relative overflow-hidden ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  }`}
                >
                  <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-600 to-purple-600 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></div>
                  <div className="flex items-center mb-6">
                    <div
                      className={`text-4xl rounded-full w-16 h-16 flex items-center justify-center mr-4 transition-all duration-300 group-hover:scale-110 ${
                        darkMode ? "bg-indigo-900" : "bg-indigo-100"
                      }`}
                    >
                      {feature.icon}
                    </div>
                    <h3
                      className={`text-xl font-semibold transition-opacity duration-300 ${
                        logoFade ? "opacity-0" : "opacity-100"
                      } ${darkMode ? "text-gray-200" : "text-gray-800"}`}
                    >
                      {feature.title}
                    </h3>
                  </div>
                  <p
                    className={`transition-opacity duration-300 ${
                      logoFade ? "opacity-0" : "opacity-100"
                    } ${darkMode ? "text-gray-400" : "text-gray-600"}`}
                  >
                    {feature.description}
                  </p>
                  <div className="mt-6">
                    <a
                      href="#"
                      className={`font-medium hover:underline transition-opacity duration-300 ${
                        logoFade ? "opacity-0" : "opacity-100"
                      } ${darkMode ? "text-indigo-400" : "text-indigo-600"}`}
                    >
                      Learn more →
                    </a>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-24 text-center">
              <h3
                className={`text-4xl font-bold mb-12 ${
                  darkMode ? "text-indigo-200" : "text-indigo-900"
                }`}
              >
                Unlock the Power of AI-Driven Productivity
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
                <div
                  className={`p-8 rounded-2xl ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } shadow-xl transition-all duration-300 hover:shadow-2xl`}
                >
                  <h4
                    className={`text-2xl font-semibold mb-6 ${
                      darkMode ? "text-indigo-300" : "text-indigo-700"
                    }`}
                  >
                    Key Benefits
                  </h4>
                  <ul
                    className={`text-left space-y-4 ${
                      darkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    {[
                      "Save hours with intelligent task automation",
                      "Boost focus with AI-powered prioritization",
                      "Enhance decision-making with data-driven insights",
                      "Streamline workflows with predictive suggestions",
                    ].map((benefit, index) => (
                      <li key={index} className="flex items-start">
                        <svg
                          className="w-6 h-6 text-green-500 mr-2 flex-shrink-0"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M5 13l4 4L19 7"
                          />
                        </svg>
                        <span>{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className={`p-8 rounded-2xl ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } shadow-xl transition-all duration-300 hover:shadow-2xl`}
                >
                  <h4
                    className={`text-2xl font-semibold mb-6 ${
                      darkMode ? "text-indigo-300" : "text-indigo-700"
                    }`}
                  >
                    AI Security & Privacy
                  </h4>
                  <ul
                    className={`text-left space-y-4 ${
                      darkMode ? "text-gray-300" : "text-gray-700"
                    }`}
                  >
                    {[
                      "End-to-end encryption",
                      "Ethical AI practices",
                      "Full transparency",
                      "Regular security audits",
                    ].map((feature, index) => (
                      <li key={index} className="flex items-start">
                        <svg
                          className="w-6 h-6 text-indigo-500 mr-2 flex-shrink-0"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                          />
                        </svg>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Seamless Integration Ecosystem Section */}
        <section
          className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900"
              : "bg-gradient-to-b from-purple-50 via-white to-indigo-50"
          }`}
        >
          <div className="max-w-7xl mx-auto">
            <h2
              className={`text-4xl md:text-5xl font-bold text-center mb-4 ${
                darkMode ? "text-indigo-200" : "text-indigo-900"
              }`}
            >
              Seamless Integration Ecosystem
            </h2>
            <p
              className={`text-xl text-center mb-16 max-w-3xl mx-auto ${
                darkMode ? "text-indigo-300" : "text-indigo-700"
              }`}
            >
              Connect Your Digital Life in One Powerful Hub
            </p>

            {/* Key Points */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              {[
                {
                  icon: "🔗",
                  title: "Unified Workflow",
                  description: "Bring all your tools together in one place",
                },
                {
                  icon: "🔄",
                  title: "Automated Syncing",
                  description: "Keep your data up-to-date across all platforms",
                },
                {
                  icon: "🛠️",
                  title: "Customizable Connections",
                  description: "Tailor integrations to your unique needs",
                },
                {
                  icon: "⚡",
                  title: "Time-Saving Automations",
                  description: "Create powerful workflows between apps",
                },
              ].map((point, index) => (
                <div
                  key={index}
                  className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl group relative overflow-hidden ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  }`}
                >
                  <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-600 to-purple-600 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></div>
                  <div className="flex items-center mb-6">
                    <div
                      className={`text-4xl rounded-full w-16 h-16 flex items-center justify-center mr-4 transition-all duration-300 group-hover:scale-110 ${
                        darkMode ? "bg-indigo-900" : "bg-indigo-100"
                      }`}
                    >
                      {point.icon}
                    </div>
                    <h3
                      className={`text-xl font-semibold transition-opacity duration-300 ${
                        darkMode ? "text-gray-200" : "text-gray-800"
                      }`}
                    >
                      {point.title}
                    </h3>
                  </div>
                  <p
                    className={`transition-opacity duration-300 ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {point.description}
                  </p>
                </div>
              ))}
            </div>

            {/* Featured Integrations */}
            <div className="text-center mb-16">
              <h3
                className={`text-4xl md:text-5xl font-bold mb-4 ${
                  darkMode ? "text-indigo-200" : "text-indigo-900"
                }`}
              >
                Seamless Integrations
              </h3>
              <p
                className={`text-lg md:text-xl max-w-2xl mx-auto ${
                  darkMode ? "text-indigo-300" : "text-indigo-600"
                }`}
              >
                Connect Boxia with your favorite tools and boost your
                productivity
              </p>
            </div>
            <div className="relative overflow-hidden mb-6">
              <div className="flex space-x-8 animate-scroll">
                {[...integrations, ...integrations].map((app, index) => (
                  <div
                    key={index}
                    className="flex-shrink-0 w-32 h-32 mx-4 flex items-center justify-center"
                  >
                    <img
                      src={app.logo}
                      alt={`${app.name} logo`}
                      className={`w-16 h-16 transition-all duration-300 ${
                        darkMode
                          ? "invert brightness-0 contrast-100 hue-rotate-180 saturate-0 opacity-70 hover:opacity-100"
                          : "opacity-100"
                      }`}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* Customization and Workflow Section */}
        <section
          className={`py-6 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-b from-gray-900 via-gray-850 to-gray-800"
              : "bg-gradient-to-b from-indigo-50 via-white to-indigo-100"
          }`}
        >
          <div className="max-w-7xl mx-auto text-center">
            <h2
              className={`text-4xl md:text-5xl font-bold mb-4 ${
                darkMode ? "text-indigo-200" : "text-indigo-900"
              }`}
            >
              Your Workspace, Your Rules
            </h2>
            <p
              className={`text-xl mb-16 max-w-3xl mx-auto ${
                darkMode ? "text-indigo-300" : "text-indigo-700"
              }`}
            >
              Tailor Boxia to fit your unique workflow and preferences
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-[100px]">
              {[
                {
                  icon: "🎛️",
                  title: "Personalized Dashboard",
                  description: "Tailor your workspace to your needs",
                  subFeatures: [
                    "Drag-and-drop widgets",
                    "Customizable layouts",
                    "Choose what information to display",
                  ],
                },
                {
                  icon: "✅",
                  title: "Flexible Task Management",
                  description: "Manage tasks your way",
                  subFeatures: [
                    "Create custom task views",
                    "Design your own task fields",
                    "Set up personalized workflows",
                  ],
                },
                {
                  icon: "🏷️",
                  title: "Smart Tagging System",
                  description: "Organize with intelligent tagging",
                  subFeatures: [
                    "Create custom tags",
                    "Automated tag suggestions",
                    "Cross-functional tag search",
                  ],
                },
                {
                  icon: "⚙️",
                  title: "Workflow Automation",
                  description: "Streamline your processes",
                  subFeatures: [
                    "Build custom automation rules",
                    "Create templates for recurring processes",
                    "Set up conditional workflows",
                  ],
                },
                {
                  icon: "📊",
                  title: "Customizable Sidebar",
                  description: "Navigate your way",
                  subFeatures: [
                    "Reorder and hide sidebar items",
                    "Add custom shortcuts",
                    "Create nested menus",
                  ],
                },
                {
                  icon: "🎨",
                  title: "Theming and Appearance",
                  description: "Make Boxia uniquely yours",
                  subFeatures: [
                    "Choose from pre-designed themes",
                    "Create custom color schemes",
                    "Toggle between light and dark modes",
                  ],
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl group relative overflow-hidden ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  }`}
                >
                  <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-600 to-purple-600 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></div>
                  <div className="flex items-center mb-6">
                    <div
                      className={`text-4xl rounded-full w-16 h-16 flex items-center justify-center mr-4 transition-all duration-300 group-hover:scale-110 ${
                        darkMode ? "bg-indigo-900" : "bg-indigo-100"
                      }`}
                    >
                      {feature.icon}
                    </div>
                    <h3
                      className={`text-xl font-semibold transition-opacity duration-300 ${
                        darkMode ? "text-gray-200" : "text-gray-800"
                      }`}
                    >
                      {feature.title}
                    </h3>
                  </div>
                  <p
                    className={`mb-4 transition-opacity duration-300 ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {feature.description}
                  </p>
                  <ul
                    className={`list-disc pl-5 ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {feature.subFeatures.map((subFeature, subIndex) => (
                      <li key={subIndex}>{subFeature}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Security and Privacy Focus Section */}
        <section
          className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-b from-gray-800 via-gray-850 to-gray-900"
              : "bg-gradient-to-b from-indigo-100 via-white to-indigo-50"
          }`}
        >
          <div className="max-w-7xl mx-auto">
            <h2
              className={`text-4xl md:text-5xl font-bold text-center mb-4 ${
                darkMode ? "text-indigo-200" : "text-indigo-900"
              }`}
            >
              Your Data, Your Control
            </h2>
            <p
              className={`text-xl text-center mb-16 max-w-3xl mx-auto ${
                darkMode ? "text-indigo-300" : "text-indigo-700"
              }`}
            >
              Industry-leading security and privacy features to keep your
              information safe
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
              {[
                {
                  icon: "🔒",
                  title: "End-to-End Encryption",
                  description:
                    "All your data is encrypted in transit and at rest. Only you have access to your unencrypted data.",
                },
                {
                  icon: "👤",
                  title: "Data Ownership",
                  description:
                    "You own your data, always. Export your data anytime, in standard formats. No data mining or selling of user information.",
                },
                {
                  icon: "🛡️",
                  title: "Privacy Controls",
                  description:
                    "Granular privacy settings for each piece of content. Control what data is synced across devices.",
                },
                {
                  icon: "🔑",
                  title: "Two-Factor Authentication",
                  description:
                    "Add an extra layer of security to your account with various 2FA methods.",
                },
                {
                  icon: "📜",
                  title: "Compliance and Certifications",
                  description:
                    "GDPR compliant, SOC 2 and ISO 27001 certified. Regular third-party security audits.",
                },
                {
                  icon: "🌐",
                  title: "Open Source Components",
                  description:
                    "Transparency in our security practices with links to open-source libraries used in Boxia.",
                },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl group relative overflow-hidden ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  }`}
                >
                  <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-600 to-purple-600 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></div>
                  <div className="flex items-center mb-6">
                    <div
                      className={`text-4xl rounded-full w-16 h-16 flex items-center justify-center mr-4 transition-all duration-300 group-hover:scale-110 ${
                        darkMode ? "bg-indigo-900" : "bg-indigo-100"
                      }`}
                    >
                      {feature.icon}
                    </div>
                    <h3
                      className={`text-xl font-semibold transition-opacity duration-300 ${
                        darkMode ? "text-gray-200" : "text-gray-800"
                      }`}
                    >
                      {feature.title}
                    </h3>
                  </div>
                  <p
                    className={`transition-opacity duration-300 ${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    {feature.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Pricing Section */}
        <section
          className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-b from-gray-900 via-gray-850 to-gray-800"
              : "bg-gradient-to-b from-indigo-50 via-white to-indigo-100"
          }`}
        >
          <div className="max-w-7xl mx-auto">
            <h2
              className={`text-4xl md:text-5xl font-bold text-center mb-4 ${
                darkMode ? "text-indigo-200" : "text-indigo-900"
              }`}
            >
              Simple Pricing for Powerful Productivity
            </h2>
            <p
              className={`text-xl text-center mb-12 max-w-3xl mx-auto ${
                darkMode ? "text-indigo-300" : "text-indigo-700"
              }`}
            >
              Choose the plan that fits your needs and start boosting your
              productivity today
            </p>

            <div className="flex justify-center mb-12">
              <div
                className={`p-1 rounded-full ${
                  darkMode ? "bg-gray-700" : "bg-gray-200"
                }`}
              >
                <button
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    annualBilling
                      ? darkMode
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-500 text-white"
                      : darkMode
                      ? "text-gray-300"
                      : "text-gray-700"
                  }`}
                  onClick={() => setAnnualBilling(true)}
                >
                  Annual Billing
                </button>
                <button
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors duration-200 ${
                    !annualBilling
                      ? darkMode
                        ? "bg-indigo-600 text-white"
                        : "bg-indigo-500 text-white"
                      : darkMode
                      ? "text-gray-300"
                      : "text-gray-700"
                  }`}
                  onClick={() => setAnnualBilling(false)}
                >
                  Monthly Billing
                </button>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {[
                {
                  name: "Boxia Personal",
                  price: annualBilling ? "$7.99" : "$9.99",
                  period: annualBilling ? "/month" : "/month",
                  billing: annualBilling ? "billed annually" : "billed monthly",
                  features: [
                    "All core Boxia features",
                    "10GB storage",
                    "Basic AI assistance",
                    "Up to 5 integrations",
                    "Mobile app access",
                  ],
                  cta: (
                    <a href={waitlistFormUrl} className="text-black">
                      Join Waitlist
                    </a>
                  ),
                },
                {
                  name: "Boxia Pro",
                  price: annualBilling ? "$14.99" : "$17.99",
                  period: annualBilling ? "/month" : "/month",
                  billing: annualBilling ? "billed annually" : "billed monthly",
                  features: [
                    "All Personal features",
                    "Unlimited storage",
                    "Advanced AI features",
                    "Unlimited integrations",
                    "Priority support",
                    "Advanced customization",
                    "Workflow automation",
                  ],
                  cta: (
                    <a href={waitlistFormUrl} className="text-white">
                      Join Waitlist
                    </a>
                  ),
                  highlight: true,
                },
                {
                  name: "Boxia Team",
                  price: annualBilling ? "$12.99" : "$15.99",
                  period: "/user/month",
                  billing: annualBilling ? "billed annually" : "billed monthly",
                  note: "Minimum 3 users",
                  features: [
                    "All Pro features",
                    "Team collaboration tools",
                    "Shared workspaces",
                    "Admin controls",
                    "Team analytics",
                    "Enhanced security features",
                  ],
                  cta: (
                    <a href={waitlistFormUrl} className="text-black">
                      Join Waitlist
                    </a>
                  ),
                },
                {
                  name: "Boxia Enterprise",
                  price: "Custom",
                  features: [
                    "All Team features",
                    "Custom integrations",
                    "On-premises deployment",
                    "Advanced security (SSO)",
                    "Dedicated account manager",
                    "Custom training & onboarding",
                  ],
                  cta: (
                    <a href={waitlistFormUrl} className="text-black">
                      Join Waitlist
                    </a>
                  ),
                },
              ].map((plan, index) => (
                <div
                  key={index}
                  className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } ${
                    plan.highlight ? "ring-2 ring-indigo-500" : ""
                  } flex flex-col`}
                >
                  <div className="flex-grow">
                    <h3
                      className={`text-2xl font-bold mb-4 ${
                        darkMode ? "text-indigo-300" : "text-indigo-700"
                      }`}
                    >
                      {plan.name}
                    </h3>
                    <div className="mb-2">
                      <span
                        className={`text-4xl font-bold ${
                          darkMode ? "text-gray-100" : "text-gray-900"
                        }`}
                      >
                        {plan.price}
                      </span>
                      <span
                        className={`text-lg ${
                          darkMode ? "text-gray-400" : "text-gray-600"
                        }`}
                      >
                        {plan.period}
                      </span>
                    </div>
                    {plan.billing && (
                      <p
                        className={`mb-2 text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-600"
                        }`}
                      >
                        {plan.billing}
                      </p>
                    )}
                    {plan.note && (
                      <p
                        className={`mb-[5px] text-sm font-medium ${
                          darkMode ? "text-gray-300" : "text-gray-700"
                        }`}
                      >
                        {plan.note}
                      </p>
                    )}
                    <ul className="mb-8 space-y-2">
                      {plan.features.map((feature, featureIndex) => (
                        <li
                          key={featureIndex}
                          className={`flex items-center ${
                            darkMode ? "text-gray-300" : "text-gray-700"
                          }`}
                        >
                          <svg
                            className="w-5 h-5 mr-2 text-green-500 flex-shrink-0"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                          <span>{feature}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-auto">
                    <button
                      className={`w-full py-2 px-4 rounded-full font-medium ${
                        plan.highlight
                          ? "bg-indigo-600 text-white hover:bg-indigo-700"
                          : darkMode
                          ? "bg-gray-700 text-white hover:bg-gray-600"
                          : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                      } transition-colors duration-200`}
                    >
                      {plan.cta}
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Final CTA Section */}
        <section
          className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
            darkMode
              ? "bg-gradient-to-b from-gray-800 via-gray-850 to-gray-900"
              : "bg-gradient-to-b from-indigo-100 via-white to-indigo-50"
          }`}
        >
          <div className="max-w-7xl mx-auto text-center">
            <h2
              className={`text-4xl md:text-5xl font-bold mb-6 ${
                darkMode ? "text-indigo-200" : "text-indigo-900"
              }`}
            >
              Be First in Line for Boxia
            </h2>
            <p
              className={`text-xl mb-12 max-w-3xl mx-auto ${
                darkMode ? "text-indigo-300" : "text-indigo-700"
              }`}
            >
              Join our exclusive waitlist and be among the first to experience
              the future of work with Boxia.
            </p>
            <a
              href={waitlistFormUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={`inline-block px-8 py-4 text-lg font-medium rounded-xl transition-all duration-300 ease-in-out ${
                darkMode
                  ? "bg-indigo-600 text-white hover:bg-indigo-700"
                  : "bg-indigo-600 text-white hover:bg-indigo-700"
              }`}
              style={{ marginBottom: "50px" }}
            >
              Join Waitlist Now
            </a>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-16">
              <div
                className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl ${
                  darkMode ? "bg-gray-800" : "bg-white"
                }`}
              >
                <h3
                  className={`text-2xl font-semibold mb-6 ${
                    darkMode ? "text-indigo-300" : "text-indigo-700"
                  }`}
                >
                  Why Choose Boxia?
                </h3>
                <ul
                  className={`space-y-4 ${
                    darkMode ? "text-gray-300" : "text-gray-700"
                  }`}
                >
                  {[
                    "All-in-one productivity powerhouse",
                    "AI-powered task management and insights",
                    "Seamless integrations with your favorite tools",
                    "Customizable to fit your unique workflow",
                    "Bank-level security and privacy",
                  ].map((item, index) => (
                    <li key={index} className="flex items-start">
                      <svg
                        className="w-6 h-6 mr-2 text-green-500 flex-shrink-0"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <span>{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className={`p-8 rounded-2xl shadow-lg transition-all duration-300 hover:shadow-2xl ${
                  darkMode ? "bg-gray-800" : "bg-white"
                }`}
              >
                <h3
                  className={`text-2xl font-semibold mb-6 ${
                    darkMode ? "text-indigo-300" : "text-indigo-700"
                  }`}
                >
                  Trusted by Industry Leaders
                </h3>
                <div className="grid grid-cols-3 gap-6">
                  {[
                    { name: "google", logo: googleLogo },
                    { name: "microsoft", logo: microsoftLogo },
                    { name: "slack", logo: slackLogo },
                    { name: "dropbox", logo: dropboxLogo },
                    { name: "github", logo: githubLogo },
                    { name: "salesforce", logo: salesforceLogo },
                  ].map((company) => (
                    <div
                      key={company.name}
                      className="flex items-center justify-center"
                    >
                      <img
                        src={company.logo}
                        alt={`${company.name} logo`}
                        className={`w-16 h-16 transition-all duration-300 ${
                          darkMode
                            ? "invert brightness-0 contrast-100 opacity-70 hover:opacity-100"
                            : "opacity-70 hover:opacity-100"
                        }`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={`w-full p-6 rounded-xl ${
                darkMode ? "bg-gray-800" : "bg-white"
              } shadow-lg`}
            >
              <h3
                className={`text-2xl font-semibold mb-6 ${
                  darkMode ? "text-indigo-300" : "text-indigo-700"
                }`}
              >
                Frequently Asked Questions
              </h3>
              <div className="space-y-2">
                <FAQItem
                  question="Is my data secure with Boxia?"
                  answer="Absolutely. We use bank-level encryption and adhere to strict privacy standards to ensure your data is always protected."
                  darkMode={darkMode}
                />
                <FAQItem
                  question="Can I cancel my subscription anytime?"
                  answer="Yes, you can cancel your subscription at any time with no questions asked. We believe in the quality of our service and don't want to lock you in."
                  darkMode={darkMode}
                />
                <FAQItem
                  question="How does the 14-day free trial work?"
                  answer="You can use all Boxia features for 14 days without any charge. No credit card is required to start your trial. At the end of the trial, you can choose to subscribe or your account will be automatically downgraded to the free plan."
                  darkMode={darkMode}
                />
                <FAQItem
                  question="What kind of support do you offer?"
                  answer="We offer 24/7 email support for all users. Pro and Team plan subscribers also get priority support with faster response times and access to video chat support."
                  darkMode={darkMode}
                />
              </div>
            </div>
          </div>
        </section>

        <Footer darkMode={darkMode} />
      </main>
    </div>
  );
};

export default LandingPage;
