import React from "react";
import { Route, Routes } from "react-router-dom";
import AppPage from "./pages/AppPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import PlanSelectionPage from "./pages/PlanSelection";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccess";
import SettingsPage from "./pages/AppSettings";
import AppMessages from "./pages/AppMessages";
import TrelloCallback from "./components/TrelloCallback";
import TaskPage from "./pages/AppTasks";
import LandingPage from "./pages/LandingPage";
import FeaturesPage from "./pages/FeaturesPage";
import PricingPage from "./pages/PricingPage";
import FilesPage from "./pages/AppFiles";
import GoogleOAuthCallback from "./components/GoogleOAuthCallback";
import CalendarPage from "./pages/AppCalendar";
import AppIntegrations from "./pages/AppIntegrations";
import AppTodo from "./pages/AppTodo";
import AppNotes from "./pages/AppNotes";
import Microsoft365OAuthCallback from "./components/Microsoft365OAuthCallback";
import GitHubOAuthCallback from "./components/GitHubOAuthCallback";
import IntegrationsPage from "./pages/IntegrationsPage";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/app" element={<AppPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<SignupPage />} />
      <Route path="/plan-selection" element={<PlanSelectionPage />} />
      <Route path="/subSuccess" element={<SubscriptionSuccessPage />} />
      <Route path="/app/settings" element={<SettingsPage />} />
      <Route path="/app/messages" element={<AppMessages />} />
      <Route path="/trello-callback" element={<TrelloCallback />} />
      <Route path="/app/boards" element={<TaskPage />} />
      <Route path="/app/calendar" element={<CalendarPage />} />
      <Route path="/features" element={<FeaturesPage />} />
      <Route path="/pricing" element={<PricingPage />} />
      <Route path="/app/files" element={<FilesPage />} />
      <Route path="/google-oauth-callback" element={<GoogleOAuthCallback />} />
      <Route path="/app/integrations" element={<AppIntegrations />} />
      <Route path="/app/todo" element={<AppTodo />} />
      <Route path="/app/notes" element={<AppNotes />} />
      <Route
        path="/microsoft365-oauth-callback"
        element={<Microsoft365OAuthCallback />}
      />
      <Route path="/github-oauth-callback" element={<GitHubOAuthCallback />} />
      <Route path="/integrations" element={<IntegrationsPage />} />
    </Routes>
  );
}

export default AppRoutes;
