import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import boxiaCircleLogo from "../assets/images/Boxia Circle Main.png";
import boxiaTextDarkMode from "../assets/images/Boxia text for dark mode.svg";
import boxiaTextLightMode from "../assets/images/Boxia text for light mode.svg";
// Import integration logos
import googleLogo from "../assets/images/logos/google.svg";
import microsoftLogo from "../assets/images/logos/microsoft.svg";
import jiraLogo from "../assets/images/logos/jira.svg";
import trelloLogo from "../assets/images/logos/trello.svg";
import slackLogo from "../assets/images/logos/slack.svg";
import githubLogo from "../assets/images/logos/github.svg";
import dropboxLogo from "../assets/images/logos/dropbox.svg";
import salesforceLogo from "../assets/images/logos/salesforce.svg";
import asanaLogo from "../assets/images/logos/asana.svg";
import evernoteLogo from "../assets/images/logos/evernote.svg";
import zapierLogo from "../assets/images/logos/zapier.svg";
import hubspotLogo from "../assets/images/logos/hubspot.svg";

const transition = {
  type: "spring",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

const MenuItem = ({ setActive, active, item, children }) => {
  return (
    <div onMouseEnter={() => setActive(item)} className="relative">
      <motion.div
        transition={{ duration: 0.3 }}
        className="cursor-pointer hover:opacity-80"
      >
        <a
          href={`/${item.toLowerCase()}`}
          className="block text-sm font-medium text-gray-700 hover:text-gray-900 transition-colors duration-200"
        >
          {item}
        </a>
      </motion.div>
      {active !== null && (
        <motion.div
          initial={{ opacity: 0, scale: 0.95, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={transition}
        >
          {active === item && (
            <div className="absolute top-full left-1/2 transform -translate-x-1/2 pt-2">
              <motion.div
                transition={transition}
                layoutId="active"
                className="bg-white backdrop-filter backdrop-blur-lg rounded-lg overflow-hidden border border-gray-200 shadow-lg"
              >
                <motion.div layout className="w-max h-full p-3">
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

const NavBar = ({ darkMode, logoFade }) => {
  const [active, setActive] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const waitlistFormUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSdKV5mQfOP17K0RlAyGUtCgLmjLzHINjIvGKshTEOvHbmmEZg/viewform";

  const featuresMenu = [
    {
      icon: "📋",
      name: "Task Management",
      description: "AI-powered task organization",
    },
    { icon: "📝", name: "Note-Taking", description: "Smart, contextual notes" },
    { icon: "📅", name: "Calendar", description: "AI-optimized scheduling" },
    {
      icon: "📁",
      name: "File Storage",
      description: "Unified, intelligent storage",
    },
    {
      icon: "🔗",
      name: "Integrations",
      description: "Connect your favorite tools",
    },
    {
      icon: "🔒",
      name: "Security",
      description: "Advanced encryption & privacy",
    },
  ];

  const pricingMenu = [
    {
      name: "Personal",
      price: "$7.99",
      period: "/month",
      description: "For individual users",
    },
    {
      name: "Pro",
      price: "$14.99",
      period: "/month",
      description: "For power users",
      highlight: true,
    },
    {
      name: "Team",
      price: "$12.99",
      period: "/user/month",
      description: "For small teams",
    },
    {
      name: "Enterprise",
      price: "Custom",
      period: "",
      description: "For large organizations",
    },
  ];

  const integrationsMenu = [
    {
      icon: googleLogo,
      name: "Google",
      description: "Sync with Google Workspace",
    },
    {
      icon: microsoftLogo,
      name: "Microsoft",
      description: "Connect with Microsoft 365",
    },
    { icon: slackLogo, name: "Slack", description: "Collaborate seamlessly" },
    { icon: trelloLogo, name: "Trello", description: "Manage tasks visually" },
    {
      icon: githubLogo,
      name: "GitHub",
      description: "Integrate your code workflow",
    },
    {
      icon: zapierLogo,
      name: "Zapier",
      description: "Connect with 1000+ apps",
    },
  ];

  const menuVariants = {
    closed: { opacity: 0, x: "100%" },
    open: { opacity: 1, x: 0 },
  };

  return (
    <nav
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
        darkMode ? "bg-gray-900/60" : "bg-white/60"
      } backdrop-blur-sm shadow-lg mx-auto mt-4 rounded-full w-full max-w-7xl`}
      onMouseLeave={() => setActive(null)}
    >
      <div className="max-w-7xl mx-auto px-6">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img
                src={boxiaCircleLogo}
                alt="Boxia Circle Logo"
                className="w-8 h-8 mr-3"
              />
              <img
                src={darkMode ? boxiaTextDarkMode : boxiaTextLightMode}
                alt="Boxia Logo"
                className={`h-8 transition-opacity duration-300 ${
                  logoFade ? "opacity-0" : "opacity-100"
                }`}
              />
            </a>
          </div>
          <div className="flex-grow flex justify-center">
            <div className="hidden md:flex md:items-center md:space-x-8">
              <MenuItem setActive={setActive} active={active} item="Features">
                <div className="grid grid-cols-2 gap-4 p-4">
                  {featuresMenu.map((feature) => (
                    <a
                      key={feature.name}
                      href="/features"
                      className={`flex items-center space-x-3 p-2 rounded-lg transition-colors duration-200 ${
                        darkMode
                          ? "hover:bg-gray-700 text-gray-200"
                          : "hover:bg-gray-100 text-gray-800"
                      }`}
                    >
                      <span className="text-2xl">{feature.icon}</span>
                      <div>
                        <p className="font-medium">{feature.name}</p>
                        <p
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-600"
                          }`}
                        >
                          {feature.description}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="p-4">
                  <a
                    href="/features"
                    className={`block text-center py-2 px-5 rounded-full text-sm font-medium text-white transition-all duration-300 ${
                      darkMode
                        ? "bg-gray-800 hover:bg-gray-700"
                        : "bg-gray-700 hover:bg-gray-800"
                    }`}
                  >
                    View All Features
                  </a>
                </div>
              </MenuItem>
              <MenuItem
                setActive={setActive}
                active={active}
                item="Integrations"
              >
                <div className="grid grid-cols-2 gap-4 p-4">
                  {integrationsMenu.map((integration) => (
                    <a
                      key={integration.name}
                      href="/integrations"
                      className={`flex items-center space-x-3 p-2 rounded-lg transition-colors duration-200 ${
                        darkMode
                          ? "hover:bg-gray-700 text-gray-200"
                          : "hover:bg-gray-100 text-gray-800"
                      }`}
                    >
                      <img
                        src={integration.icon}
                        alt={`${integration.name} logo`}
                        className="w-6 h-6"
                      />
                      <div>
                        <p className="font-medium">{integration.name}</p>
                        <p
                          className={`text-sm ${
                            darkMode ? "text-gray-400" : "text-gray-600"
                          }`}
                        >
                          {integration.description}
                        </p>
                      </div>
                    </a>
                  ))}
                </div>
                <div className="p-4">
                  <a
                    href="/integrations"
                    className={`block text-center py-2 px-5 rounded-full text-sm font-medium text-white transition-all duration-300 ${
                      darkMode
                        ? "bg-gray-800 hover:bg-gray-700"
                        : "bg-gray-700 hover:bg-gray-800"
                    }`}
                  >
                    View All Integrations
                  </a>
                </div>
              </MenuItem>
              <MenuItem setActive={setActive} active={active} item="Pricing">
                <div className="grid grid-cols-2 gap-4 p-4">
                  {pricingMenu.map((plan) => (
                    <a
                      key={plan.name}
                      href="/pricing"
                      className={`flex flex-col p-3 rounded-lg transition-colors duration-200 ${
                        darkMode
                          ? "hover:bg-gray-700 text-gray-200"
                          : "hover:bg-gray-100 text-gray-800"
                      } ${plan.highlight ? "ring-2 ring-indigo-500" : ""}`}
                    >
                      <span className="font-medium">{plan.name}</span>
                      <span className="text-lg font-bold">
                        {plan.price}
                        <span className="text-sm font-normal">
                          {plan.period}
                        </span>
                      </span>
                      <span
                        className={`text-sm ${
                          darkMode ? "text-gray-400" : "text-gray-600"
                        }`}
                      >
                        {plan.description}
                      </span>
                    </a>
                  ))}
                </div>
                <div className="p-4">
                  <a
                    href="/pricing"
                    className={`block text-center py-2 px-5 rounded-full text-sm font-medium text-white transition-all duration-300 ${
                      darkMode
                        ? "bg-gray-800 hover:bg-gray-700"
                        : "bg-gray-700 hover:bg-gray-800"
                    }`}
                  >
                    Compare All Plans
                  </a>
                </div>
              </MenuItem>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className={`p-2 rounded-md ${
                darkMode ? "text-gray-200" : "text-gray-800"
              }`}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          {/* Join Waitlist Button (hidden on mobile) */}
          <a
            href={waitlistFormUrl}
            className={`hidden md:block px-5 py-2 rounded-full text-sm font-medium text-white transition-all duration-300 ${
              darkMode
                ? "bg-gray-800 hover:bg-gray-700"
                : "bg-gray-700 hover:bg-gray-800"
            }`}
          >
            Join Waitlist
          </a>
        </div>
      </div>

      {/* Mobile menu dropdown */}
      <AnimatePresence>
        {mobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className={`md:hidden absolute top-full left-0 right-0 mt-2 ${
              darkMode ? "bg-gray-900" : "bg-white"
            } shadow-lg rounded-3xl overflow-hidden`}
          >
            <div className="px-6 py-4 space-y-3">
              {["Features", "Integrations", "Pricing"].map((item) => (
                <a
                  key={item}
                  href={`/${item.toLowerCase()}`}
                  className={`block px-4 py-3 rounded-2xl text-base font-medium transition-all duration-300 ${
                    darkMode
                      ? "text-gray-200 hover:bg-gray-800 hover:text-white"
                      : "text-gray-800 hover:bg-gray-100 hover:text-gray-900"
                  }`}
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {item}
                </a>
              ))}
              <a
                href={waitlistFormUrl}
                className={`block px-4 py-3 rounded-2xl text-base font-medium text-white transition-all duration-300 ${
                  darkMode
                    ? "bg-gray-800 hover:bg-gray-700"
                    : "bg-gray-700 hover:bg-gray-600"
                }`}
                onClick={() => setMobileMenuOpen(false)}
              >
                Join Waitlist
              </a>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default NavBar;
