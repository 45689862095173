import React, {
  useState,
  useEffect,
  useContext,
  Suspense,
  useMemo,
  useCallback,
} from "react";
import { UserContext } from "../context/UserContext";
import { DarkModeContext } from "../context/DarkModeContext";
import Sidebar from "../components/Sidebar";
import { db } from "../firebase/config";
import { collection, getDocs, addDoc, doc, getDoc } from "firebase/firestore";
import { syncTrelloBoards, createTrelloBoard } from "../utils/trelloSync";
import { motion, AnimatePresence } from "framer-motion";
import KanbanBoard from "../components/KanbanBoard";
import {
  StarIcon,
  PlusIcon,
  XIcon as XMarkIcon,
  HomeIcon,
} from "@heroicons/react/outline";
import { getRecentItems } from "../utils/recentItemsUtils";

const bgColors = [
  "bg-gradient-to-br from-purple-500 to-indigo-600",
  "bg-gradient-to-br from-blue-500 to-teal-400",
  "bg-gradient-to-br from-green-500 to-emerald-400",
  "bg-gradient-to-br from-yellow-400 to-orange-500",
  "bg-gradient-to-br from-red-500 to-pink-500",
  "bg-gradient-to-br from-indigo-500 to-purple-600",
];

const Tab = ({ id, title, isActive, onClick, onClose, isDarkMode }) => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -10 }}
    className={`flex items-center px-4 py-2 rounded-t-lg cursor-pointer transition-all duration-200 ${
      isActive
        ? isDarkMode
          ? "bg-indigo-600 text-white"
          : "bg-white text-indigo-600"
        : isDarkMode
        ? "bg-gray-800 text-gray-300 hover:bg-gray-700"
        : "bg-gray-100 text-gray-600 hover:bg-gray-200"
    }`}
    onClick={onClick}
    onMouseDown={(e) => {
      if (e.button === 1) {
        e.preventDefault();
        onClose(id);
      }
    }}
  >
    {id === "home" ? (
      <div className="flex items-center">
        <HomeIcon className="w-4 h-4 mr-2" />
        <span className="font-medium">Home</span>
      </div>
    ) : (
      <>
        <span className="font-medium mr-2">{title}</span>
        <button
          onClick={(e) => {
            e.stopPropagation();
            onClose(id);
          }}
          className={`ml-2 transition-colors duration-200 ${
            isDarkMode
              ? "text-gray-400 hover:text-white"
              : "text-gray-500 hover:text-indigo-600"
          }`}
        >
          <XMarkIcon className="w-4 h-4" />
        </button>
      </>
    )}
  </motion.div>
);

const TabContainer = ({ children, isDarkMode }) => (
  <div className={`flex items-end space-x-1 p-2 rounded-t-lg shadow-md`}>
    <AnimatePresence>{children}</AnimatePresence>
  </div>
);

function AppTasks() {
  const { userData } = useContext(UserContext);
  const { isDarkMode } = useContext(DarkModeContext);
  const [boxiaBoards, setBoxiaBoards] = useState([]);
  const [trelloBoards, setTrelloBoards] = useState([]);
  const [starredBoards, setStarredBoards] = useState([]);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [newBoardName, setNewBoardName] = useState("");
  const [newBoardBackground, setNewBoardBackground] = useState(bgColors[0]);
  const [tabs, setTabs] = useState([{ id: "home", title: "Home" }]);
  const [activeTab, setActiveTab] = useState("home");
  const mainContainerClass = isDarkMode
    ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
    : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900";
  const [isLoading, setIsLoading] = useState(true);
  const [openBoards, setOpenBoards] = useState({});
  const [isCreateTrelloBoard, setIsCreateTrelloBoard] = useState(false);
  const [recentTasks, setRecentTasks] = useState([]);
  const [isTrelloConnected, setIsTrelloConnected] = useState(false);

  useEffect(() => {
    if (userData && userData.uid) {
      checkTrelloConnection();
      fetchBoards();
      fetchRecentTasks();
    }
  }, [userData]);

  const checkTrelloConnection = async () => {
    if (userData && userData.uid) {
      try {
        const userDoc = await getDoc(
          doc(db, `users/${userData.uid}/integrations/trello`)
        );
        const isConnected = userDoc.exists() && userDoc.data().accessToken;
        setIsTrelloConnected(isConnected);
        if (!isConnected) {
          // Clear Trello boards from state and local storage if disconnected
          setTrelloBoards([]);
          localStorage.removeItem(`boardsCache_${userData.uid}`);
        }
      } catch (error) {
        console.error("Error checking Trello connection:", error);
      }
    }
  };

  const fetchRecentTasks = async () => {
    try {
      const recentItems = await getRecentItems(userData.uid, "task");
      setRecentTasks(recentItems);
    } catch (error) {
      console.error("Error fetching recent tasks:", error);
    }
  };

  useEffect(() => {
    if (userData && userData.uid) {
      fetchBoards();
    }
  }, [userData]);

  useEffect(() => {
    if (userData && userData.uid) {
      const cachedData = localStorage.getItem(`boardsCache_${userData.uid}`);
      if (cachedData) {
        const { trelloBoards, boxiaBoards, timestamp } = JSON.parse(cachedData);
        setTrelloBoards(trelloBoards);
        setBoxiaBoards(boxiaBoards);
        setStarredBoards(
          [...trelloBoards, ...boxiaBoards].filter((board) => board.starred)
        );
        setIsLoading(false);

        if (Date.now() - timestamp > 5 * 60 * 1000) {
          fetchBoards();
        }
      } else {
        fetchBoards();
      }
      fetchRecentTasks();
    }
  }, [userData]);

  const fetchBoards = async () => {
    setError(null);
    try {
      if (isTrelloConnected) {
        await syncTrelloBoards(userData.uid);
      }

      const [trelloBoardsSnapshot, boxiaBoardsSnapshot] = await Promise.all([
        isTrelloConnected
          ? getDocs(collection(db, `users/${userData.uid}/trelloBoards`))
          : Promise.resolve({ docs: [] }),
        getDocs(collection(db, `users/${userData.uid}/boxiaBoards`)),
      ]);

      const trelloBoards = trelloBoardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        isTrello: true,
      }));

      const boxiaBoards = boxiaBoardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        isTrello: false,
      }));

      const allBoards = [...trelloBoards, ...boxiaBoards];

      setTrelloBoards(trelloBoards);
      setBoxiaBoards(boxiaBoards);
      setStarredBoards(allBoards.filter((board) => board.starred));
      setIsLoading(false);

      if (isTrelloConnected) {
        localStorage.setItem(
          `boardsCache_${userData.uid}`,
          JSON.stringify({
            trelloBoards,
            boxiaBoards,
            timestamp: Date.now(),
          })
        );
      }
    } catch (error) {
      console.error("Error fetching boards:", error);
      setError("Failed to fetch boards. Please try again.");
      setIsLoading(false);
    }
  };

  const createBoard = async (boardName, boardBackground, isTrello) => {
    if (!boardName.trim()) return;

    try {
      setIsLoading(true);
      let newBoard;

      if (isTrello) {
        newBoard = await createTrelloBoard(
          userData.uid,
          boardName.trim(),
          boardBackground
        );
      } else {
        const boardsRef = collection(db, `users/${userData.uid}/boxiaBoards`);
        const newBoardData = {
          name: boardName.trim(),
          background: boardBackground,
          starred: false,
          createdAt: new Date().toISOString(),
          lists: [],
        };

        const docRef = await addDoc(boardsRef, newBoardData);
        newBoard = { ...newBoardData, id: docRef.id, isTrello: false };
      }

      // Add the new board to the state
      if (isTrello) {
        setTrelloBoards((prevBoards) => [...prevBoards, newBoard]);
      } else {
        setBoxiaBoards((prevBoards) => [...prevBoards, newBoard]);
      }

      // Clear form and close modal
      setNewBoardName("");
      setNewBoardBackground(bgColors[0]);
      setIsCreateModalOpen(false);
      setIsCreateTrelloBoard(false);

      // Open the newly created board
      openBoard(newBoard);
    } catch (error) {
      console.error("Error creating board:", error);
      setError("Failed to create board. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const openBoard = (board) => {
    const newTab = {
      id: `board-${board.id}`,
      title: board.name,
      boardData: board,
    };
    setTabs([...tabs, newTab]);
    setActiveTab(newTab.id);
    setOpenBoards({ ...openBoards, [board.id]: board });
  };

  const BoardGrid = useCallback(
    ({
      boards,
      workspaceName,
      showTrelloLabel = false,
      onCreateBoard,
      isCreateTrelloBoard = false,
    }) => (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`rounded-2xl p-4 sm:p-6 md:p-8 mb-8 ${
          isDarkMode ? "bg-gray-800" : "bg-white shadow-lg"
        }`}
      >
        <h3 className="text-xl sm:text-2xl font-bold mb-4 sm:mb-6 md:mb-8">
          {workspaceName}
        </h3>
        <div className="grid grid-cols-1 xs:grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 sm:gap-6">
          {boards.map((board, index) => (
            <motion.div
              key={board.id}
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 0.97 }}
              onClick={() => openBoard(board)}
              className={`${
                board.background || bgColors[index % bgColors.length]
              } rounded-xl p-4 sm:p-6 relative overflow-hidden shadow-lg cursor-pointer transition-all duration-300 aspect-[16/6]`}
            >
              <h4 className="text-lg sm:text-xl font-semibold mb-2 text-white line-clamp-2">
                {board.name}
              </h4>
              {showTrelloLabel && (
                <span className="absolute top-2 right-2 text-xs bg-white text-gray-800 px-2 py-1 rounded-full font-medium shadow-sm">
                  Trello
                </span>
              )}
              {board.starred && (
                <StarIcon className="h-5 w-5 sm:h-6 sm:w-6 absolute bottom-2 right-2 text-yellow-300" />
              )}
              <div className="absolute inset-0 bg-black opacity-0 hover:opacity-10 transition-opacity duration-300" />
            </motion.div>
          ))}
          <motion.div
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.97 }}
            onClick={onCreateBoard}
            className={`rounded-xl p-4 sm:p-6 flex flex-col items-center justify-center cursor-pointer transition-all duration-300 aspect-[16/6] ${
              isDarkMode
                ? "bg-gray-700 hover:bg-gray-600"
                : "bg-gray-100 hover:bg-gray-200"
            }`}
          >
            <PlusIcon className="h-8 w-8 sm:h-10 sm:w-10 mb-2 sm:mb-3 text-indigo-500" />
            <span className="text-sm sm:text-base font-medium text-center">
              Create new board
            </span>
            {isCreateTrelloBoard && (
              <span className="mt-1 sm:mt-2 text-xs sm:text-sm text-gray-500">
                (Trello Board)
              </span>
            )}
          </motion.div>
        </div>
      </motion.div>
    ),
    [isDarkMode]
  );

  const CreateBoardModal = useMemo(
    () =>
      React.memo(() => {
        const inputRef = React.useRef(null);
        const [localNewBoardName, setLocalNewBoardName] =
          useState(newBoardName);
        const [localNewBoardBackground, setLocalNewBoardBackground] =
          useState(newBoardBackground);

        React.useEffect(() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }, []);

        const handleSubmit = (e) => {
          e.preventDefault();
          createBoard(
            localNewBoardName,
            localNewBoardBackground,
            isCreateTrelloBoard
          );
        };

        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 backdrop-blur-sm"
          >
            <motion.form
              initial={{ scale: 0.9, y: 20 }}
              animate={{ scale: 1, y: 0 }}
              exit={{ scale: 0.9, y: 20 }}
              onSubmit={handleSubmit}
              className={`rounded-2xl p-10 max-w-md w-full shadow-2xl transition-all duration-300 ${
                isDarkMode ? "bg-gray-800" : "bg-white"
              }`}
            >
              <div className="flex justify-between items-center mb-8">
                <h3 className="text-2xl font-bold">Create New Board</h3>
                <button
                  type="button"
                  onClick={() => {
                    setIsCreateModalOpen(false);
                    setIsCreateTrelloBoard(false);
                  }}
                  className="text-gray-400 hover:text-gray-600 transition-colors duration-200"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <input
                ref={inputRef}
                type="text"
                placeholder="Enter board name"
                value={localNewBoardName}
                onChange={(e) => setLocalNewBoardName(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                className={`w-full p-4 mb-8 border-2 rounded-xl focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-200 ${
                  isDarkMode
                    ? "bg-gray-700 border-gray-600 placeholder-gray-400"
                    : "bg-white border-gray-200 placeholder-gray-500"
                }`}
              />
              <div className="mb-8">
                <h4 className="text-xl font-semibold mb-4">
                  Choose Background
                </h4>
                <div className="grid grid-cols-3 gap-4">
                  {bgColors.map((color) => (
                    <div
                      key={color}
                      className={`${color} h-20 rounded-xl cursor-pointer transition-transform duration-200 hover:scale-105 ${
                        newBoardBackground === color
                          ? "ring-4 ring-indigo-500"
                          : ""
                      }`}
                      onClick={() => setNewBoardBackground(color)}
                    ></div>
                  ))}
                </div>
              </div>
              <button
                type="submit"
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-semibold py-4 px-6 rounded-xl transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 text-lg"
              >
                Create Board
              </button>
            </motion.form>
          </motion.div>
        );
      }),
    [isDarkMode, isCreateTrelloBoard, newBoardBackground]
  );

  const openNewTab = () => {
    const newTab = { id: `home-${Date.now()}`, title: "Home" };
    setTabs([...tabs, newTab]);
    setActiveTab(newTab.id);
  };

  const closeTab = (tabId) => {
    if (tabId === "home") return; // Prevent closing the home tab
    const newTabs = tabs.filter((tab) => tab.id !== tabId);
    setTabs(newTabs);
    if (activeTab === tabId) {
      setActiveTab(newTabs[newTabs.length - 1].id);
    }
  };

  const renderTabContent = (tab) => {
    if (tab.id.startsWith("home")) {
      return (
        <div
          className={`h-full overflow-y-auto p-8 ${
            isDarkMode
              ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900"
              : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100"
          }`}
        >
          <h1 className="text-4xl font-bold mb-10 text-indigo-600">Boards</h1>
          {starredBoards.length > 0 && (
            <section className="mb-12">
              <h2 className="text-3xl font-semibold mb-6 text-indigo-500">
                Starred Boards
              </h2>
              <BoardGrid
                boards={starredBoards}
                workspaceName="Starred"
                showTrelloLabel={true}
              />
            </section>
          )}
          <section>
            <h2 className="text-3xl font-semibold mb-6 text-indigo-500">
              Your Workspaces
            </h2>
            <BoardGrid
              boards={boxiaBoards}
              workspaceName="Boxia Workspace"
              onCreateBoard={() => {
                setIsCreateModalOpen(true);
                setIsCreateTrelloBoard(false);
              }}
            />
            <BoardGrid
              boards={trelloBoards}
              workspaceName="Trello Workspace"
              showTrelloLabel={true}
              onCreateBoard={() => {
                setIsCreateModalOpen(true);
                setIsCreateTrelloBoard(true);
              }}
              isCreateTrelloBoard={true}
            />
          </section>
        </div>
      );
    } else if (tab.boardData && tab.boardData.id) {
      const board = openBoards[tab.boardData.id];
      return <KanbanBoard board={board} isDarkMode={isDarkMode} />;
    }
  };

  const renderContent = () => {
    if (!userData) {
      return null;
    }

    return (
      <div className="flex flex-col h-screen">
        <TabContainer isDarkMode={isDarkMode}>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              id={tab.id}
              title={tab.title}
              isActive={activeTab === tab.id}
              onClick={() => setActiveTab(tab.id)}
              onClose={closeTab}
              isDarkMode={isDarkMode}
            />
          ))}
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={openNewTab}
            className={`ml-2 p-2 rounded-full transition-colors duration-200 ${
              isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-200"
            }`}
          >
            <PlusIcon className="w-5 h-5 text-indigo-500" />
          </motion.button>
        </TabContainer>
        <Suspense fallback={<LoadingSpinner />}>
          <div className="flex-grow overflow-hidden">
            {renderTabContent(tabs.find((tab) => tab.id === activeTab))}
          </div>
        </Suspense>
        <AnimatePresence>
          {isCreateModalOpen && <CreateBoardModal />}
        </AnimatePresence>
      </div>
    );
  };

  return (
    <div className={`h-screen ${mainContainerClass}`}>
      {renderContent()}
    </div>
  );
}

const LoadingSpinner = () => (
  <div className="flex justify-center items-center h-64">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-indigo-500"></div>
  </div>
);

export default React.memo(AppTasks);
