import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useIntersectionObserver } from "../hooks/useIntersectionObserver";

import googleLogo from "../assets/images/logos/google.svg";
import microsoftLogo from "../assets/images/logos/microsoft.svg";
import slackLogo from "../assets/images/logos/slack.svg";
import trelloLogo from "../assets/images/logos/trello.svg";
import githubLogo from "../assets/images/logos/github.svg";
import asanaLogo from "../assets/images/logos/asana.svg";
import zapierLogo from "../assets/images/logos/zapier.svg";
import dropboxLogo from "../assets/images/logos/dropbox.svg";

import BoxiaBoards from "../assets/images/Boxia Boards.png";

const FeatureCard = ({ feature, darkMode, index }) => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <div
      ref={ref}
      className={`p-8 rounded-2xl shadow-lg transition-all duration-500 hover:shadow-2xl group relative overflow-hidden ${
        darkMode ? "bg-gray-800" : "bg-white"
      } ${
        isVisible ? "opacity-100 translate-y-0" : "opacity-0 translate-y-10"
      }`}
      style={{ transitionDelay: `${index * 100}ms` }}
    >
      <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-600 to-purple-600 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100"></div>
      <div className="flex items-center mb-6">
        <div
          className={`text-4xl rounded-full w-16 h-16 flex items-center justify-center mr-4 transition-all duration-300 group-hover:scale-110 ${
            darkMode ? "bg-indigo-900" : "bg-indigo-100"
          }`}
        >
          {feature.icon}
        </div>
        <h3
          className={`text-xl font-semibold transition-opacity duration-300 ${
            darkMode ? "text-gray-200" : "text-gray-800"
          }`}
        >
          {feature.title}
        </h3>
      </div>
      <p
        className={`transition-opacity duration-300 ${
          darkMode ? "text-gray-400" : "text-gray-600"
        }`}
      >
        {feature.description}
      </p>
    </div>
  );
};

function FeaturesPage() {
  const [darkMode, setDarkMode] = useState(false);
  const [logoFade, setLogoFade] = useState(false);

  const integrations = [
    { name: "Google", logo: googleLogo },
    { name: "Microsoft", logo: microsoftLogo },
    { name: "Slack", logo: slackLogo },
    { name: "Trello", logo: trelloLogo },
    { name: "GitHub", logo: githubLogo },
    { name: "Asana", logo: asanaLogo },
    { name: "Zapier", logo: zapierLogo },
    { name: "Dropbox", logo: dropboxLogo },
  ];

  const [taskManagementRef, isTaskManagementVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [noteTakingRef, isNoteTakingVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [calendarManagementRef, isCalendarManagementVisible] =
    useIntersectionObserver({ threshold: 0.1 });
  const [fileStorageRef, isFileStorageVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [integrationRef, isIntegrationVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [securityRef, isSecurityVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  useEffect(() => {
    document.documentElement.classList.toggle("dark", darkMode);
  }, [darkMode]);

  const handleDarkModeToggle = () => {
    setLogoFade(true);
    setTimeout(() => {
      setDarkMode(!darkMode);
      setLogoFade(false);
    }, 150);
  };

  return (
    <main
      className={`flex-grow overflow-y-auto transition-colors duration-300 ${
        darkMode ? "dark text-white" : "text-gray-800"
      } ${
        darkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      <NavBar darkMode={darkMode} logoFade={logoFade} />

      {/* Hero Section */}
      <section className="pt-[200px] py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1
            className={`text-5xl sm:text-6xl md:text-7xl font-bold mb-8 leading-tight ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Powerful Features for{" "}
            <span
              className={`bg-clip-text text-transparent bg-gradient-to-r ${
                darkMode
                  ? "bg-gradient-to-br from-blue-500 to-purple-600"
                  : "bg-gradient-to-br from-teal-400 to-indigo-500"
              }`}
            >
              Unmatched Productivity
            </span>
          </h1>
          <p
            className={`text-xl sm:text-2xl mb-8 max-w-2xl mx-auto ${
              darkMode ? "text-indigo-300" : "text-indigo-700"
            }`}
          >
            Discover how Boxia's cutting-edge features can revolutionize your
            workflow and boost your efficiency.
          </p>
        </div>
      </section>

      {/* Features Showcase */}
      <section className={`py-24 ${darkMode ? "bg-gray-900" : "bg-gray-50"}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2
            className={`text-4xl font-bold text-center mb-16 ${
              darkMode ? "text-white" : "text-gray-900"
            }`}
          >
            Powerful Features for Unmatched Productivity
          </h2>

          {/* Task Management */}
          <div
            ref={taskManagementRef}
            className={`mb-24 transition-all duration-1000 ${
              isTaskManagementVisible
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h3
                  className={`text-2xl font-semibold mb-4 ${
                    darkMode ? "text-indigo-400" : "text-indigo-600"
                  }`}
                >
                  Advanced Task Management
                </h3>
                <p
                  className={`text-lg mb-6 ${
                    darkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  Stay organized and focused with our AI-powered task management
                  system.
                </p>
                <ul className="space-y-3">
                  {[
                    "AI-driven prioritization",
                    "Custom fields and labels",
                    "Recurring tasks and reminders",
                    "Smart deadlines and scheduling",
                    "Task dependencies and subtasks",
                    "Collaborative task assignment",
                  ].map((feature, index) => (
                    <li
                      key={index}
                      className={`flex items-center ${
                        darkMode ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      <svg
                        className="h-5 w-5 text-green-500 mr-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative h-64 md:h-auto">
                <div
                  className={`absolute inset-0 ${
                    darkMode ? "bg-indigo-900" : "bg-indigo-100"
                  } rounded-lg transform -rotate-6`}
                ></div>
                <img
                  className="relative rounded-lg shadow-xl"
                  src={BoxiaBoards}
                  alt="Task Management Interface"
                />
              </div>
            </div>
          </div>

          {/* Note-Taking */}
          <div
            ref={noteTakingRef}
            className={`mb-24 transition-all duration-1000 ${
              isNoteTakingVisible
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div className="order-2 md:order-1 relative h-64 md:h-auto">
                <div
                  className={`absolute inset-0 ${
                    darkMode ? "bg-purple-900" : "bg-purple-100"
                  } rounded-lg transform rotate-6`}
                ></div>
                <img
                  className="relative rounded-lg shadow-xl"
                  src="/features/note-taking.png"
                  alt="Note-Taking Interface"
                />
              </div>
              <div className="order-1 md:order-2">
                <h3
                  className={`text-2xl font-semibold mb-4 ${
                    darkMode ? "text-purple-400" : "text-purple-600"
                  }`}
                >
                  Intelligent Note-Taking
                </h3>
                <p
                  className={`text-lg mb-6 ${
                    darkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  Capture and organize ideas effortlessly with our AI-driven
                  note-taking system.
                </p>
                <ul className="space-y-3">
                  {[
                    "OCR for images and handwriting",
                    "AI-powered organization",
                    "Rich text editing",
                    "Multimedia support",
                  ].map((feature, index) => (
                    <li
                      key={index}
                      className={`flex items-center ${
                        darkMode ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      <svg
                        className="h-5 w-5 text-green-500 mr-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Calendar Management */}
          <div
            ref={calendarManagementRef}
            className={`mb-24 transition-all duration-1000 ${
              isCalendarManagementVisible
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            <h3
              className={`text-2xl font-semibold text-center mb-8 ${
                darkMode ? "text-blue-400" : "text-blue-600"
              }`}
            >
              AI-Powered Calendar Management
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                { title: "Smart Scheduling", icon: "🧠" },
                { title: "Conflict Resolution", icon: "🔀" },
                { title: "Time Blocking", icon: "⏱️" },
                { title: "Task Integration", icon: "🔗" },
                { title: "Team Collaboration", icon: "👥" },
                { title: "Availability Sharing", icon: "📅" },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-lg ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } shadow-lg`}
                >
                  <div className="text-4xl mb-4">{feature.icon}</div>
                  <h4
                    className={`text-xl font-medium mb-2 ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {feature.title}
                  </h4>
                  <p
                    className={`${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Optimize your schedule with AI-driven insights and
                    management.
                  </p>
                </div>
              ))}
            </div>
          </div>

          {/* File Storage */}
          <div
            ref={fileStorageRef}
            className={`mb-24 transition-all duration-1000 ${
              isFileStorageVisible
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            <div className="grid md:grid-cols-2 gap-12 items-center">
              <div>
                <h3
                  className={`text-2xl font-semibold mb-4 ${
                    darkMode ? "text-green-400" : "text-green-600"
                  }`}
                >
                  Unified File Storage
                </h3>
                <p
                  className={`text-lg mb-6 ${
                    darkMode ? "text-gray-300" : "text-gray-600"
                  }`}
                >
                  Keep all your important files in one place with our
                  AI-enhanced file management system.
                </p>
                <ul className="space-y-3">
                  {[
                    "AI-driven file recommendations",
                    "Automatic tagging",
                    "Advanced search",
                    "Version control",
                  ].map((feature, index) => (
                    <li
                      key={index}
                      className={`flex items-center ${
                        darkMode ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      <svg
                        className="h-5 w-5 text-green-500 mr-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="relative h-64 md:h-auto">
                <div
                  className={`absolute inset-0 ${
                    darkMode ? "bg-green-900" : "bg-green-100"
                  } rounded-lg transform -rotate-6`}
                ></div>
                <img
                  className="relative rounded-lg shadow-xl"
                  src="/features/file-storage.png"
                  alt="File Storage Interface"
                />
              </div>
            </div>
          </div>

          {/* Integration Ecosystem Section */}
          <section
            className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 rounded-3xl ${
              darkMode
                ? "bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900"
                : "bg-gradient-to-b from-purple-50 via-white to-indigo-50"
            }`}
          >
            <div className="max-w-7xl mx-auto">
              <h2
                className={`text-4xl md:text-5xl font-bold text-center mb-4 ${
                  darkMode ? "text-indigo-200" : "text-indigo-900"
                }`}
              >
                Seamless Integration Ecosystem
              </h2>
              <p
                className={`text-xl text-center mb-16 max-w-3xl mx-auto ${
                  darkMode ? "text-indigo-300" : "text-indigo-700"
                }`}
              >
                Connect Your Digital Life in One Powerful Hub
              </p>

              {/* Integration Grid */}
              <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                {integrations.map((integration, index) => (
                  <div
                    key={index}
                    className={`p-6 rounded-lg text-center transition-all duration-300 ${
                      darkMode
                        ? "bg-gray-800 hover:bg-gray-700"
                        : "bg-white hover:shadow-lg"
                    }`}
                  >
                    <img
                      src={integration.logo}
                      alt={`${integration.name} logo`}
                      className={`h-16 mx-auto mb-4 transition-all duration-300 ${
                        darkMode
                          ? "filter invert brightness-0 contrast-100 opacity-70 hover:opacity-100"
                          : "opacity-70 hover:opacity-100"
                      }`}
                    />
                    <p
                      className={`font-medium ${
                        darkMode ? "text-gray-300" : "text-gray-700"
                      }`}
                    >
                      {integration.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </section>

          {/* Security and Privacy */}
          <div
            ref={securityRef}
            className={`transition-all duration-1000 mt-[50px] ${
              isSecurityVisible
                ? "opacity-100 translate-y-0"
                : "opacity-0 translate-y-10"
            }`}
          >
            <h3
              className={`text-2xl font-semibold text-center mb-8 ${
                darkMode ? "text-gray-200" : "text-gray-800"
              }`}
            >
              Advanced Security and Privacy
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {[
                { title: "End-to-end Encryption", icon: "🔒" },
                { title: "Granular Privacy Controls", icon: "🛡️" },
                { title: "Custom Data Retention", icon: "⏳" },
                { title: "GDPR Compliance", icon: "📜" },
                { title: "Regular Security Audits", icon: "🔍" },
                { title: "Two-Factor Authentication", icon: "🔐" },
              ].map((feature, index) => (
                <div
                  key={index}
                  className={`p-6 rounded-lg ${
                    darkMode ? "bg-gray-800" : "bg-white"
                  } shadow-lg`}
                >
                  <div className="text-4xl mb-4">{feature.icon}</div>
                  <h4
                    className={`text-xl font-medium mb-2 ${
                      darkMode ? "text-gray-200" : "text-gray-800"
                    }`}
                  >
                    {feature.title}
                  </h4>
                  <p
                    className={`${
                      darkMode ? "text-gray-400" : "text-gray-600"
                    }`}
                  >
                    Your data's security is our top priority.
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="py-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2
            className={`text-4xl md:text-5xl font-bold text-center mb-4 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            All Features at a Glance
          </h2>
          <p
            className={`text-xl text-center mb-16 max-w-3xl mx-auto ${
              darkMode ? "text-indigo-300" : "text-indigo-700"
            }`}
          >
            Explore the full range of Boxia's powerful features designed to
            elevate your productivity.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              {
                icon: "📋",
                title: "Advanced Task Management",
                description:
                  "Create, organize, and prioritize tasks with AI assistance",
              },
              {
                icon: "📝",
                title: "Smart Note-taking",
                description:
                  "Capture and organize ideas with AI-driven insights",
              },
              {
                icon: "📅",
                title: "Intelligent Calendar",
                description: "AI-optimized scheduling and time management",
              },
              {
                icon: "📁",
                title: "Unified File Storage",
                description:
                  "Centralized file management with predictive suggestions",
              },
              {
                icon: "🤖",
                title: "AI-Powered Assistance",
                description:
                  "Personalized productivity insights and recommendations",
              },
              {
                icon: "🔗",
                title: "Robust Integration Ecosystem",
                description: "Connect with a wide array of third-party tools",
              },
              {
                icon: "🔒",
                title: "Advanced Security & Privacy",
                description:
                  "End-to-end encryption and granular privacy controls",
              },
              {
                icon: "💬",
                title: "Unified Messaging Hub",
                description:
                  "Centralize communications across multiple platforms",
              },
              {
                icon: "🎨",
                title: "Customizable Interface",
                description: "Tailor Boxia to fit your unique workflow",
              },
              {
                icon: "📊",
                title: "Comprehensive Analytics",
                description: "Gain insights into your productivity and habits",
              },
              {
                icon: "🌐",
                title: "Cross-Platform Sync",
                description: "Access your data seamlessly across all devices",
              },
              {
                icon: "🔍",
                title: "Advanced Search & Retrieval",
                description:
                  "Find what you need instantly with AI-powered search",
              },
            ].map((feature, index) => (
              <FeatureCard
                key={index}
                feature={feature}
                darkMode={darkMode}
                index={index}
              />
            ))}
          </div>
        </div>
      </section>

      {/* Final CTA Section */}
      <section
        className={`py-24 px-4 sm:px-6 lg:px-8 transition-colors duration-300 ${
          darkMode
            ? "bg-gradient-to-b from-gray-800 via-gray-850 to-gray-900"
            : "bg-gradient-to-b from-indigo-100 via-white to-indigo-50"
        }`}
      >
        <div className="max-w-7xl mx-auto text-center">
          <h2
            className={`text-4xl md:text-5xl font-bold mb-6 ${
              darkMode ? "text-indigo-200" : "text-indigo-900"
            }`}
          >
            Ready to Boost Your Productivity?
          </h2>
          <p
            className={`text-xl mb-12 max-w-3xl mx-auto ${
              darkMode ? "text-indigo-300" : "text-indigo-700"
            }`}
          >
            Join thousands of professionals who have transformed their workflow
            with Boxia. Start your journey to enhanced productivity today.
          </p>
          <div className="flex flex-col sm:flex-row justify-center items-center gap-6">
            <button
              onClick={() => (window.location.href = "/signup")}
              className="w-full sm:w-auto px-8 py-4 bg-indigo-600 hover:bg-indigo-700 text-white rounded-full text-lg font-semibold transition-all duration-200 shadow-lg hover:shadow-xl"
            >
              Start Free Trial
            </button>
            {/* <button
              className={`w-full sm:w-auto px-8 py-4 border-2 border-indigo-600 rounded-full text-lg font-semibold transition-all duration-200 ${
                darkMode
                  ? "text-indigo-400 hover:bg-indigo-900"
                  : "text-indigo-600 hover:bg-indigo-50"
              }`}
            >
              Schedule a Demo
            </button> */}
          </div>
        </div>
      </section>

      <Footer darkMode={darkMode} />
    </main>
  );
}

export default FeaturesPage;
