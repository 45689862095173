import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import { UserContext } from "../context/UserContext";
import { DarkModeContext } from "../context/DarkModeContext";
import { auth, db } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
  startAfter,
} from "firebase/firestore";
import {
  FaPlus,
  FaSearch,
  FaBook,
  FaStar,
  FaTag,
  FaTrash,
  FaCog,
} from "react-icons/fa";
import debounce from "lodash/debounce";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import {
  FaStar as FaStarSolid,
  FaRegStar as FaStarOutline,
} from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { FiX } from "react-icons/fi";
import { FiMinimize2, FiMaximize2 } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaListUl,
  FaListOl,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaIndent,
  FaOutdent,
  FaPalette,
  FaHighlighter,
  FaFont,
  FaUndo,
  FaRedo,
} from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";

const NOTES_PER_PAGE = 30;

function stripHtml(html) {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || "";
}

function AppNotes() {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData } = useContext(UserContext);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [activeSection, setActiveSection] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const queryClient = useQueryClient();
  const observerTarget = useRef(null);
  const [tabs, setTabs] = useState([
    { id: "home", title: "All Notes", section: "all", note: null },
  ]);
  const [activeTab, setActiveTab] = useState("home");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const fetchNotes = useCallback(
    async ({ pageParam = null }) => {
      if (!user) return { notes: [], nextCursor: null };

      const notesRef = collection(db, `users/${user.uid}/notes`);
      let q = query(
        notesRef,
        where("isDeleted", "==", activeSection === "trash"),
        orderBy("updatedAt", "desc"),
        limit(NOTES_PER_PAGE)
      );

      // Add conditions for starred and tags sections
      if (activeSection === "starred") {
        q = query(q, where("isStarred", "==", true));
      } else if (activeSection === "tags") {
        q = query(q, where("tags", "array-contains", searchTerm));
      }

      if (pageParam) {
        q = query(q, startAfter(pageParam));
      }

      const querySnapshot = await getDocs(q);
      const fetchedNotes = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const nextCursor = querySnapshot.docs[querySnapshot.docs.length - 1];
      return { notes: fetchedNotes, nextCursor };
    },
    [user, activeSection, searchTerm] // Add searchTerm to dependencies
  );

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, status } =
    useInfiniteQuery(["notes", activeSection, searchTerm], fetchNotes, {
      getNextPageParam: (lastPage) => lastPage.nextCursor || undefined,
      enabled: !!user,
    });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1.0 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  const createNoteMutation = useMutation(
    async (newNote) => {
      const docRef = await addDoc(
        collection(db, `users/${user.uid}/notes`),
        newNote
      );
      return { id: docRef.id, ...newNote };
    },
    {
      onSuccess: (newNote) => {
        queryClient.setQueryData(
          ["notes", activeSection, searchTerm],
          (oldData) => {
            const newPages = oldData.pages.map((page, index) => {
              if (index === 0) {
                return { ...page, notes: [newNote, ...page.notes] };
              }
              return page;
            });
            return { ...oldData, pages: newPages };
          }
        );
      },
    }
  );

  const formatDate = (date) => {
    if (date instanceof Date) {
      return date.toLocaleDateString();
    } else if (date && typeof date.toDate === "function") {
      return date.toDate().toLocaleDateString();
    } else if (date && date.seconds) {
      return new Date(date.seconds * 1000).toLocaleDateString();
    }
    return "Unknown date";
  };

  const updateNoteMutation = useMutation(
    async ({ noteId, updatedFields }) => {
      const noteRef = doc(db, `users/${user.uid}/notes`, noteId);
      await updateDoc(noteRef, { ...updatedFields, updatedAt: new Date() });
      return { id: noteId, ...updatedFields };
    },
    {
      onSuccess: (updatedNote) => {
        queryClient.setQueryData(
          ["notes", activeSection, searchTerm],
          (oldData) => {
            const newPages = oldData.pages.map((page) => ({
              ...page,
              notes: page.notes.map((note) =>
                note.id === updatedNote.id ? { ...note, ...updatedNote } : note
              ),
            }));
            return { ...oldData, pages: newPages };
          }
        );
      },
    }
  );

  const deleteNoteMutation = useMutation(
    async (noteId) => {
      const noteRef = doc(db, `users/${user.uid}/notes`, noteId);
      await updateDoc(noteRef, { isDeleted: true });
      return noteId;
    },
    {
      onSuccess: (deletedNoteId) => {
        queryClient.setQueryData(
          ["notes", activeSection, searchTerm],
          (oldData) => {
            const newPages = oldData.pages.map((page) => ({
              ...page,
              notes: page.notes.filter((note) => note.id !== deletedNoteId),
            }));
            return { ...oldData, pages: newPages };
          }
        );
      },
    }
  );

  const createNote = () => {
    const newNote = {
      title: "Untitled Note",
      content: "",
      createdAt: new Date(),
      updatedAt: new Date(),
      isStarred: false,
      tags: [],
      isDeleted: false,
    };
    createNoteMutation.mutate(newNote);
  };

  const updateNote = (noteId, updatedFields) => {
    updateNoteMutation.mutate({ noteId, updatedFields });

    // Update the note in the tabs state
    setTabs((prevTabs) =>
      prevTabs.map((tab) =>
        tab.note && tab.note.id === noteId
          ? {
              ...tab,
              note: { ...tab.note, ...updatedFields },
              title: updatedFields.title || tab.title,
            }
          : tab
      )
    );
  };

  const deleteNote = (noteId) => {
    deleteNoteMutation.mutate(noteId);
  };

  const debouncedSearch = useCallback(
    debounce((value) => setSearchTerm(value), 300),
    []
  );

  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  const filteredNotes =
    data?.pages
      .flatMap((page) => page.notes)
      .filter((note) => {
        if (activeSection === "tags") {
          return note.tags.some((tag) =>
            tag.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return (
          note.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          note.content.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }) || [];

  const toggleStarredNote = (noteId, currentStarredState) => {
    updateNoteMutation.mutate({
      noteId,
      updatedFields: { isStarred: !currentStarredState },
    });
  };

  const sidebarSections = [
    { id: "all", name: "All Notes", icon: FaBook },
    { id: "starred", name: "Starred", icon: FaStar },
    { id: "tags", name: "Tags", icon: FaTag },
    { id: "trash", name: "Trash", icon: FaTrash },
  ];

  const openNewTab = () => {
    const newTabId = `tab-${Date.now()}`;
    const newTab = {
      id: newTabId,
      title: "All Notes",
      section: "all",
      note: null,
    };
    setTabs([...tabs, newTab]);
    setActiveTab(newTabId);
  };

  const openNote = (note) => {
    const existingTab = tabs.find((tab) => tab.note && tab.note.id === note.id);
    if (existingTab) {
      setActiveTab(existingTab.id);
    } else {
      const newTabId = `tab-${Date.now()}`;
      const newTab = {
        id: newTabId,
        title: note.title,
        section: "note",
        note: { ...note }, // Create a new object to avoid reference issues
      };
      setTabs([...tabs, newTab]);
      setActiveTab(newTabId);
    }
  };

  const closeTab = (tabId) => {
    if (tabId === "home") return;
    const newTabs = tabs.filter((tab) => tab.id !== tabId);
    setTabs(newTabs);
    if (activeTab === tabId) {
      setActiveTab(newTabs[newTabs.length - 1].id);
    }
  };

  const updateTabTitle = (tabId, newTitle) => {
    setTabs(
      tabs.map((tab) => (tab.id === tabId ? { ...tab, title: newTitle } : tab))
    );
  };

  const TabContainer = ({ children }) => {
    return (
      <div className="flex items-center overflow-x-auto py-2 px-4">
        {children}
      </div>
    );
  };

  const Tab = ({ id, title, isActive, onClick, onClose }) => {
    const handleMouseDown = (e) => {
      if (e.button === 1) {
        e.preventDefault();
        onClose(id);
      }
    };

    return (
      <div
        className={`flex items-center px-4 py-3 rounded-lg mr-3 cursor-pointer transition-all duration-300 ${
          isActive
            ? "bg-blue-600 text-white shadow-md"
            : "bg-white text-gray-700 hover:bg-gray-100"
        }`}
        onClick={onClick}
        onMouseDown={handleMouseDown}
      >
        <span className="truncate max-w-xs font-medium">{title}</span>
        {id !== "home" && (
          <button
            className={`ml-3 p-1 rounded-full transition-colors duration-200 ${
              isActive ? "hover:bg-blue-700" : "hover:bg-gray-200"
            }`}
            onClick={(e) => {
              e.stopPropagation();
              onClose(id);
            }}
          >
            <FiX className="w-4 h-4" />
          </button>
        )}
      </div>
    );
  };

  const renderTabContent = (tab) => {
    if (tab.section === "note") {
      return (
        <NoteEditor
          key={tab.id} // Add a key to ensure a new instance for each tab
          note={tab.note}
          updateNote={updateNote}
          isDarkMode={isDarkMode}
          onClose={() => closeTab(tab.id)}
        />
      );
    }

    return (
      <>
        <header className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold">
            {tab.section === "all" && "All Notes"}
            {tab.section === "starred" && "Starred Notes"}
            {tab.section === "tags" && "Tags"}
            {tab.section === "trash" && "Trash"}
          </h1>
          <div className="relative">
            <input
              type="text"
              placeholder={
                activeSection === "tags" ? "Filter tags..." : "Search notes..."
              }
              onChange={handleSearchChange}
              className={`pl-10 pr-4 py-2 rounded-full border ${
                isDarkMode
                  ? "border-gray-700 bg-gray-800 text-white"
                  : "border-gray-300 bg-gray-100 text-gray-800"
              } focus:ou  tline-none focus:ring-2 focus:ring-blue-500`}
            />
            <FaSearch
              className={`absolute left-3 top-1/2 transform -translate-y-1/2 ${
                isDarkMode ? "text-gray-400" : "text-gray-500"
              }`}
            />
          </div>
        </header>

        {status === "loading" ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
          </div>
        ) : status === "error" ? (
          <div className="text-center text-red-500">Error loading notes</div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {filteredNotes.map((note) => (
              <div
                key={note.id}
                className={`p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 ${
                  isDarkMode ? "bg-gray-800" : "bg-white"
                } cursor-pointer`}
                onClick={() => openNote(note)}
              >
                <h3 className="text-lg font-semibold mb-2">{note.title}</h3>
                <p
                  className={`mb-4 ${
                    isDarkMode ? "text-gray-300" : "text-gray-600"
                  } overflow-hidden text-ellipsis`}
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: "vertical",
                    wordWrap: "break-word",
                  }}
                >
                  {stripHtml(note.content)}
                </p>
                <div className="flex flex-wrap gap-2 mb-4">
                  {note.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>
                <div className="flex justify-between items-center">
                  <span
                    className={`text-sm ${
                      isDarkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                  >
                    {formatDate(note.updatedAt)}
                  </span>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleStarredNote(note.id, note.isStarred);
                    }}
                    className={`text-yellow-500 hover:text-yellow-600 transition-colors duration-200`}
                    aria-label={note.isStarred ? "Unstar note" : "Star note"}
                  >
                    {note.isStarred ? (
                      <FaStarSolid className="w-5 h-5" />
                    ) : (
                      <FaStarOutline className="w-5 h-5" />
                    )}
                  </button>
                </div>
              </div>
            ))}
            {isFetchingNextPage && (
              <div className="col-span-full flex justify-center items-center py-4">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            )}
            <div ref={observerTarget} />
          </div>
        )}
      </>
    );
  };

  if (!user) {
    return null; // or a loading spinner
  }

  return (
    <div
      className={`flex min-h-screen w-full ${
        isDarkMode
          ? "bg-gradient-to-br from-gray-700 via-gray-800 to-gray-900 text-gray-100"
          : "bg-gradient-to-br from-green-100 via-blue-100 to-purple-100 text-gray-900"
      }`}
    >
      <div className="flex flex-1">
        {/* Sidebar */}
        <aside
          className={`w-72 h-full overflow-y-auto transition-all duration-300 ease-in-out`}
        >
          <div className="p-4">
            <button
              className="w-full bg-blue-600 hover:bg-blue-800 text-white px-4 py-2 rounded-lg flex items-center justify-center transition-all duration-200 shadow-md hover:shadow-lg"
              onClick={createNote}
            >
              <FaPlus className="mr-2" />
              <span className="font-medium">New Note</span>
            </button>
          </div>
          <nav className="mt-5">
            {sidebarSections.map((section) => (
              <button
                key={section.id}
                onClick={() => setActiveSection(section.id)}
                className={`w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center mb-2 ${
                  activeSection === section.id
                    ? isDarkMode
                      ? "bg-blue-600 text-white"
                      : "bg-blue-50 text-blue-700"
                    : isDarkMode
                    ? "text-gray-300 hover:bg-gray-800"
                    : "text-gray-700 hover:bg-gray-100"
                }`}
              >
                <section.icon className="mr-3" size={18} />
                <span className="font-medium">{section.name}</span>
              </button>
            ))}
          </nav>
          <div className="mt-auto p-4">
            <button
              className={`w-full flex items-center justify-center py-2 px-4 rounded-lg ${
                isDarkMode
                  ? "bg-gray-800 text-gray-300 hover:bg-gray-700"
                  : "bg-gray-100 text-gray-700 hover:bg-gray-200"
              } transition-all duration-200`}
              onClick={() => {
                /* Open settings */
              }}
            >
              <FaCog className="mr-2" />
              <span>Settings</span>
            </button>
          </div>
        </aside>

        {/* Main content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <div>
            <TabContainer>
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  id={tab.id}
                  title={tab.title}
                  isActive={activeTab === tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  onClose={closeTab}
                />
              ))}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={openNewTab}
                className={`ml-2 p-2 rounded-full transition-colors duration-200 ${
                  isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-200"
                }`}
              >
                <FaPlus className="w-5 h-5 text-blue-500" />
              </motion.button>
            </TabContainer>
          </div>
          <main className="flex-1 p-8 overflow-y-auto">
            {renderTabContent(tabs.find((tab) => tab.id === activeTab))}
          </main>
        </div>
      </div>
    </div>
  );
}

const NoteEditor = ({ note, updateNote, isDarkMode, onClose }) => {
  const [title, setTitle] = useState(note.title);
  const [content, setContent] = useState(note.content);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const editorRef = useRef(null);

  const handleSave = useCallback(
    debounce((newTitle, newContent) => {
      updateNote(note.id, { title: newTitle, content: newContent });
    }, 500),
    [note.id, updateNote]
  );

  const handleStarToggle = () => {
    updateNote(note.id, { isStarred: !note.isStarred });
  };

  const handleShareNote = () => {
    console.log("Sharing note:", note.id);
    // Implement your sharing logic here
  };

  useEffect(() => {
    const editor = editorRef.current;
    if (editor) {
      editor.innerHTML = content;
    }
  }, [note.id]); // Only update when the note changes

  const applyFormat = (command, value = null) => {
    document.execCommand(command, false, value);
    editorRef.current.focus();
  };

  const handleHeaderChange = (e) => {
    const value = e.target.value;
    applyFormat("formatBlock", value === "normal" ? "<p>" : `<${value}>`);
  };

  const handleFontChange = (e) => {
    applyFormat("fontName", e.target.value);
  };

  const handleSizeChange = (e) => {
    applyFormat("fontSize", e.target.value);
  };

  const handleAlignmentChange = (alignment) => {
    applyFormat(
      `justify${alignment.charAt(0).toUpperCase() + alignment.slice(1)}`
    );
  };

  const handleInput = (e) => {
    const newContent = e.target.innerHTML;
    handleSave(title, newContent);
  };

  return (
    <div
      className={`flex flex-col h-full ${
        isFullScreen ? "fixed inset-0 z-50" : ""
      } ${isDarkMode ? "bg-gray-900 text-white" : "bg-white text-gray-900"}`}
    >
      {/* Top bar */}
      <div
        className={`flex flex-col ${
          isDarkMode ? "bg-gray-800" : "bg-white"
        } shadow-md rounded-lg`}
      >
        {/* Title and main controls */}
        <div className="flex items-center justify-between p-2 rounded-lg">
          <div className="flex items-center space-x-2 flex-grow">
            <input
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                handleSave(e.target.value, content);
              }}
              className={`text-xl font-semibold bg-transparent focus:outline-none transition-colors duration-200 w-full ${
                isDarkMode ? "text-white" : "text-gray-900"
              }`}
              placeholder="Untitled Note"
            />
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={handleStarToggle}
              className={`p-1 rounded-full transition-colors duration-200 ${
                isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
              }`}
              aria-label={note.isStarred ? "Unstar note" : "Star note"}
            >
              {note.isStarred ? (
                <FaStarSolid className="w-5 h-5 text-yellow-400" />
              ) : (
                <FaStarOutline className="w-5 h-5 text-gray-400" />
              )}
            </button>
            <button
              onClick={handleShareNote}
              className={`px-3 py-1 rounded-lg text-sm font-medium transition-colors duration-200 ${
                isDarkMode
                  ? "bg-blue-600 hover:bg-blue-700 text-white"
                  : "bg-blue-100 hover:bg-blue-200 text-blue-800"
              }`}
            >
              Share
            </button>
            <button
              onClick={() => setIsFullScreen(!isFullScreen)}
              className={`p-2 rounded-lg transition-colors duration-200 ${
                isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
              }`}
              aria-label={
                isFullScreen ? "Exit full screen" : "Enter full screen"
              }
            >
              {isFullScreen ? (
                <FiMinimize2 className="w-4 h-4" />
              ) : (
                <FiMaximize2 className="w-4 h-4" />
              )}
            </button>
            <button
              onClick={onClose}
              className={`p-2 rounded-lg transition-colors duration-200 ${
                isDarkMode ? "hover:bg-gray-700" : "hover:bg-gray-100"
              }`}
              aria-label="Close note"
            >
              <FiX className="w-4 h-4" />
            </button>
          </div>
        </div>

        {/* Toolbar */}
        <div className={`flex items-center p-2 space-x-2 rounded-lg`}>
          <select
            onChange={handleHeaderChange}
            className={`p-1 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 transition-colors duration-200 ${
              isDarkMode
                ? "bg-gray-700 text-white"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            <option value="normal">Paragraph</option>
            <option value="h1">Heading 1</option>
            <option value="h2">Heading 2</option>
            <option value="h3">Heading 3</option>
          </select>
          <select
            onChange={handleFontChange}
            className={`p-1 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 transition-colors duration-200 ${
              isDarkMode
                ? "bg-gray-700 text-white"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            <option value="Arial">Arial</option>
            <option value="Times New Roman">Times New Roman</option>
            <option value="Calibri">Calibri</option>
          </select>
          <select
            onChange={handleSizeChange}
            className={`p-1 rounded-lg text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 transition-colors duration-200 ${
              isDarkMode
                ? "bg-gray-700 text-white"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            <option value="1">Small</option>
            <option value="3">Normal</option>
            <option value="5">Large</option>
            <option value="7">Huge</option>
          </select>
          <div className="flex items-center space-x-1 px-2 mx-2">
            {[
              { icon: FaBold, command: "bold" },
              { icon: FaItalic, command: "italic" },
              { icon: FaUnderline, command: "underline" },
            ].map(({ icon: Icon, command }, index) => (
              <button
                key={index}
                onClick={() => applyFormat(command)}
                className={`p-2 rounded-lg transition-colors duration-200 ${
                  isDarkMode
                    ? "text-gray-300 hover:bg-gray-700"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                <Icon className="w-4 h-4" />
              </button>
            ))}
          </div>
          <div className="flex items-center space-x-1">
            {[
              { icon: FaListUl, command: "insertUnorderedList" },
              { icon: FaListOl, command: "insertOrderedList" },
            ].map(({ icon: Icon, command }, index) => (
              <button
                key={index}
                onClick={() => applyFormat(command)}
                className={`p-2 rounded-lg transition-colors duration-200 ${
                  isDarkMode
                    ? "text-gray-300 hover:bg-gray-700"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                <Icon className="w-4 h-4" />
              </button>
            ))}
          </div>
          <div className="flex items-center space-x-1 px-2 mx-2">
            {[
              { icon: FaAlignLeft, alignment: "left" },
              { icon: FaAlignCenter, alignment: "center" },
              { icon: FaAlignRight, alignment: "right" },
              { icon: FaAlignJustify, alignment: "full" },
            ].map(({ icon: Icon, alignment }, index) => (
              <button
                key={index}
                onClick={() => handleAlignmentChange(alignment)}
                className={`p-2 rounded-lg transition-colors duration-200 ${
                  isDarkMode
                    ? "text-gray-300 hover:bg-gray-700"
                    : "text-gray-600 hover:bg-gray-100"
                }`}
              >
                <Icon className="w-4 h-4" />
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Note content */}
      <div className="flex-grow overflow-auto p-4">
        <div
          className={`max-w-4xl mx-auto p-8 ${
            isDarkMode ? "bg-gray-900" : "bg-white"
          } rounded-xl transition-colors duration-200 shadow-lg border ${
            isDarkMode ? "border-gray-700" : "border-gray-200"
          }`}
          style={{
            minHeight: "calc(100vh - 200px)",
            transform: "translateY(-4px)",
            transition: "transform 0.3s ease-in-out",
          }}
        >
          <div
            ref={editorRef}
            contentEditable
            onInput={handleInput}
            className={`h-full outline-none ${
              isDarkMode ? "text-gray-200" : "text-gray-800"
            }`}
            style={{ minHeight: "100%" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AppNotes;
