import { getFunctions, httpsCallable } from "firebase/functions";
import { db } from "../firebase/config";
import {
  doc,
  setDoc,
  collection,
  query,
  getDocs,
  deleteDoc,
  updateDoc,
  writeBatch,
} from "firebase/firestore";

const functions = getFunctions();

export async function syncTrelloBoards(userId) {
  try {
    const fetchTrelloBoards = httpsCallable(functions, "fetchTrelloBoards");
    const result = await fetchTrelloBoards();
    const boards = result.data;

    const boardsRef = collection(db, `users/${userId}/trelloBoards`);

    // Delete existing boards
    const existingBoards = await getDocs(query(boardsRef));
    await Promise.all(existingBoards.docs.map((doc) => deleteDoc(doc.ref)));

    // Add new boards
    await Promise.all(
      boards.map((board) => setDoc(doc(boardsRef, board.id), board))
    );
  } catch (error) {
    throw new Error(`Failed to sync Trello boards: ${error.message}`);
  }
}

export async function syncTrelloLists(userId, boardId) {
  try {
    const fetchTrelloLists = httpsCallable(functions, "fetchTrelloLists");
    const result = await fetchTrelloLists({ boardId });
    const trelloLists = result.data;

    const listsRef = collection(
      db,
      `users/${userId}/trelloBoards/${boardId}/lists`
    );
    const existingLists = await getDocs(query(listsRef));
    const existingListsMap = new Map(
      existingLists.docs.map((doc) => [doc.id, doc.data()])
    );

    for (const trelloList of trelloLists) {
      const listDocRef = doc(listsRef, trelloList.id);
      if (!existingListsMap.has(trelloList.id)) {
        // New list, add it
        await setDoc(listDocRef, {
          id: trelloList.id,
          name: trelloList.name,
          pos: trelloList.pos,
          cards: [],
        });
      } else {
        // Existing list, update if changed
        const existingList = existingListsMap.get(trelloList.id);
        if (
          existingList.name !== trelloList.name ||
          existingList.pos !== trelloList.pos
        ) {
          await updateDoc(listDocRef, {
            name: trelloList.name,
            pos: trelloList.pos,
          });
        }
      }
      await syncTrelloCards(userId, boardId, trelloList.id);
    }

    // Remove lists that no longer exist in Trello
    for (const [listId, listData] of existingListsMap) {
      if (!trelloLists.find((l) => l.id === listId)) {
        await deleteDoc(doc(listsRef, listId));
      }
    }

    console.log(`Synced ${trelloLists.length} lists for board ${boardId}`);
  } catch (error) {
    console.error("Error syncing Trello lists:", error);
    if (error.code === "permission-denied") {
      throw new Error(
        "Permission denied. Please check your Trello integration and try again."
      );
    } else {
      throw new Error(`Failed to sync Trello lists: ${error.message}`);
    }
  }
}

export async function syncTrelloCards(userId, boardId, listId) {
  try {
    const fetchTrelloCards = httpsCallable(functions, "fetchTrelloCards");
    const result = await fetchTrelloCards({ listId });
    const trelloCards = result.data;

    const cardsRef = collection(
      db,
      `users/${userId}/trelloBoards/${boardId}/lists/${listId}/cards`
    );
    const existingCards = await getDocs(query(cardsRef));
    const existingCardsMap = new Map(
      existingCards.docs.map((doc) => [doc.id, doc.data()])
    );

    const batch = writeBatch(db);

    for (const trelloCard of trelloCards) {
      const cardDocRef = doc(cardsRef, trelloCard.id);
      if (!existingCardsMap.has(trelloCard.id)) {
        // New card, add it
        batch.set(cardDocRef, {
          id: trelloCard.id,
          name: trelloCard.name,
          description: trelloCard.desc,
          position: trelloCard.pos,
          listId: listId,
        });
      } else {
        // Existing card, update if changed
        const existingCard = existingCardsMap.get(trelloCard.id);
        if (
          existingCard.name !== trelloCard.name ||
          existingCard.description !== trelloCard.desc ||
          existingCard.position !== trelloCard.pos
        ) {
          batch.update(cardDocRef, {
            name: trelloCard.name,
            description: trelloCard.desc,
            position: trelloCard.pos,
          });
        }
      }
    }

    // Remove cards that no longer exist in Trello
    for (const [cardId, cardData] of existingCardsMap) {
      if (!trelloCards.find((c) => c.id === cardId)) {
        const cardDocRef = doc(cardsRef, cardId);
        batch.delete(cardDocRef);
      }
    }

    await batch.commit();

    console.log(`Synced ${trelloCards.length} cards for list ${listId}`);
  } catch (error) {
    console.error("Error syncing Trello cards:", error);
    throw new Error(`Failed to sync Trello cards: ${error.message}`);
  }
}

export async function syncTrelloCard(userId, boardId, listId, cardId) {
  const fetchTrelloCard = httpsCallable(functions, "fetchTrelloCard");
  const card = await fetchTrelloCard({ cardId });

  const cardRef = doc(
    db,
    `users/${userId}/trelloBoards/${boardId}/lists/${listId}/cards/${cardId}`
  );
  await setDoc(cardRef, card);
}

export async function createTrelloList(userId, boardId, listName) {
  const functions = getFunctions();
  const createTrelloListCallable = httpsCallable(functions, "createTrelloList");

  try {
    const result = await createTrelloListCallable({ boardId, name: listName });
    const newList = result.data;

    const listRef = doc(
      db,
      `users/${userId}/trelloBoards/${boardId}/lists/${newList.id}`
    );
    await setDoc(listRef, {
      ...newList,
      cards: [],
    });

    return newList;
  } catch (error) {
    console.error("Error creating Trello list:", error);
    throw new Error(`Failed to create Trello list: ${error.message}`);
  }
}

export async function createTrelloCard(userId, boardId, listId, cardData) {
  const createTrelloCard = httpsCallable(functions, "createTrelloCard");
  const newCard = await createTrelloCard({ listId, ...cardData });

  const cardRef = doc(
    db,
    `users/${userId}/trelloBoards/${boardId}/lists/${listId}/cards/${newCard.id}`
  );
  await setDoc(cardRef, newCard);

  return newCard;
}

export async function updateTrelloCard(
  userId,
  boardId,
  listId,
  cardId,
  updates
) {
  const updateTrelloCard = httpsCallable(functions, "updateTrelloCard");
  const updatedCard = await updateTrelloCard({ cardId, ...updates });

  const cardRef = doc(
    db,
    `users/${userId}/trelloBoards/${boardId}/lists/${listId}/cards/${cardId}`
  );
  await updateDoc(cardRef, updatedCard);

  return updatedCard;
}

export async function deleteTrelloCard(userId, boardId, listId, cardId) {
  const deleteTrelloCard = httpsCallable(functions, "deleteTrelloCard");
  await deleteTrelloCard({ cardId });

  const cardRef = doc(
    db,
    `users/${userId}/trelloBoards/${boardId}/lists/${listId}/cards/${cardId}`
  );
  await deleteDoc(cardRef);
}

export async function updateTrelloListOrder(userId, boardId, newOrder) {
  const updateTrelloListOrder = httpsCallable(
    functions,
    "updateTrelloListOrder"
  );
  await updateTrelloListOrder({ boardId, newOrder });
}

export async function updateTrelloCardPosition(
  userId,
  boardId,
  cardId,
  newListId,
  newPosition
) {
  const updateTrelloCardPosition = httpsCallable(
    functions,
    "updateTrelloCardPosition"
  );
  await updateTrelloCardPosition({ boardId, cardId, newListId, newPosition });
}

export async function createTrelloBoard(userId, boardName, boardBackground) {
  const functions = getFunctions();
  const createTrelloBoardCallable = httpsCallable(
    functions,
    "createTrelloBoard"
  );

  try {
    const result = await createTrelloBoardCallable({
      name: boardName,
      background: boardBackground,
    });
    const newBoard = result.data;

    const boardRef = doc(db, `users/${userId}/trelloBoards/${newBoard.id}`);
    await setDoc(boardRef, {
      ...newBoard,
      isTrello: true,
    });

    return newBoard;
  } catch (error) {
    console.error("Error creating Trello board:", error);
    throw new Error(`Failed to create Trello board: ${error.message}`);
  }
}
