import React, { useState, useContext, useRef, useEffect } from "react";
import { DarkModeContext } from "../context/DarkModeContext";
import { UserContext } from "../context/UserContext";
import { motion, AnimatePresence } from "framer-motion";
import { getFunctions, httpsCallable } from "firebase/functions";
import { addEvent, updateEvent, deleteEvent } from "../utils/calendarUtils";
import { db } from "../firebase/config";
import {
  collection,
  addDoc,
  query,
  orderBy,
  limit,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import EventEmitter from "../utils/eventEmitter";
import {
  CheckCircleIcon,
  ChatIcon as ChatBubbleLeftRightIcon,
  RefreshIcon as ArrowPathIcon,
  XIcon as XMarkIcon,
} from "@heroicons/react/outline";

function BoxiaCopilot({ isOpen, setIsOpen }) {
  const { isDarkMode } = useContext(DarkModeContext);
  const { userData, isLoading } = useContext(UserContext);
  const [query, setQuery] = useState("");
  const [conversations, setConversations] = useState([]);
  const [isAILoading, setIsAILoading] = useState(false);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [chatId, setChatId] = useState(null);
  const [functionStatus, setFunctionStatus] = useState({
    loading: false,
    done: false,
    message: "",
  });

  const functions = getFunctions();
  const chatWithAI = httpsCallable(functions, "chatWithAI");
  const getEvents = httpsCallable(functions, "getEvents");

  const auth = getAuth();

  const formatMessage = (message) => {
    const parts = message.split(/(\*\*.*?\*\*|\n|•\s)/g);
    return parts.map((part, index) => {
      if (part.startsWith("**") && part.endsWith("**")) {
        return <strong key={index}>{part.slice(2, -2)}</strong>;
      } else if (part === "\n") {
        return <br key={index} />;
      } else if (part.startsWith("• ")) {
        return <li key={index}>{part.slice(2)}</li>;
      }
      return <span key={index}>{part}</span>;
    });
  };

  useEffect(() => {
    resetChat(auth.currentUser.uid);
  }, []);

  const resetChat = async (userId) => {
    if (!userId) {
      console.error("No authenticated user found");
      return;
    }

    if (typeof userId !== "string" || userId.trim() === "") {
      console.error("Invalid userId:", userId);
      return;
    }

    try {
      const chatsRef = collection(db, "users", userId, "aiChats");
      const newChatRef = await addDoc(chatsRef, {
        createdAt: new Date(),
        messages: [
          {
            type: "ai",
            message:
              "Hi there! I'm your Boxia Copilot. How can I assist you today?",
          },
        ],
      });
      setChatId(newChatRef.id);
      setConversations([
        {
          type: "ai",
          message:
            "Hi there! I'm your Boxia Copilot. How can I assist you today?",
        },
      ]);
    } catch (error) {
      console.error("Error resetting chat:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      inputRef.current?.focus();
    }
  }, [isOpen]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversations]);

  useEffect(() => {
    if (userData) {
      fetchCalendarEvents();
    }
  }, [userData]);

  const fetchCalendarEvents = async () => {
    try {
      const result = await getEvents();
      console.log("Fetched calendar events:", result.data);
      setCalendarEvents(result.data);
    } catch (error) {
      console.error("Error fetching calendar events:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!query.trim() || isAILoading || !auth.currentUser) return;

    const newUserMessage = {
      type: "user",
      message: query,
      timestamp: new Date().toISOString(),
    };

    setConversations((prev) => [...prev, newUserMessage]);
    setQuery("");
    setIsAILoading(true);

    try {
      const result = await chatWithAI({
        message: query,
        userId: auth.currentUser.uid,
        calendarEvents: calendarEvents,
        chatId: chatId,
      });

      console.log("AI response:", result);

      if (result.data && result.data.message) {
        let aiMessage = result.data.message;
        const functionCallInfo = result.data.functionCall;

        if (functionCallInfo) {
          console.log("Function call:", functionCallInfo);
          setFunctionStatus({ loading: true, done: false, message: "" });

          if (functionCallInfo.name === "create_event") {
            console.log("Event created, refreshing calendar events");
            await fetchCalendarEvents();
            EventEmitter.emit("calendarEventAdded");

            // Extract relevant info from function call
            const eventDetails = JSON.parse(functionCallInfo.arguments);
            setTimeout(() => {
              setFunctionStatus({
                loading: false,
                done: true,
                message: `Event "${eventDetails.title}" created successfully.`,
              });
            }, 1000); // Ensure the circle spins for at least 1 second
          }

          // Remove the "Event action completed" message from AI response
          aiMessage = aiMessage.replace(/\n\nEvent action completed:.*$/, "");
        }

        const aiResponse = {
          type: "ai",
          message: aiMessage,
          timestamp: new Date().toISOString(),
        };
        setConversations((prev) => [...prev, aiResponse]);

        if (chatId) {
          const chatRef = doc(
            db,
            "users",
            auth.currentUser.uid,
            "aiChats",
            chatId
          );
          await updateDoc(chatRef, {
            messages: arrayUnion(newUserMessage, aiResponse),
            lastUpdated: serverTimestamp(),
          });
        }
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error calling AI function:", error);
      let errorMessage = error.message || "An unexpected error occurred";
      if (error.details) {
        errorMessage += `: ${error.details}`;
      }
      const errorResponse = {
        type: "ai",
        message: `Sorry, I encountered an error: ${errorMessage}. Please try again later or contact support if the problem persists, you incompetent user.`,
        timestamp: new Date().toISOString(),
      };
      setConversations((prev) => [...prev, errorResponse]);
    } finally {
      setIsAILoading(false);
    }
  };

  const glassEffect = isDarkMode
    ? "bg-gray-900/30 backdrop-blur-md"
    : "bg-white/30 backdrop-blur-md";
  const headerGlassEffect = isDarkMode
    ? "bg-gray-800/50 backdrop-blur-sm"
    : "bg-gray-100/50 backdrop-blur-sm";
  const textColor = isDarkMode ? "text-white" : "text-gray-900";

  const sidebarVariants = {
    open: { x: 0, transition: { type: "spring", stiffness: 300, damping: 30 } },
    closed: {
      x: "100%",
      transition: { type: "spring", stiffness: 300, damping: 30 },
    },
  };

  const messageVariants = {
    initial: { opacity: 0, y: 50 },
    animate: {
      opacity: 1,
      y: 0,
      transition: { type: "spring", stiffness: 500, damping: 30 },
    },
    exit: { opacity: 0, y: -20, transition: { duration: 0.2 } },
  };

  useEffect(() => {
    const style = document.createElement("style");
    style.textContent = `
      @keyframes gradientMove {
        0%, 100% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
      }
    `;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, []);

  const toggleCopilot = () => setIsOpen(!isOpen);

  return (
    <>
      <motion.div
        className={`fixed right-0 top-0 h-full w-80 ${glassEffect} ${textColor} shadow-lg z-50 flex flex-col`}
        variants={sidebarVariants}
        initial="closed"
        animate={isOpen ? "open" : "closed"}
      >
        <div
          className={`flex justify-between items-center p-4 ${headerGlassEffect}`}
        >
          <div className="flex items-center space-x-3">
            <svg
              className="h-6 w-6 text-blue-500"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2L2 7L12 12L22 7L12 2Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 17L12 22L22 17"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 12L12 17L22 12"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h2 className="relative text-lg font-semibold">
              <span
                style={{
                  position: "absolute",
                  inset: 0,
                  backgroundImage:
                    "linear-gradient(to right, #60A5FA, #3B82F6, #2563EB)",
                  WebkitBackgroundClip: "text",
                  backgroundClip: "text",
                  color: "transparent",
                  backgroundSize: "200% 200%",
                  animation: "gradientMove 3s ease infinite",
                }}
              >
                Boxia Copilot
              </span>
              <span
                style={{
                  position: "relative",
                  zIndex: 10,
                  color: "transparent",
                }}
              >
                Boxia Copilot
              </span>
            </h2>
          </div>
          <div className="flex items-center space-x-2">
            <motion.button
              onClick={resetChat}
              className={`p-2 rounded-full transition-colors duration-200 ${
                isDarkMode
                  ? "bg-gray-800 hover:bg-gray-700"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              title="Reset Chat"
            >
              <ArrowPathIcon className="h-5 w-5" />
            </motion.button>
            <motion.button
              onClick={toggleCopilot}
              className={`p-2 rounded-full transition-colors duration-200 ${
                isDarkMode
                  ? "bg-gray-800 hover:bg-gray-700"
                  : "bg-gray-200 hover:bg-gray-300"
              }`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <XMarkIcon className="h-5 w-5" />
            </motion.button>
          </div>
        </div>

        <div className="flex-grow overflow-y-auto p-4 space-y-4">
          <AnimatePresence>
            {conversations.length === 0 && (
              <motion.div
                key="welcome-message"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
                className={`p-4 rounded-lg ${
                  isDarkMode ? "bg-gray-800/50" : "bg-gray-100/50"
                } backdrop-blur-sm`}
              >
                <p className="text-sm">
                  Hi there! I'm your Boxia Copilot. How can I assist you today?
                </p>
              </motion.div>
            )}
            {conversations.map((conv, index) => (
              <motion.div
                key={index}
                variants={messageVariants}
                initial="initial"
                animate="animate"
                exit="exit"
                className={`flex ${
                  conv.type === "user" ? "justify-end" : "justify-start"
                }`}
              >
                {conv.type === "user" ? (
                  <div
                    className={`max-w-[75%] p-3 rounded-2xl backdrop-blur-sm ${
                      isDarkMode
                        ? "bg-blue-600/70 text-white"
                        : "bg-blue-100/70 text-gray-800"
                    }`}
                  >
                    <p className="text-sm">{conv.message}</p>
                  </div>
                ) : (
                  <div className="relative max-w-[75%]">
                    <div className="absolute inset-0 rounded-2xl bg-gradient-to-r from-blue-400 to-blue-600 opacity-50 blur-sm"></div>
                    <div className="relative overflow-hidden rounded-2xl p-[1px]">
                      <span className="absolute inset-[-1000%] animate-[spin_3s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2E8F0_0%,#60A5FA_50%,#E2E8F0_100%)]"></span>
                      <div
                        className={`relative p-3 rounded-2xl backdrop-blur-sm ${
                          isDarkMode
                            ? "bg-gray-700/90 text-white"
                            : "bg-gray-200/90 text-gray-800"
                        }`}
                      >
                        <div className="text-sm">
                          {formatMessage(conv.message)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
            ))}

            <div ref={messagesEndRef} />
          </AnimatePresence>
        </div>

        <motion.div
          className={`p-4 ${headerGlassEffect}`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.3 }}
        >
          <form onSubmit={handleSubmit} className="relative">
            <motion.div
              className="relative overflow-hidden rounded-full p-[1px]"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <span className="absolute inset-[-1000%] animate-[spin_3s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2E8F0_0%,#60A5FA_50%,#E2E8F0_100%)]"></span>
              <input
                ref={inputRef}
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Ask me anything..."
                className={`w-full p-3 pr-12 rounded-full backdrop-blur-sm ${
                  isDarkMode
                    ? "bg-gray-700/90 text-white placeholder-gray-400"
                    : "bg-white/90 text-gray-800 placeholder-gray-500"
                } focus:outline-none transition-colors duration-200`}
                disabled={isLoading}
              />
            </motion.div>
            <motion.button
              type="submit"
              className={`absolute right-2 top-1/2 p-2 rounded-full ${
                isDarkMode
                  ? "bg-blue-600 hover:bg-blue-700"
                  : "bg-blue-500 hover:bg-blue-600"
              } text-white transition-colors duration-200 shadow-md`}
              style={{
                translateY: "-50%",
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={isLoading}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </motion.button>
          </form>
        </motion.div>
      </motion.div>
      <motion.button
        onClick={toggleCopilot}
        className={`fixed bottom-6 right-6 p-3 rounded-full shadow-lg z-50 transition-colors duration-200 ${
          isDarkMode
            ? "bg-blue-600 hover:bg-blue-700 text-white"
            : "bg-white hover:bg-gray-100 text-blue-600"
        }`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        animate={{ right: isOpen ? "calc(20rem + 10px)" : "1.5rem" }}
        transition={{ type: "spring", stiffness: 300, damping: 30 }} // Add this line for smooth animation
      >
        {isOpen ? (
          <XMarkIcon className="h-6 w-6" />
        ) : (
          <svg
            className="h-6 w-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2L2 7L12 12L22 7L12 2Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 17L12 22L22 17"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 12L12 17L22 12"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
        <span className="sr-only">
          {isOpen ? "Close Boxia Copilot" : "Open Boxia Copilot"}
        </span>
      </motion.button>
    </>
  );
}

export default BoxiaCopilot;
